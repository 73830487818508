import { useQuery } from 'react-apollo';
import { Layout, Space, Breadcrumb, Form, Input, Button, DatePicker, Row, Col, Table, Pagination, ConfigProvider } from "antd";
import React, { useState, useEffect } from 'react';
import { SalesReport, DownloadSalesReport } from "../../../server/query";
import client from '../../../server/apollo_config'
import './sales.css'
import Moment from 'moment';
import PermissionCheck from '../../../components/permission_check';
import { useHistory, useLocation } from 'react-router';
import { ROLES } from '../../../server/constant'
import locale from 'antd/lib/locale/zh_CN';
const { RangePicker } = DatePicker;

const SalesReportPage = () => {
    const [form] = Form.useForm();
    const [reportList, setReportList] = useState([])
    const [totalCount, setTotalCount] = useState('0');
    const [agentName, setAgentName] = useState(null);
    const [agentLeadName, setAgentLeadName] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [roles, setRoles] = useState('');
    const [invoiceNo, setInvoiceNo] = useState(null);
    const [billingName, setBillingName] = useState(null);
    const [advertiserName, setAdvertiserName] = useState(null);
    const [paymentReference, setPaymentReference] = useState(null)
    const [promoCode, setPromoCode] = useState(null);
    const [offSet, setOffSet] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [variables, setVariables] = useState({ offSet })
    const { loading, error, data, refetch } = useQuery(SalesReport, { variables })
    const columns = [
        {
            title: 'Invoice Date \n收据日期',
            dataIndex: 'date',
            key: 'date',
            render: (text, row) => row['date'] !== '' ? (Moment(row['date']).format('DD/MM/YYYY')) : ''
        },
        {
            title: 'Invoice Number \n收据编号',
            dataIndex: 'no',
            key: 'no',
        },
        {
            title: 'Billing Name \n付款人姓名',
            dataIndex: 'billing_name',
            key: 'billing_name',
        },
        {
            title: 'Advertiser Name \n广告商姓名',
            dataIndex: 'advertiser_name',
            key: 'advertiser_name',
        },
        {
            title: 'Agent Name \n代理姓名',
            dataIndex: 'agent_name',
            key: 'agent_name',
        },
        {
            title: 'Agent Lead Name \n代理主管姓名',
            dataIndex: 'agent_lead_name',
            key: 'agent_lead_name',
        },
        {
            title: 'Promo Code \n促销代码',
            dataIndex: 'promo_code',
            key: 'promo_code',
        },
        {
            title: 'Payment Reference \n付款参考',
            dataIndex: 'reference',
            key: 'reference',
        },

    ]

    const location = useLocation()
    const history = useHistory()

    const listOfViewOnlyRoles = ['operation', 'project_lead']

    useEffect(() => {
        const roles = localStorage.getItem(ROLES)
        setRoles(roles)
    
        const isAllowedRole = ['super_admin', 'project_lead', 'finance', 'operation'].includes(roles)

        if (isAllowedRole === false) {
            history.push('/inbox')
            return
        } 

        if (loading === false && data != null) {
            rerenderListView(data.salesReport.edges)
            setTotalCount(data.salesReport.totalCount)
        }
    }, [data, history, loading])

    function rerenderListView(data) {
        var list = []

        for (var i = 0; i < data.length; i++) {
            list.push({
                'date': data[i].node.invoiceDate,
                'no': data[i].node.invoiceNo,
                'billing_name': data[i].node.billing?.name,
                'advertiser_name': data[i].node.advertiser?.legalName,
                'agent_name': data[i].node.agent?.user?.name,
                'agent_lead_name': data[i].node.agentLead?.user?.name,
                'promo_code': data[i].node.promoCode,
                'reference': data[i].node.paymentReference?.referenceId,
            })
        }

        setReportList(list)

    }

    async function downloadReportFunction() {
        client.query({
            query: DownloadSalesReport,
            variables: { agentName: agentName, agentLeadName: agentLeadName, startDate: startDate, endDate: endDate, invoiceNo: invoiceNo, billingName: billingName, advertiserName: advertiserName, paymentReference: paymentReference, promoCode: promoCode },
            fetchPolicy: "network-only"
        }).then((res) => {
            let element = document.createElement('a');
            element.setAttribute('href', res.data.downloadSalesReport.url);
            element.setAttribute('target', "_blank");
            // element.setAttribute('download', 'output.xlsx');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        })
    }

    const onFinish = (values) => {
        if (values.advertiser !== undefined) setAdvertiserName(values.advertiser)
        if (values.agent !== undefined) setAgentName(values.agent)
        if (values.agent_lead !== undefined) setAgentLeadName(values.agent_lead)
        if (values.date !== undefined) setStartDate(values.date[0])
        if (values.date !== undefined) setEndDate(values.date[1])
        if (values.invoice !== undefined) setInvoiceNo(values.invoice)
        if (values.payment_ref !== undefined) setPaymentReference(values.payment_ref)
        if (values.billing_name !== undefined) setBillingName(values.billing_name)
        if (values.promo_code !== undefined) setPromoCode(values.promo_code)

        if (values.date !== undefined) {
            setVariables({
                offSet,
                agentName: values.agent,
                agentLeadName: values.agent_lead,
                invoiceNo: values.invoice,
                billingName: values.billing_name,
                advertiserName: values.advertiser,
                paymentReference: values.payment_ref,
                startDate: values.date[0],
                endDate: values.date[1],
                promoCode: values.promo_code
            })
        } else {
            setVariables({
                offSet,
                agentName: values.agent,
                agentLeadName: values.agent_lead,
                invoiceNo: values.invoice,
                billingName: values.billing_name,
                advertiserName: values.advertiser,
                paymentReference: values.payment_ref,
                promoCode: values.promo_code

            })
        }

        setCurrentPageNumber(1)
    };

    function onResetClicked() {
        form.resetFields();
        setVariables({ offSet: 0 })
        setAdvertiserName(null)
        setAgentName(null)
        setAgentLeadName(null)
        setStartDate(null)
        setEndDate(null)
        setInvoiceNo(null)
        setPaymentReference(null)
        setBillingName(null)
        setPromoCode(null)

        setCurrentPageNumber(1)
    }

    function onPaginationClicked(page) {

        if (startDate !== "") {
            setVariables({
                offSet: ((page - 1) * 5),
                agentName,
                agentLeadName,
                invoiceNo,
                billingName,
                advertiserName,
                paymentReference,
                promoCode,
                startDate,
                endDate,
            })
        } else {
            setVariables({
                offSet: ((page - 1) * 5),
                agentName,
                agentLeadName,
                invoiceNo,
                billingName,
                advertiserName,
                paymentReference,
                promoCode,
            })

        }
    }

    return (
        <Layout>
            <Space direction='vertical' className="commission_header">
                <Breadcrumb style={{ marginBottom: '16px' }}>
                    <Breadcrumb.Item>Homepage 首页</Breadcrumb.Item>
                    <Breadcrumb.Item>Sales Report 业绩报告</Breadcrumb.Item>
                </Breadcrumb>
                <h1>Sales Report 业绩报告</h1>
            </Space>
            <Layout style={{ margin: '16px 32px' }}>

                <Form className="filter-form" name="basic"
                    onFinish={onFinish}
                    form={form}
                    autoComplete="off">
                    <Row align="top" justify='space-between'>
                        <Col span={8}>
                            <Row>
                                <Col span={6}>
                                    <p>Date 日期</p>
                                </Col>
                                <Col span={16}>
                                    <ConfigProvider locale={locale}>
                                        <Form.Item name="date">
                                            <RangePicker />
                                        </Form.Item>
                                    </ConfigProvider>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row>
                                <Col span={8}>
                                    <p>Invoice Number <br />收据编号</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="invoice">
                                        <Input placeholder="Fill in number 填写编号" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8} style={{ textAlign: 'end' }}>
                            <Space direction="horizontal">
                                {
                                    listOfViewOnlyRoles.includes(roles) ? null : <Button onClick={() => downloadReportFunction()}>
                                        Export 汇出
                                    </Button>
                                }

                                <Button onClick={() => onResetClicked()}>
                                    Reset 重设
                                </Button>
                                <Button className="search-button" htmlType="submit">
                                    Search 搜寻
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                    <Row align="top" >
                        <Col span={8}>
                            <Row>
                                <Col span={6}>
                                    <p>Billing Name <br />付款人姓名</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="billing_name">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row>
                                <Col span={8}>
                                    <p>Advertiser Name <br />广告商姓名</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="advertiser">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row align="top" >
                        <Col span={8}>
                            <Row>
                                <Col span={6}>
                                    <p>Agent Name <br />代理姓名</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="agent">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row>
                                <Col span={8}>
                                    <p>Agent Lead Name <br />代理主管姓名</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="agent_lead">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row align="top" >
                        {/* <Col span={8}>
                            <Row>
                                <Col span={6}>
                                    <p>Billing Name <br />付款人姓名</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="billing_name">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col> */}
                        <Col span={8}>
                            <Row>
                                <Col span={6}>
                                    <p>Payment Reference <br />付款参考</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="payment_ref">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row>
                                <Col span={6}>
                                    <p>Promo Code <br />促销代码</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="promo_code">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>

                <div style={{ background: 'white' }}>
                    <Table className="main-table" style={{ whiteSpace: "pre" }} columns={columns} dataSource={reportList} scroll={{ x: 1800 }}
                        pagination={{
                            showSizeChanger: false,
                            current: currentPageNumber, total: totalCount, pageSize: 5, onChange: (page, pageSize) => {
                                setCurrentPageNumber(page)
                                onPaginationClicked(page)
                            }
                        }}
                    />
                </div>

            </Layout>
        </Layout>
    )
}

export default SalesReportPage;