import React, { useState, useEffect } from 'react';
import { Layout, Space, Row, Col, Card, Button, Divider, Tabs, DatePicker, Radio } from 'antd';
import { ROLES } from '../server/constant'
import DashboardComponent from '../components/dashboardComponent';
import { useHistory } from "react-router-dom";
import client from '../server/apollo_config'
import moment from 'moment';
import { DownloadOverallStatisticsReport } from '../server/query';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const ref = React.createRef();

const HomePage = () => {
    const [timeFilter, setTimeFilter] = useState("week");

    const [dateRange, setDateRange] = useState([moment().startOf("year"), moment().endOf("year")]);

    const history = useHistory();

    const headerColumn = [
        {
            title: 'Total Sales 总业绩',
            graph01: {
                query: {
                    measures: ['CmsPayment.paymentAmount'],
                    timeDimensions: [],
                    order: {
                        'CmsPayment.paymentAmount': 'desc',
                    },
                    filters: [
                        {
                            member: 'CmsPayment.isSuccess',
                            operator: 'equals',
                            values: ['TRUE'],
                        }
                    ],
                    dimensions: [],
                    limit: 5000,
                },
                chartType: 'number',
            },
            graph02: {
                query: {
                    measures: ['CmsPayment.paymentAmount'],
                    timeDimensions: [
                        {
                            dimension: 'CmsPayment.dateCreated',
                            dateRange: 'from 6 days ago to now',
                            granularity: 'day',
                        }
                    ],
                    order: {
                        'CmsPayment.paymentAmount': 'desc',
                    },
                    filters: [
                        {
                            member: 'CmsPayment.isSuccess',
                            operator: 'equals',
                            values: ['TRUE'],
                        }
                    ],
                    dimensions: [],
                    limit: 5000,
                },
                chartType: 'area',
            },
            average: {
                query: {
                    measures: ['CmsPayment.paymentAmountAverage'],
                    timeDimensions: [
                        {
                            dimension: 'CmsPayment.dateCreated',
                            dateRange: 'Last 7 days',
                        }
                    ],
                    order: [['CmsPayment.paymentAmountAverage', 'desc']],
                    // filters: [
                    //     {
                    //         member: 'CmsPayment.isSuccess',
                    //         operator: 'equals',
                    //         values: ['TRUE'],
                    //     }
                    // ],
                    dimensions: [],
                    limit: 5000,
                },
                chartType: 'number',
            },
        },
        {
            title: 'Classified Ads 汽车广告',
            graph01: {
                query: {
                    measures: ['CmsListingpage.count'],
                    timeDimensions: [],
                    order: {
                        'CmsListingpage.count': 'desc',
                    },
                    filters: [],
                    dimensions: [],
                    limit: 5000,
                },
                chartType: 'number',
            },
            graph02: {
                query: {
                    measures: ['CmsListingpage.count'],
                    timeDimensions: [
                        {
                            dimension: 'WagtailcorePage.lastPublishedAt',
                            dateRange: 'from 6 days ago to now',
                            granularity: 'day',
                        }
                    ],
                    order: {
                        'CmsListingpage.count': 'desc',
                    },
                    filters: [],
                    dimensions: [],
                    limit: 5000,
                },
                chartType: 'area',
            },
            average: {
                query: {
                    measures: ['CmsListingpage.averageTotalCount'],
                    timeDimensions: [
                        {
                            dimension: 'WagtailcorePage.lastPublishedAt',
                            dateRange: 'from 6 days ago to now',
                        }
                    ],
                    order: {
                        'Orders.totalAmount': 'desc',
                    },
                    filters: [],
                    dimensions: [],
                    limit: 5000,
                },
                chartType: 'number',
            },
        },
        {
            title: 'Total Views 总浏览量',
            graph01: {
                query: {
                    measures: ['CmsListingpage.count'],
                    timeDimensions: [],
                    order: {
                        'CmsListingpage.count': 'desc',
                    },
                    filters: [],
                    limit: 5000,
                },
                chartType: 'number',
            },
            graph02: {
                query: {
                    measures: ['CmsListingpage.count'],
                    timeDimensions: [],
                    order: {
                        'CmsListingpage.count': 'desc',
                    },
                    filters: [],
                    dimensions: [],
                    limit: 5000,
                },
                chartType: 'bar',
            },
            average: {
                query: {
                    measures: ['CmsListingpage.count'],
                    timeDimensions: [],
                    order: {
                        'CmsListingpage.count': 'desc',
                    },
                    filters: [],
                    dimensions: [],
                    limit: 5000,
                },
                chartType: 'number',
            },
        },
        {
            title: 'Total Clicks 总点击率',
            graph01: {
                query: {
                    measures: ['CmsView.count'],
                    timeDimensions: [],
                    order: {
                        'CmsView.count': 'desc',
                    },
                    filters: [],
                    dimensions: [],
                    limit: 5000,
                },
                chartType: 'number',
            },
            graph02: {
                query: {
                    measures: ['CmsView.count'],
                    timeDimensions: [
                        {
                            dimension: 'WagtailcorePage.lastPublishedAt',
                            dateRange: 'from 6 days ago to now',
                            granularity: 'day',
                        },
                    ],
                    order: {
                        'CmsViews.count': 'desc',
                    },
                    filters: [],
                    dimensions: [],
                    limit: 5000,
                },
                chartType: 'area',
            },
            average: {
                query: {
                    measures: ['CmsView.count'],
                    timeDimensions: [],
                    order: {
                        'CmsView.count': 'desc',
                    },
                    filters: [],
                    dimensions: [],
                    limit: 5000,
                },
                chartType: 'number',
            },
        },
    ]

    const options = [
        { label: 'Week 周', value: 'week' },
        { label: 'Month 月', value: 'month' },
        { label: 'Year 年', value: 'year' },
    ];

    const timeOnChange = event => {
        setTimeFilter(event.target.value);
    };

    const dateRangeOnChange = (dates, dateStrings) => {
        setDateRange(dates);
    };

    const operations = <Space size={12}>
        <Radio.Group
            options={options}
            onChange={timeOnChange}
            value={timeFilter}
            optionType="button"
        />
        <RangePicker
            defaultValue={dateRange}
            onChange={dateRangeOnChange}
        />
    </Space>;

     async function downloadReportFunction() {

        client.query({
            query: DownloadOverallStatisticsReport,
            fetchPolicy: "network-only"
        }).then((res) => {
            let element = document.createElement('a');
            element.setAttribute('href', res.data.downloadOverallStatisticsReport.url);
            element.setAttribute('target', "_blank");
            // element.setAttribute('download', 'output.xlsx');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        })
    }

    useEffect(() => {
        const roles = localStorage.getItem(ROLES)
        if (roles.includes('super_admin') || roles.includes('project_lead')) {
        } else {
            history.push('/inbox')
        }
    }, [history]);
    
    return (
        <Layout>
            <Row style={{ justifyContent: 'space-between', padding: '16px 24px', background: 'white' }}>
                <h1 style={{ fontSize: '20px', margin: '0px' }}>Classified Ads 汽车广告 <span style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.45)', fontWeight: '400' }}>Project Lead 陈佳纹 Tan Chia Wen</span></h1>
                {/* <Pdf targetRef={ref} filename="code-example.pdf" scale={0.64}>
                    {({ toPdf }) => <Button onClick={toPdf} type="primary">Download Report</Button>}
                </Pdf> */}
                <Button type="primary" style={{ background: '#C62828', color: 'white', borderRadius: '4px', borderColor: '#C62828' }} onClick={() => downloadReportFunction()}>Download Report 下载报告</Button>
            </Row>

            <div ref={ref} id='download_image'>
                <Layout style={{ padding: '24px' }}>

                    <Row gutter={[16, 16]} style={{ justifyContent: 'space-evenly' }}>
                        {headerColumn.map((item) => (
                            <Col span={6}>
                                <Card>
                                    <Space direction="horizontal"><p style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: '14px' }}>{item.title}</p></Space>
                                    <Row>
                                        <Col style={{ alignSelf: 'center', marginRight: '4px' }}>
                                            {item.title === 'Total Sales' ? <h1 style={{ fontSize: '24px', fontWeight: '400' }}>RM</h1> : null}
                                        </Col>
                                        <Col>
                                            <DashboardComponent graph={item.graph01} />
                                        </Col>
                                    </Row>

                                    <DashboardComponent graph={item.graph02} />
                                    <Divider />
                                    <Row>
                                        <Col style={{ alignSelf: 'center', marginRight: '32px' }}>
                                            <p style={{ color: 'rgba(0, 0, 0, 0.65)', margin: '0px' }}>7 day avg 平均7天
                                            </p>
                                        </Col>
                                        <Col>
                                            <DashboardComponent fontSize='14px' graph={item.average} />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <Layout style={{ marginTop: '24px', background: 'white' }}>
                        <Tabs style={{ justifyContent: 'space-between', padding: '16px 24px' }} defaultActiveKey="1" tabBarExtraContent={operations}>
                            <TabPane tab="Agent Lead Sales 代理主管业绩" key="1">
                                <Row style={{ justifyContent: 'space-between' }}>
                                    <Col span={14}>
                                        <DashboardComponent graph={{
                                            query: {
                                                measures: ['CmsPayment.paymentAmount'],
                                                timeDimensions: [
                                                    {
                                                        dimension: 'CmsPayment.dateCreated',
                                                        dateRange: dateRange,
                                                        granularity: timeFilter,
                                                    }
                                                ],
                                                order: {
                                                    'CmsPayment.paymentAmount': 'desc',
                                                },
                                                filters: [
                                                    {
                                                        member: 'CmsPayment.isSuccess',
                                                        operator: 'equals',
                                                        values: ['TRUE'],
                                                    },
                                                    {
                                                        member: 'CmsPayment.agentId',
                                                        operator: 'set',
                                                    },
                                                    {
                                                        member: 'CmsAgent.parentId',
                                                        operator: 'notSet',
                                                    }
                                                ],
                                                dimensions: ['CmsAgent.id', 'UsersUser.name'],
                                                limit: 5000,
                                            },
                                            chartType: 'bar',
                                        }} />
                                    </Col>
                                    <Col span={9}>
                                        <p style={{ fontWeight: 500 }}>Top Sales Performance 最佳销售业绩</p>
                                        <DashboardComponent graph={{
                                            query: {
                                                measures: ['CmsPayment.paymentAmount'],
                                                timeDimensions: [],
                                                order: {
                                                    'CmsPayment.paymentAmount': 'desc',
                                                },
                                                filters: [
                                                    {
                                                        member: 'CmsPayment.isSuccess',
                                                        operator: 'equals',
                                                        values: ['TRUE'],
                                                    },
                                                    {
                                                        member: 'CmsPayment.agentId',
                                                        operator: 'set',
                                                    },
                                                    {
                                                        member: 'CmsAgent.parentId',
                                                        operator: 'notSet',
                                                    }
                                                ],
                                                dimensions: ['CmsAgent.id', 'UsersUser.name'],
                                                limit: 7,
                                            },
                                            chartType: 'table',
                                        }}
                                        isAgentLead='true'
                                         />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="Agent Sales 代理业绩" key="2">
                                <Row style={{ justifyContent: 'space-between' }}>
                                    <Col span={14}>
                                        <DashboardComponent graph={{
                                            query: {
                                                measures: ['CmsPayment.paymentAmount'],
                                                timeDimensions: [
                                                    {
                                                        dimension: 'CmsPayment.dateCreated',
                                                        dateRange: dateRange,
                                                        granularity: timeFilter,
                                                    }
                                                ],
                                                order: {
                                                    'CmsPayment.paymentAmount': 'desc',
                                                },
                                                filters: [
                                                    {
                                                        member: 'CmsPayment.isSuccess',
                                                        operator: 'equals',
                                                        values: ['TRUE'],
                                                    },
                                                    {
                                                        member: 'CmsPayment.agentId',
                                                        operator: 'set',
                                                    },
                                                    {
                                                        member: 'CmsAgent.parentId',
                                                        operator: 'set',
                                                    }
                                                ],
                                                dimensions: ['CmsAgent.id', 'UsersUser.name'],
                                                limit: 5000,
                                            },
                                            chartType: 'bar',
                                        }} />
                                    </Col>
                                    <Col span={9}>
                                        <p style={{ fontWeight: 500 }}>Top Sales Performance 最佳销售业绩</p>
                                        <DashboardComponent graph={{
                                            query: {
                                                measures: ['CmsPayment.paymentAmount'],
                                                timeDimensions: [],
                                                order: {
                                                    'CmsPayment.paymentAmount': 'desc',
                                                },
                                                filters: [
                                                    {
                                                        member: 'CmsPayment.isSuccess',
                                                        operator: 'equals',
                                                        values: ['TRUE'],
                                                    },
                                                    {
                                                        member: 'CmsPayment.agentId',
                                                        operator: 'set',
                                                    },
                                                    {
                                                        member: 'CmsAgent.parentId',
                                                        operator: 'set',
                                                    }
                                                ],
                                                dimensions: ['CmsAgent.id', 'UsersUser.name'],
                                                limit: 7,
                                            },
                                            chartType: 'table',
                                        }} />
                                    </Col>
                                </Row>
                            </TabPane>
                        </Tabs>
                    </Layout>
                </Layout>
            </div>

        </Layout>

    );

};

export default HomePage;