import { Layout, Space, Breadcrumb, message, Avatar, Row, Divider, Tag, Empty, Button, Select, Table, Modal, Badge, Col } from "antd";
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { useQuery, useMutation } from 'react-apollo';
import { Advertiser, AllAdvertiserBillings, AllAgentLead, AllAgents } from '../../../../server/query'
import { RetiredAgentLead, AssignAgent, AssignAdvertiser } from '../../../../server/mutation'
import { ROLES } from '../../../../server/constant'
import {
    ArrowLeftOutlined,
} from '@ant-design/icons';
import '../car-dealer.css'
import Moment from 'moment';

const CarDealerDetailView = (param) => {

    const [offSet, setOffSet] = useState(0)
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const { loading, error, data, refetch } = useQuery(Advertiser, { variables: { id: param.match.params.id } })
    const { loading: billingLoading, error: billingError, data: billingData } = useQuery(AllAdvertiserBillings, { variables: { id: param.match.params.id, offSet } })
    const { loading: loadingAgentLead, error: errorAgentLead, data: dataAgentLead } = useQuery(AllAgentLead)
    const { loading: AgentListLoading, data: agentListData } = useQuery(AllAgents)
    const [ReassignCarDealer] = useMutation(AssignAdvertiser, {
        onCompleted(data) {
            if (data.assignAdvertiser.ok == null) {
                message.error(data.assignAdvertiser.error);
            } else {
                message.success('Car dealer reassigned.')
                setReassignModalVisible(false);
                setTransferId('')
                refetch()
            }
        }
    })
    const [agentList, setAgentList] = useState([])
    const [adsListing, setAdsListing] = useState([])
    const [billingRecords, setBillingRecords] = useState([])
    const [agentsList, setAgentsList] = useState([])
    const [reassignModalVisible, setReassignModalVisible] = useState(false)
    const [TransferId, setTransferId] = useState('')
    const [projectLead, setProjectLead] = useState(false)
    const [superAdmin, setSuperAdmin] = useState(false)
    const [billingTotal, setBillingTotal] = useState(0)
    const history = useHistory()
    const { Option } = Select;


    useEffect(() => {
        var roles = localStorage.getItem(ROLES)
        if (roles.includes('advertiser') || roles.includes('car_dealer')) {
            history.push('/inbox')
        } else {

            if (roles.includes('super_admin') || roles.includes('operation') || roles.includes('finance')) {
                setProjectLead(true)
                setSuperAdmin(true)
            }
            if (roles.includes('project_lead')) {
                setProjectLead(true)
            }
        }

        if (loading === false && data.advertiser != null) {


            rerenderAgentList(data.advertiser.agent)
            if (data.advertiser.allListings != null) {

                rerenderListingList(data.advertiser.allListings.edges)
            }
        }

        if (billingLoading === false && billingData?.allAdvertiserBillings != null) {

            setBillingTotal(billingData.allAdvertiserBillings.totalCount)
            if (billingData.allAdvertiserBillings.totalCount > 0) { rerenderBillingList(billingData.allAdvertiserBillings.edges) }

        }
        if (AgentListLoading === false && agentListData != null) {
            rerenderAgentsList(agentListData.allAgents.edges)
        }


    }, [AgentListLoading, agentListData, billingData, billingLoading, data, dataAgentLead, error, history, loading, loadingAgentLead]);


    function formatAddOns(addOns) {
        console.log(addOns)
        return addOns ? addOns.map(addon => addon.replace("Fb", "Facebook")).join(", ") : []
    }

    function rerenderAgentsList(data) {
        var list = []
        for (var i = 0; i < data.length; i++) {
            list.push({
                'id': data[i].node.id,
                'name': data[i].node.user.name
            })


        }
        setAgentsList(list)
    }

    function rerenderAgentList(data) {
        var list = []

        if (data) {
            list.push({
                'agent_name': (data.parent != null ? data.user.name : ''),
                'agent_id': (data.parent != null ? data.id : ''),
                'lead_name': (data.parent != null ? data.parent.user.name : data.user.name),
                'lead_id': (data.parent != null ? data.parent.id : data.id),
                'start_date': data.dateAssigned || '',
            })
        }
        setAgentList(list)
    }

    const agentColumn = [
        {
            title: 'Agent Name \n代理姓名',
            dataIndex: ['agent_name', 'agent_id'],
            key: 'agent_name',
            render: (text, row) => <Link type="text" to={`/car-dealer-detail/agent-detail/${row['agent_id']}`} style={{ color: '#1890FF' }}>{row['agent_name']}</Link>,
        },
        {
            title: 'Agent Lead Name \n代理主管姓名',
            dataIndex: ['lead_name', 'lead_id'],
            key: 'lead_name',
            render: (text, row) => <Link type="text" style={{ color: '#1890FF' }} to={`/car-dealer-detail/agent-lead-detail/${row['lead_id']}`}>{row['lead_name']}</Link>,
        },
        {
            title: 'Start Date \n开始日期',
            dataIndex: 'start_date',
            key: 'start_date',
            render: row => Moment(row['start_date']).format('DD/MM/YYYY'),
        },
    ]

    function rerenderListingList(data) {
        var list = []

        for (var i = 0; i < data.length; i++) {
            list.push({
                'title': data[i].node.title,
                'status': data[i].node.status,
                'id': data[i].node.postId,
                'package': data[i].node.adsPackage?.name,
                'add-ons': formatAddOns(data[i].node.addOns),
            })
        }
        setAdsListing(list)
    }

    const adsListingColumn = [
        {
            title: 'Post ID \n贴文编号',
            dataIndex: 'id',
            key: 'id',
            // render: text => <Link type="text" style={{ color: '#1890FF' }}>{text}</Link>,
        },
        {
            title: 'Ads Title\n广告标题',
            dataIndex: 'title',
            key: 'title',
            // render: text => <Link type="text" style={{ color: '#1890FF' }}>{text}</Link>,
        },
        {
            title: 'Status \n状态',
            dataIndex: 'status',
            key: 'status',
            render: text => statusWidget(text),
        },
        {
            title: 'Package \n配套',
            dataIndex: 'package',
            key: 'package',
            render: text => <Tag color="geekblue">{text}</Tag>
        },
        {
            title: 'Add-ons \n附加项目',
            dataIndex: 'add-ons',
            key: 'add-ons',
        },
    ]

    function statusWidget(status) {
        let color;
        let text;
        switch (status) {
            case 'live':
                color = "#52C41A"
                text = 'Published 已刊登'
                break;
            case 'scheduled':
                color = "#FA8C16"
                text = "Scheduled 待定"
                break
            case 'draft':
                color = "#000000A6"
                text = "Draft 草稿"
                break
            case 'expired':
                color = "#D9D9D9"
                text = "Expired 逾期"
                break
            case 'deleted':
                color = "red"
                text = "Deleted 已删除"
                    break
            default: break;
        }
        return <Badge color={color} text={text} style={{ textTransform: "capitalize" }} />
    }

    function rerenderBillingList(data) {
        var list = []

        for (var i = 0; i < data.length; i++) {
            const  { adsPackage, addOns } = data[i].node
            const adsPackageName = adsPackage?.name ?? "-"
            const listOfAddOns = addOns.map(addOnObj =>{ return addOnObj.name })
            let addOnStr = "-"

            if(adsPackageName === "-"){
                if (addOns.length > 0 && addOns){
                    addOnStr = `${formatAddOns(listOfAddOns)}`
                }
            }
            else{
                if (addOns.length > 0 && addOns){
                    addOnStr = adsPackageName + ` + ${formatAddOns(listOfAddOns)}`
                }
                else{
                    addOnStr = adsPackageName
                }
            }
            

            list.push({
                'invoice_id': data[i].node.invoiceNo,
                'post_id': data[i].node.postId,
                'name': adsPackageName,
                'add_ons': addOnStr,
                'amount': data[i].node.invoiceAmount
            })
        }

        setBillingRecords(list)

    }
    const billingRecordColumn = [
        {
            title: 'Invoice Number \n收据编号',
            dataIndex: 'invoice_id',
            key: 'invoice_id',
            // render: text => <Link type="text" style={{ color: '#1890FF' }} >{text}</Link>,
        },
        {
            title: 'Post ID \n贴文编号',
            dataIndex: 'post_id',
            key: 'post_id',
            render: text => (text === null ? <p>-</p> : <p>{text}</p>)
        }, 
        {
            title: 'Package \n配套',
            dataIndex: 'name',
            key: 'name',
            render: text => (text === null ? <p>-</p> : <Tag color="geekblue">{text}</Tag>)
        }, 
        {
            title: 'Package or Add-ons 配套或附加选项',
            dataIndex: 'add_ons',
            key: 'add_ons',
        },
        {
            title: 'Amount \n金额',
            dataIndex: 'amount',
            key: 'amount',
            render: text => <span>RM{text}</span>,
        },

    ]

    const handleReassignOK = () => {
        ReassignCarDealer({ variables: { id: param.match.params.id, agent: TransferId } })
    }

    const handleRemoveCancel = () => {
        setReassignModalVisible(false)
    }

    const goBack = () => {
        history.goBack()
    }

    function onChangeSelection(value) {
        setTransferId(value)
    }

    const sumOfContent = (list, type) =>{
        const sum = list.reduce((accumulator, curObj) =>{
            return accumulator + curObj.node[type]
        }, 0)

        return isNaN(sum) ? 0 : sum
    }

    return (
        <Layout>
            <Modal title="Reassign Agent 重新分配代理" visible={reassignModalVisible} onOk={handleReassignOK} onCancel={handleRemoveCancel} className="create-modal" footer={[
                <Button key="back" onClick={handleRemoveCancel} style={{ borderRadius: '4px' }}>
                    Cancel 取消
                </Button>,
                <Button key="submit" type="primary" onClick={handleReassignOK} className="submit-button">
                    Confirm 确认
                </Button>,
            ]}>
                <Layout>
                    <Space direction="vertical" style={{ background: 'white' }} >
                        <p>Please select an agent to transfer 请选择欲转换的代理</p>
                        <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Select an Agent 选择代理"
                            optionFilterProp="children"
                            onChange={onChangeSelection}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >

                            {agentsList ? agentsList.map((agent) => (
                                <Option value={agent.id}>{agent.name}</Option>
                            )) : null}
                        </Select>
                    </Space>
                </Layout>
            </Modal>
            {data ? <Layout>
                <Space direction='vertical' style={{ padding: '16px 24px', background: 'white' }}>
                    <Breadcrumb>
                        <Breadcrumb.Item>Homepage 首页</Breadcrumb.Item>
                        {/* <Breadcrumb.Item>
                            广告
                        </Breadcrumb.Item> */}
                        <Breadcrumb.Item>
                            <Link to="/car-dealer">Advertiser 广告商</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{data.advertiser.legalName}</Breadcrumb.Item>
                    </Breadcrumb>
                    <Row justify="space-between" style={{ paddingTop: '20px' }}>
                        <Space direction="horizontal" style={{ alignItems: 'flex-start' }} size={24}>
                            <div style={{ alignSelf: 'baseline' }}>
                                <ArrowLeftOutlined onClick={goBack} />
                            </div>
                            <Avatar size={80} src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                            <Space direction="vertical">
                                <h3>{data.advertiser.legalName}</h3>
                                <p className="car_dealer_p">{data.advertiser.carDealer ? "Car Dealer 汽车经销商" : "Individual 个人卖家"}</p>
                            </Space>
                        </Space>
                        <Space size={16} direction="horizontal" split={<Divider type="vertical" style={{ borderColor: '#E9E9E9', height: '40px', }} />} style={{ paddingRight: '40px' }}>
                            <Space direction="vertical" align="end">
                                <p>Total Published Ad 已刊登广告数量</p>
                                <h1>{data.advertiser.allListings.totalCount}</h1>
                            </Space>
                            <Space direction="vertical" align="end">
                                <p>Total View 总浏览量</p>
                                <h1>{sumOfContent(data.advertiser.allListings.edges, "views")}</h1>
                            </Space>
                            <Space direction="vertical" align="end">
                                <p>Total Clicks 总点击率</p>
                                <h1>{sumOfContent(data.advertiser.allListings.edges, "clicks")}</h1>
                            </Space>
                            <Space direction="vertical" align="end">
                                <p>Total Sales 总业绩</p>
                                <h1>RM {data.advertiser.sales || 0}</h1>
                            </Space>
                        </Space>
                    </Row>
                </Space>

                <Layout style={{ background: 'white', margin: '28px', padding: '24px' }}>

                    <Row justify="space-between" style={{ width: '1080px' }}>
                        <h2>Basic Info 基本资料</h2>
                    </Row>
                    <Row className="basic-info-row">
                        <Col span={7}>
                            <Space direction='vertical' size='small'>
                                <p style={{ margin: "0px" }}>Account Creation Date 账号建立日期: <span>{Moment(data.advertiser.dateCreated).format('DD/MM/YYYY')}</span></p>
                                {/* <p style={{ margin: "0px" }}>{data.advertiser.carDealer ? "Car Dealer 汽车经销商姓名" : "Individual 个人卖家姓名"}: <span>{data.advertiser.legalName}</span></p> */}
                                <p style={{ margin: "0px" }}>{data.advertiser.carDealer ? "Car Dealer ID 汽车经销商编号" : "Individual ID 个人卖家编号"} <span>{data.advertiser.regularId}</span></p>
                                <p style={{ margin: "0px" }}>Mobile Number 手机号码: <span>{data.advertiser.user.phoneNumber}</span></p>
                                <p style={{ margin: "0px" }}>Email 电子邮件: <span>{data.advertiser.user.email}</span></p>
                                {data.advertiser.carDealer && <p style={{ margin: "0px" }}>Company Name 公司名称: <span>{data.advertiser.carDealer.companyName}</span></p>}
                                {data.advertiser.carDealer && <p style={{ margin: "0px" }}>Company Branch 公司分行: <span></span></p>}
                            </Space>
                        </Col>
                        <Col span={7}>
                            <Space direction='vertical' size='small'>
                                <p style={{ margin: "0px" }}>Address 1 地址1: <span>{data.advertiser.address1}</span> </p>
                                <p style={{ margin: "0px" }}>Address 2 地址2: <span>{data.advertiser?.address2}</span> </p>
                                <p style={{ margin: "0px" }}>Postcode 邮政编号: <span>{data.advertiser?.postcode}</span> </p>
                                <p style={{ margin: "0px" }}>City 城市: <span>{data.advertiser?.city}</span> </p>
                                <p style={{ margin: "0px" }}>State 州属: <span>{data.advertiser?.state}</span> </p>
                                <p style={{ margin: "0px" }}>Country 国家: <span>{data.advertiser?.country}</span> </p>
                            </Space>
                        </Col>
                    </Row>
                    <Divider />
                    <Space direction="horizontal" size={22} style={{ marginBottom: '16px' }} align="baseline">
                        <h2>Current Package 目前配套</h2>
                        <Tag color="#F0F5FF" style={{ color: '#2F54EB', border: '1px solid #ADC6FF' }}>{data.advertiser?.adsPackage?.name}</Tag>
                    </Space>


                    <Space direction="horizontal" size={270} className="basic-info-row">
                        <p style={{ margin: "0px" }}>Start Date 开始日期: <span>{Moment(data.advertiser?.adsPackage?.startDate).format('DD/MM/YYYY')}</span></p>
                        <p style={{ margin: "0px" }}>End Date 结束日期: <span>{Moment(data.advertiser?.adsPackage?.endDate).format('DD/MM/YYYY')}</span></p>
                    </Space>
                    <Divider />
                    <Row justify="space-between" style={{ marginBottom: '16px' }}>
                        <h2>Agent 代理</h2>
                        {projectLead ? <Button className='add-new-button' onClick={() => { setReassignModalVisible(true) }}>Reassign 重新分配</Button> : null}

                    </Row>
                    <Table
                        columns={agentColumn}
                        style={{ whiteSpace: "pre" }}
                        dataSource={agentList}
                        pagination={false}
                    />
                    <Space direction="vertical" className="table_className">
                        <h2>Ads Record 广告记录&emsp;<span>{data.advertiser.allListings.totalCount} ads in total 总广告量</span></h2>
                        <Table
                            columns={adsListingColumn}
                            dataSource={adsListing}
                        />
                    </Space>

                    <Space direction="vertical" className="table_className">
                        <h2>Billing Record 账单记录&emsp;<span>{billingTotal} transactions 交易</span></h2>
                        <Table
                            columns={billingRecordColumn}
                            dataSource={billingRecords}
                            pagination={{
                                showSizeChanger: false,
                                current: currentPageNumber, total: billingTotal, pageSize: 5, onChange: (page, pageSize) => {

                                    setCurrentPageNumber(page);
                                    setOffSet((page - 1) * 5);
                                }
                            }}

                        />
                    </Space>


                </Layout>
            </Layout> : <Empty />}
        </Layout>

    )
}

export default CarDealerDetailView;