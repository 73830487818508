import { gql } from "apollo-boost";

export const LoginUser = gql`
  mutation loginUser($email: String!, $password:String!){
    loginInternalUser(input: {email: $email, password: $password}) {
      accessToken
    }
  }
  `

export const ResetPassword = gql`
  mutation ResetPassword($username:String, $password: String, $verificationCode: String){
    resetPassword(input: {username: $username, password: $password, verificationCode: $verificationCode}) {
      ok
      error
    }
  }
  
`

export const ChangePassword = gql`
  mutation ChangePassword($oldPassword: String, $newPassword: String){
    changePassword(input: {oldPassword: $oldPassword, newPassword: $newPassword}) {
      ok
      error
    }  
  }
`

export const CreateTask = gql`
mutation CreateTask($title: String!, $description: String!, $users: [UserInput], $groups: [GroupInput]) {
    createTask(input: {title: $title, description: $description, users: $users, groups: $groups}) {
      ok
      error
    }
  }
  `

export const CreateAgentLead = gql`
mutation CreateAgentLead($name:String!, $phoneNumber:String!, $email: String!, $password:String!, $secondaryId:String!){
  createAgentLead(input: {name: $name, phoneNumber: $phoneNumber, email: $email, password: $password, secondaryId: $secondaryId}) {
    ok
    error
  }
}
`

export const UpdateAgentLead = gql`
mutation UpdateAgentLead($id:ID!, $name:String, $phoneNumber:String){
  updateAgentLead(input: {id: $id, name: $name, phoneNumber: $phoneNumber}) {
    ok
    error
  }
}
`

export const RetiredAgentLead = gql`
mutation RetiredAgentLead($id: ID!, $transfer: ID!){
  retireAgentLead(input:{id:$id,transfer:$transfer}){
    ok
    error
  }
}`

export const CreateAgent = gql`
mutation CreateAgent($name: String!, $phoneNumber: String!, $email:String!, $password: String!, $secondaryId: String!, $agentLead:ID!){
  createAgent(input: {name: $name, phoneNumber: $phoneNumber, email: $email, password: $password, secondaryId: $secondaryId, agentLead: $agentLead}) {
    ok
    error
  }
}
`

export const EditAgent = gql`
mutation EditAgent($id: ID, $name: String, $phoneNumber: String) {
  updateAgent(input: {id: $id, name: $name, phoneNumber: $phoneNumber}) {
    ok
    error
  }
}
`

export const RetiredAgent = gql`
mutation RetiredAgent($id: ID) {
  retireAgent(input: {id: $id}) {
    ok
    error
  }
}
`

export const AssignAgent = gql`
mutation AssignAgent($id: ID!, $agentLead: ID!) {
  assignAgent(input: {id: $id, agentLead: $agentLead}) {
    ok
    error
  }
}
`
export const EditTask = gql`
  mutation EditTask($id: String!, $title: String!, $description: String!, $users:[UserInput], $groups: [GroupInput]){
    editTask(input: {id: $id, title: $title, description: $description, users: $users, groups: $groups}) {
      ok
      error
    }
  }
  `

export const DeleteTask = gql`
mutation DeleteTask($id: String!) {
  deleteTask(input:{id: $id}) {
    ok
  }
}`

export const AssignAdvertiser = gql`
mutation AssignAdvertiser($id: ID, $agent: ID){
  assignAdvertiser(input: {id: $id, agent: $agent}) {
    ok
    error
  }
}
`

export const UpdateListing = gql`
mutation (
  $slug: String!,
  $carBrand: String, 
  $carModel:  String, 
  $carModelVariant:  String, 
  $carCondition: String!, 
  $manufactureYear: Int!, 
  $mileage: Int!, 
  $transmission: String!, 
  $seatCapacity: Int!, 
  $title: String!, 
  $price: Int!, 
  $description: String!, 
  $licensePlate: String!, 
  $imageObjects: [ImageObjectInput],
  $region: RegionStateChoice!, 
  $engine: String,
  $videoUrl: String,
) {
  updateListing(
    input: {
      slug: $slug,
      carBrand: $carBrand, 
      carModel: $carModel, 
      carModelVariant: $carModelVariant, 
      carCondition: $carCondition, 
      manufactureYear: $manufactureYear, 
      mileage: $mileage, 
      transmission: $transmission, 
      seatCapacity: $seatCapacity, 
      title: $title, 
      price: $price, 
      description: $description, 
      licensePlate: $licensePlate, 
      imageObjects: $imageObjects,
      region: $region, 
      engine: $engine,
      videoUrl: $videoUrl
    }) {
      listing {
        slug
        title
      }
      ok
      error
    }
  }`

export const DeleteListing = gql`
mutation DeleteListing($slug: String!) {
  deleteListing(input: {slug: $slug}) {
    ok
    error
  }
}`

export const UpdateIndividualAdvertiser = gql`
mutation UpdateIndividualAdvertiser($id: ID!, $address1: String, $address2: String, $postcode: String, $city: String, $state: String, $country: String) {
  updateIndividualAdvertiser(input: {id: $id, address1: $address1, address2: $address2, postcode: $postcode, city: $city, state: $state, country: $country}) {
    ok
    error
  }
}
`

export const UpdateCarDealer = gql`
mutation UpdateCarDealer($id: ID!, $address1: String, $address2: String, $postcode: String, $city: String, $state: String, $country: String, $companyName: String, $companyBranch: String, $companyRegistrationNo: String) {
  updateCarDealer(input: {id: $id, address1: $address1, address2: $address2, postcode: $postcode, city: $city, state: $state, country: $country, companyName: $companyName, companyBranch: $companyBranch, companyRegistrationNo: $companyRegistrationNo}) {
    ok
    error
  }
}
`

export const CreateFinance = gql`
mutation CreateFinance($name: String!, $phoneNumber: String!, $email: String!, $password: String!) {
  createFinance(input: {name: $name, phoneNumber: $phoneNumber, email: $email, password: $password}) {
    ok
    error
  }
}
`

export const CreateSuperAdmin = gql`
mutation CreateSuperAdmin($name: String!, $phoneNumber: String!, $email: String!, $password: String!) {
  createSuperAdmin(input: {name: $name, phoneNumber: $phoneNumber, email: $email, password: $password}) {
    ok
    error
  }
}
`

export const CreateEditor = gql`
mutation CreateEditor($name: String!, $phoneNumber: String!, $email: String!, $password: String!) {
  createEditor(input: {name: $name, phoneNumber: $phoneNumber, email: $email, password: $password}) {
    ok
    error
  }
}
`

export const CreateOperation = gql`
mutation CreateOperation($name: String!, $phoneNumber: String!, $email: String!, $password: String!) {
  createOperation(input: {name: $name, phoneNumber: $phoneNumber, email: $email, password: $password}) {
    ok
    error
  }
}
`

export const UpdateFinance = gql`
mutation UpdateFinance($id: ID, $name: String, $phoneNumber: String) {
  updateFinance(input: {id: $id, name: $name, phoneNumber: $phoneNumber}) {
    ok
    error
  }
}
`

export const UpdateOperation = gql`
mutation UpdateOperation($id: ID, $name: String!, $phoneNumber: String) {
  updateOperation(input:{id: $id, name: $name, phoneNumber: $phoneNumber}) {
    ok
    error
  }
}
`

export const RetireInternalUser = gql`
mutation RetiredInternalUser($id: ID!) {
  retireInternalUser(input: {id: $id}) {
    ok
    error
  }
}
`

export const UpdateSuperAdmin = gql`
mutation UpdateSuperAdmin($id: ID, $name: String, $phoneNumber: String) {
  updateSuperAdmin(input: {id: $id, name: $name, phoneNumber: $phoneNumber}) {
    ok
    error
  }
}
`

export const UserUpdate = gql`
mutation UpdateUser($firstName: String, $lastName: String, $phoneNumber: String, $photo: Upload) {
  updateUser(input: {firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber, photo: $photo}) {
    user {
      id
    }
  }
}`

export const CreatePromoCode = gql`
mutation CreatePromoCode($name: String, $code: String, $startDate: DateTime, $expirationDate: DateTime, $type: PromoCodeTypeEnum, $value: Int, $limit: Int) {
  createPromoCode(input: {name: $name, code: $code,startDate: $startDate, expirationDate: $expirationDate, type: $type, value: $value, limit: $limit}) {
    ok
    error
  }
}
`

export const UpdatePromoCode = gql`
mutation UpdatePromoCode($id: ID, $name: String, $code: String, $startDate: DateTime, $expirationDate: DateTime, $type: PromoCodeTypeEnum, $value: Int, $limit: Int) {
  updatePromoCode(input: {id: $id, name: $name, code: $code,startDate: $startDate,  expirationDate: $expirationDate, type: $type, value: $value, limit: $limit}) {
    ok
    error
  }
}
`

export const DeletePromoCode = gql`
mutation DeletePromoCode($id: ID) {
  deletePromoCode(input: {id: $id}) {
    ok
    error
  }
}
`

export const UpdateCommission = gql`
mutation UpdateCommission($id: String!, $percentage: Int!) {
  updateCommission(input: {id: $id, percentage: $percentage}) {
    ok
    error
  }
}
`

export const UpdateAddOnPin = gql`
mutation ($id: ID!, $pin: Boolean) {
  updateAddOnPin(input: {id: $id, pin: $pin}) {
    ok
    error
  }
}
`

export const UpdateAddOnPost = gql`
mutation ($id: ID!, $post: Boolean, $fbPostLink: String) {
  updateAddOnPost(input: {id: $id, post: $post, fbPostLink: $fbPostLink}) {
    ok
    error
  }
}
`

export const PublishListing = gql`
mutation PublishListing($slug: String!) {
  publishListing(input: {slug: $slug}) {
    ok
    error
  }
}
`

export const ScheduleListing = gql`
mutation ScheduleListing($slug: String!, $schedule: DateTime!) {
  scheduleListing(input: {slug: $slug, schedule: $schedule}) {
   	ok
    error
  }
}
`

export const UnpublishListing = gql`
mutation UnpublishListing($slug: String!) {
  unpublishListing(input: {slug: $slug}) {
    ok
    error
  }
}
`

export const UnscheduleListing = gql`
mutation UnscheduleListing($slug: String!) {
  unscheduleListing(input: {slug: $slug}) {
    ok
    error
  }
}
`

export const UploadFacebookPostPhoto = gql`
mutation updateAddOnPost($id: ID!, $imageObject: ImageObjectInput) {
  updateAddOnPost(input: {
    id: $id,
    imageObject: $imageObject
  }) {
    ok
    error
  }
}
`

export const RemoveFacebookPostPhoto = gql`
mutation updateAddOnPost($id: ID!) {
  updateAddOnPost(input: {
    id: $id,
    removeImage: true
  }) {
    ok
    error
  }
}
`

export const UploadFacebookPinPhoto = gql`
mutation updateAddOnPin($id: ID!, $imageObject: ImageObjectInput) {
  updateAddOnPin(input: {
    id: $id,
    imageObject: $imageObject
  }) {
    ok
    error
  }
}
`

export const RemoveFacebookPinPhoto = gql`
mutation updateAddOnPin($id: ID!) {
  updateAddOnPin(input: {
    id: $id,
    removeImage: true
  }) {
    ok
    error
  }
}
`