import './body.css';
import 'antd/dist/antd.css';
import React, { useEffect } from 'react';
import '@ant-design/compatible';
import { Layout } from 'antd';
import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import { ApolloProvider } from "react-apollo";
import SideBar from './components/SideBar';
import client from './server/apollo_config'
import Amplify, { Auth } from 'aws-amplify';
// import config from './config.json'
import LoginPage from './pages/login';
import { LOGIN } from './server/constant'
import ForgetPasswordPage from './pages/forget-password';
import { useLocation } from 'react-router';

// const API_URL = 'http://localhost:4000';
// const API_URL = 'https://dashboard.api.mywheels.my';
// const CUBEJS_TOKEN =
//   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MzMzMjMyMTgsImV4cCI6MTYzMzQwOTYxOH0.zpJ1vuLKF-xXJ7Fq-lWIkekSAIEgEoTB3xw1NACtmSE';
// const cubejsApi = cubejs(CUBEJS_TOKEN, {
//   apiUrl: `${API_URL}/cubejs-api/v1`,
// });
const API_URL = process.env.REACT_APP_CUBEJS_URL;
const CUBEJS_TOKEN = process.env.REACT_APP_CUBEJS_TOKEN;
const cubejsApi = cubejs(CUBEJS_TOKEN, {
  apiUrl: `${API_URL}/cubejs-api/v1`,
});

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    // region: config.cognito.REGION,
    // userPoolId: config.cognito.USER_POOL_ID,
    // userPoolWebClientId: config.cognito.APP_CLIENT_ID
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID,
  },
});

const authConfig = Auth.configure();
const login = localStorage.getItem(LOGIN)

const NonLoggedInPage = () =>{
  const { pathname } = useLocation()
  useEffect(() =>{
  }, [pathname])

  const handleNonLoginRedirection = (pathname) =>{
    if(pathname.includes("forget-password")){
      return <ForgetPasswordPage/>
    }
  
    return <LoginPage/>
  }

  return handleNonLoginRedirection(pathname)
}

const AppLayout = ({ children }) => {
  return(
    <Layout
      style={{
        height: 'auto',
      }}
    >
      {/* <Header /> */}
      {login === 'true' ? <SideBar /> : <NonLoggedInPage/>}


      {/* <Layout.Content>{children}</Layout.Content> */}
    </Layout> 
  )
}


function App({ children }) {
  Amplify.configure(authConfig)

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <ApolloProvider client={client}>
        <AppLayout>{children}</AppLayout>
      </ApolloProvider>

    </CubeProvider>
  );
}
// const App = ({ children }) => 

export default App;
