import { Layout, Form, Input, Button, message, Image, Checkbox, Row, Col, Tooltip } from "antd";
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { LOGIN, ROLES, NAME } from '../../server/constant'
import client from '../../server/apollo_config'
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import './forget-password.css'
import { userProfile } from "../../server/query";
import { useHistory } from "react-router-dom";

export default function ForgetPasswordPage(){
    const history = useHistory()
    const [loginTimer, setLoginTimer] = useState(null)
    const [successfulReset, setSuccessfulReset] = useState(false)

    useEffect(() =>{
        if(loginTimer === 0){
            setLoginTimer(null)
            history.push('/login')
            return
        }

        if(loginTimer === null){
            return
        }

        const loginTimerInterval = setInterval(() => {
            setLoginTimer(prevState => {
                return prevState - 1
            })
        }, 1000)
        
        return () =>{
            clearInterval(loginTimerInterval)
        }
    }, [loginTimer, history])

    const onFinish = async function (values) {
        const { email, password, code } = values
        try {
            await Auth.forgotPasswordSubmit(email, code, password).then(() => {
                setLoginTimer(5)
                message.info("重置密码成功！");
                setSuccessfulReset(true)
            })
        }
        catch (error) {
            message.error(`重置密码失败！\n${error.message}`);

        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <Layout className="main-layout">
            <Layout className="main-space">
                <div style={{ textAlign: 'center', marginBottom: '32px' }}>
                    <Image
                        width={160}
                        src='/login-logo.svg'
                        preview={false}
                    />
                </div>
                {successfulReset ? 
                    <div style={{marginTop:"2vh", textAlign:"center"}}>Redirecting you to Login page {loginTimer ?? ""}</div> :  
                    <Form
                        initialValues={{ remember: true }}

                        size="large"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Please input your email.' }]}
                        >
                            <Input 
                                className='login-input' 
                                placeholder="Email" 
                                type="email"
                            />
                        </Form.Item>
                        <Tooltip
                            title={
                                <>
                                    <div>
                                        密码长度至少为8个字符（推荐12个以上），需包含大小写英文字母、数字、特殊符号
                                    </div>
                                    <div>
                                       Length of the password is at least 8 characters, and it must contain upper and lower case English letters, numbers, and special symbols
                                    </div>
                                </>
                            }
                            placement="bottomLeft"
                            trigger="focus"
                        >
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "Required",
                                        pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`])\S{8,99}$/g),
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password 
                                    className='login-input' 
                                    placeholder={"新密码 New Password"}
                                    autoComplete="new-password"
                                />
                            </Form.Item>
                        </Tooltip>
                        <Form.Item
                            name="confirm"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: "Required",
                                    pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`])\S{8,99}$/g),
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('密码不匹配！密码必须一致！\nPassword does not match! Both password has to be the same.'))
                                    },
                                }),
                            ]}
                        >
                            <Input.Password 
                                className='login-input'
                                autoComplete="new-password"
                                placeholder={"确认新密码 Confirm New Password"} 
                            />
                        </Form.Item>

                        <Form.Item
                            name="code"
                            rules={[
                                {
                                    required: true,
                                    message: "Required",
                                },
                            ]}
                            hasFeedback
                        >
                            <Input className='login-input' placeholder={"验证码 Verification code"} />
                        </Form.Item>

                        <Row>
                            <Col offset={12} span={12} style={{ textAlign: 'right' }}>
                                <Button type="text" className='forgot-button' onClick={()=>{history.push("/")}}>回到登入 Back to Login</Button>
                            </Col>
                        </Row>
                        <Form.Item style={{marginTop:"2vh"}}>
                            <Button htmlType="submit" block style={{ background: '#C62828', color: 'white', borderColor: '#C62828', borderRadius: '4px' }}>
                                提交 Submit
                            </Button>
                        </Form.Item>
                    </Form>
                }
            </Layout>
        </Layout>
    )
}