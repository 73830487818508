import { gql } from "apollo-boost";

export const AllUsers = gql`
query ($offSet: Int, $name: String, $registrationSource: String) {
  allUsers(offset: $offSet, first: 5, name:$name, registrationSource: $registrationSource) {
    totalCount
    edges {
      node {
        regularId
        firstName
        lastName
        roles
        email
        phoneNumber
        dateJoined
        registrationReference {
          registrationSource
          referenceLink
          referenceTitle

        }
        advertiser {
          id
          selfie
          id
          icBack
          icFront
        }
        
        
      }
    }
  }
}
`
export const AllEditors = gql`
query ($offSet: Int, $name: String) {
  allEditors(offset: $offSet, name: $name, first: 5,) {
    totalCount
    edges {
      node {
        regularId
        name
        firstName
        lastName
        roles
        email
        phoneNumber
        dateJoined
      }
    }
  }
}
`

export const AllWagtailUsers = gql`
query($offSet: Int, $name: String) {
  allWagtailUsers(offset: $offSet, first: 5, name_Icontains: $name) {
    totalCount
    edges {
      node {
        regularId
        name
        roles
        email
        phoneNumber
        dateJoined
        advertiser {
          id
          icFront
          icFront
          selfie
        }
      }
    }
  }
}
`

export const MyAgentProfile = gql`
{
  myAgentProfile {
    referralCode
    referralLink
    id
    regularId
    secondaryId
    user {
      name
      email
      phoneNumber
    }
  }
}

`

export const AllProjectLeads = gql`
query {
    allProjectLeads {
      edges {
        node {
          firstName
          lastName
          name
          phoneNumber
          id
        }
      }
    }
  }
`

export const MyTasks = gql`
query($offSet: Int) {
  myTasks(offset: $offSet, first: 5) {
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
    edges {
      node {
        sender{
          photo{
            file
          }
        }
        id
        title
        description
        sender {
          name
          photo {
            file
          }
          id
        }
        createdAt
        taskRecipients {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          totalCount
          edges {
            node {
              id
              user {
                name
                id
              }
              group {
                name
                id
              }
            }
          }
        }
      }
    }
  }
}
`

export const AllUserRoles = gql`
{
  allUserRoles{
    value
    displayName
  }
}`

export const AllAgentLead = gql`
 {
  allAgentLeads{
    totalCount
    edges {
      node {
        dateAssigned
        id
        regularId
        user {
          name
          email
          phoneNumber
          dateJoined
          roles
          photo {
            file
          }
        }
        referralCode
        allChildren {
          totalCount
          edges {
            node {
              allAdvertisers {
                totalCount
              }
            }
          }
        }
        allAdvertisers {
          totalCount
        }
        allCarDealers {
          totalCount
        }
      }
    }
  }
}

`

export const AgentLead = gql`
  query AgentLead($id: ID!)  {
    agentLead(id: $id) {
      user {
        email
        name
        phoneNumber
        regularId
        id
        dateJoined
      }
      secondaryId
      sales
      referralCode
      allChildren {
        totalCount
        edges{
          node{
            id
            regularId
            sales
            user{
              name
              id
              regularId
            }
            allCarDealers{
              totalCount
            }
          }
        }
      }
      allCarDealers {
        totalCount
      }
    }
}`

export const AgentSearch = gql`
query AgentSearch($agentId: ID, $agentName: String, $agentType: AgentTypeEnum,$startDate: DateTime, $endDate: DateTime,$offSet: Int) {
  agentSearch(agentId: $agentId, agentName: $agentName, agentType: $agentType, startDate: $startDate, endDate: $endDate, first:5, offset: $offSet) {
    totalCount
    edges {
      node {
        dateAssigned
        id
        regularId
        secondaryId
        parent{
          id
          regularId
          user{
            name
          }
        }
        sales
        user {
          name
          email
          phoneNumber
          dateJoined
          roles
          photo {
            file
          }
        }
        referralCode
        parent {
          id
          user {
            name
          }
        }
        allChildren {
          totalCount
          edges {
            node {
              allAdvertisers {
                totalCount
              }
            }
          }
        }
        allAdvertisers {
          totalCount
        }
        allCarDealers {
          totalCount
        }
      }
    }
  }
}

`

export const AllInternalUsers = gql`
query {
  allInternalUsers {
    edges {
      node {
        
        id
        name
      }
    }
  }
}
`
export const AllAgents = gql`
query($parent: ID){
  allAgents(parent: $parent){
    edges {
      node {
        sales
        parent{
          id
          regularId
          user{
            name
          }
        }
        user {
          email
          name
          phoneNumber
          id
          regularId
        }
        referralCode
        regularId
        id
        allCarDealers {
          totalCount
          edges {
            node {
              id
              user {
                name
              }
            }
          }
        }
      }
    }
  }
}
`

export const Agent = gql`
query Agent($id: ID!) {
  agent(id: $id) {
    user {
      email
      name
      phoneNumber
      regularId
      id
      dateJoined
    }
    sales
    secondaryId
    referralCode
    allAdvertisers {
      totalCount
      edges {
        node {
          id
          regularId
          legalName
          sales
          hasCompany
          carDealer{
            companyName
          }
          allListings {
            totalCount
          }
        }
      }
    }
  }
}
`

export const AdvertiserSearch = gql`
query AdvertiserSearch($offSet: Int, $id: ID, $name: String, $advertiserType: AdvertiserTypeEnum, $startDate: DateTime, $endDate: DateTime, $agentId: ID) {
  advertiserSearch(first: 5, offset: $offSet, id: $id, name: $name, advertiserType: $advertiserType,startDate: $startDate, endDate: $endDate, agentId: $agentId) {
    totalCount
    edges {
      node {
        id
        regularId
        dateCreated
        legalName
        icFront
        icBack
        selfie
        address1
        address2
        postcode
        city
        state
        country
        sales
        adsPackage {
          name
        }
        hasCompany
        carDealer{
          companyName
          companyBranch
          companyRegistrationNo
          ssmDocument
        }
        user {
          email
          dateJoined
          phoneNumber
        }
        agent {
          referralCode
          user {
            name
            
          }
          id
        }
        allListings {
          totalCount
        }
      }
    }
  }
}
`

export const AllListings = gql`
query AllListings($offSet: Int, $status: ListingStatusChoice, $title: String, $postId: ID, $packageId: ID, $advertiser: String, $agent: String, ) {
  allListings(offset: $offSet, first: 5, status: $status, title_Icontains: $title,postId: $postId, packageId: $packageId, advertiser: $advertiser, agent: $agent) {
    totalCount
    edges {
      node {
        agent {
          user {
            name
          }
          parent {
            user {
              name
            }
          }
        }
        owner {
          advertiser {
            legalName
          }
        }
        slug
        urlPath
        id
        postId
      	title
        isEditable
        description
        expireAt
        firstPublishedAt
        lastPublishedAt
        regularId
        latestRevisionCreatedAt
        owner {
          name
        }
        status
        carBrand {
          title
        }
        carModel {
          title
        }
        carModelVariant {
          title
        }
        other
        carCondition {
          name
        }
        addOns
        adsPackage{
          regularId
          name
        }
        manufactureYear
        mileage
        engine
				transmission
        seatCapacity
        price
        licensePlate
        images {
          id
          title
          rendition(width: 300, height: 300) {
            url
          }
          width
          height
          url: originalUrl
        }
        live
        region {
          value
          displayName
        }
        videoUrl
      }
    }
  }
}`

export const AllListingFormInformation = gql`
query AllListingFormInformation {
  allCarBrands {
    edges {
      node {
        slug
        title
        allCarModels {
          edges {
            node {
              slug 
              title
              allCarModelVariants {
                edges {
                  node {
                    slug 
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  allListingCarConditionTags {
    edges {
      node {
        slug
        name
      }
    }
  }
  allListingRegions {
    value
    displayName
  }
}
`

export const userProfile = gql`
{
  myUserProfile {
    roles
    name
    photo {
      file
    }
    phoneNumber
    email
    regularId
    id
  }
}
`

export const allCarDealers = gql`
 {
  allCarDealers {
    totalCount
    edges {
      node {
        id
        regularId
        legalName
        address1
        address2
        postcode
        city
        state
        sales
        adsPackage {
          name
        }
        country
        hasCompany
        carDealer{
          companyName
          companyBranch
          companyRegistrationNo
          ssmDocument
        }
        user {
          name
          email
          phoneNumber
        }
        agent {
          user {
            name
          }
        }
        allListings {
          totalCount
        }
      }
    }
  }
}
`

export const Advertiser = gql`
query Advertiser($id:ID) {
  advertiser(id: $id) {
    id
    regularId
    dateAssigned
    legalName
    address1
    address2
    postcode
    city
    state
    country
    sales
    adsPackage {
      startDate
      endDate
      name
    }
    user {
      email
      photo {
        file
      }
      phoneNumber
      regularId
    }
    agent {
      id
      user {
        name
      }
      parent {
        id
        user {
          name
        }
      }
      dateAssigned
    }
    allListings(status: all, showDeleted: true) {
      totalCount
      edges {
        node {
          id
          postId
          regularId
          title
          status
          adsPackage {
            name
          }
          addOns
          clicks
          views
          status
        }
      }
    }
    carDealer {
      companyName
      companyBranch
      companyRegistrationNo
    }
    adsPackage{
      name
    }
  }
}
`

export const ViewVerificationDocuments = gql`
  query Advertiser($id:ID) {
    advertiser(id: $id) {
      selfie
      icFront
      icBack
    }
  }
`

export const SuperAdmins = gql`
{
  allSuperAdmins{
    totalCount
    edges {
      node {
        email
        name
        phoneNumber
        id
        dateJoined
      }
    }
  }
}
`

export const allOperations = gql`
{
  allOperations {
    totalCount
    edges {
      node {
        email
        name
        phoneNumber
        id
        dateJoined
      }
    }
  }
}
`

export const AllFinances = gql`
 {
  allFinances{
    totalCount
    edges {
      node {
        name
        id
        email
        phoneNumber
        dateJoined
      }
    }
  }
}
`

export const allPayments = gql`
query($offSet: Int){
  allPayments(offset:$offSet, first: 10) {
    totalCount
    edges {
      node {
        id
        orderId
        listing {
          postId
        }
        listingGhost
        advertiser {
          id
          user {
            name
          }
          hasCompany
        }
        paymentAmount
      }
    }
  }
}
`

export const auditLog = gql`
query($offSet: Int,$user_Name_Icontains: String , $actionFlag: String ) {
  allLogs(offset:$offSet ,first:5, user_Name_Icontains:$user_Name_Icontains, actionFlag:$actionFlag) {
    totalCount
    edges {
      node {
        id
        actionTime
        user {
          firstName
          lastName
          name
        }
        objectRepr
        actionFlag
        changeMessage
        action
      }
    }
  }
}
`

export const AllAuditListingsLogs = gql`
query allListingsLogs($offset: Int, $postId: String, $user: String, $role: String, $actionFlag: String, $ipAddress:String, $startDate: DateTime, $endDate: DateTime){
  allListingLogs(first: 5, offset: $offset, postId:$postId, user: $user, role: $role, actionFlag: $actionFlag, ipAddress: $ipAddress, startDate: $startDate, endDate: $endDate  ){
    totalCount
    edges{
      node{
        id
        action
        actionTime
        objectId
        objectRepr
        group{
          name
        }
        listing{
          postId
          owner{
            firstName
            lastName
            name
            roles
          }
        }
        ipAddress
        
      }
    }
  }
}

`

export const AllAdvertisers = gql`
query($offSet: Int) {
  allAdvertisers(offset:$offSet, first:5) {
    totalCount
    edges {
      node {
        id
        legalName
        regularId
        address1
        address2
        postcode
        city
        state
        country
        hasCompany
        dateAssigned
        dateCreated
        selfie
        icFront
        icBack
        agent {
          user {
            name
          }
        }
        sales
        allListings {
          totalCount
        }
        adsPackage {
          name
        }
        carDealer{
          companyName
          companyBranch
          companyRegistrationNo
          ssmDocument
        }
        user {  
          email
          phoneNumber
        }
        allAdsPackages {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
}
`

export const CommissionReports = gql`
query($offSet: Int,$agentName:String, $agentLeadName:String, $startDate:DateTime, $endDate: DateTime, $invoiceNo: String,) {
  
  commissionReport(first: 5, offset: $offSet, agentName: $agentName, agentLeadName: $agentLeadName, startDate:$startDate, endDate: $endDate, invoiceNo: $invoiceNo) {
    totalCount
    edges {
      node {
        invoiceDate
        invoiceAmount
        invoiceNo
        userEmail
        description
        paymentMethod
        payment {
          id
        }
        billing {
          name
        }
        advertiser {
          user {
            email
          }
          legalName
        }
        agent {
          id
          referralCode
          user{
            name
          }
        }
        agentLead {
          id
          referralCode
          user{
            name
          }
        }
        commission
      }
    }
  }
}

`

export const DownloadCommissionReport = gql`
query downloadCommissionReport($agentName: String, $agentLeadName: String, $startDate: DateTime, $endDate: DateTime, $invoiceNo: String) {
  downloadCommissionReport(agentName: $agentName, agentLeadName: $agentLeadName, startDate:$startDate, endDate: $endDate, invoiceNo: $invoiceNo) {
    token
    url
  }
}`

export const DownloadInvoiceDocument = gql`
query($orderId: ID!){
  downloadInvoiceDocument(orderId:$orderId){
    token
    url
  }
}`

export const DownloadOverallStatisticsReport = gql`
{
  downloadOverallStatisticsReport {
    token
    url
  }
}`

export const DownloadAdvertiserVerificationDocument = gql`
query($advertiserId: ID!){
  downloadAdvertiserVerificationDocuments(advertiserId: $advertiserId){
    token
    url
  }
}`
export const SalesReport = gql`
query($offSet: Int, $agentName: String, $agentLeadName: String, $startDate: DateTime, $endDate: DateTime, $invoiceNo: String, $billingName: String, $advertiserName: String, $paymentReference: String, $promoCode: String) {
  salesReport(first: 5, offset: $offSet, agentName: $agentName, agentLeadName: $agentLeadName, startDate: $startDate, endDate: $endDate, invoiceNo: $invoiceNo, billingName: $billingName, advertiserName: $advertiserName, paymentReference: $paymentReference, promoCode: $promoCode) {
    totalCount
    edges {
      node {
        description
        invoiceDate
        invoiceAmount
        invoiceNo
        userEmail
        packagePrice
        promoCode
        payment {
          id
          orderId
        }
        paymentReference{
          referenceId
        }
        billing {
          name
        }
        advertiser {
          legalName
        }
        agent {
          id
          referralCode
          user {
            name
          }
        }
        agentLead {
          id
          referralCode
          user {
            name
          }
        }
        commission
      }
    }
  }
}
`

export const DownloadSalesReport = gql`
query downloadSalesReport($agentName: String, $agentLeadName:String, $startDate: DateTime, $endDate: DateTime, $invoiceNo: String, $billingName: String, $advertiserName: String, $paymentReference: String, $promoCode: String) {
  downloadSalesReport(agentName: $agentName, agentLeadName: $agentLeadName, startDate: $startDate, endDate: $endDate, invoiceNo: $invoiceNo, billingName: $billingName, advertiserName: $advertiserName, paymentReference: $paymentReference, promoCode: $promoCode) {
    url
    token
  }
}`

export const DownloadRevenueReport = gql`
query downloadRevenueReport($agentName: String, $agentLeadName: String, $startDate: DateTime, $endDate: DateTime, $billingName: String) {
  downloadRevenueReport(agentName: $agentName, agentLeadName: $agentLeadName, listingStartDate: $startDate, listingEndDate: $endDate, billingName: $billingName) {
    url
    token
  }
}`

export const DownloadDeferredRevenueReport = gql`
query downloadDeferredRevenueReport($selectDate: DateTime) {
  downloadDeferredRevenueReport(listingDate: $selectDate) {
    url
  }
}`


export const RevenueReport = gql`
query($offSet: Int, $agentName: String, $agentLeadName: String, $startDate: DateTime, $endDate: DateTime, $billingName: String) {
  revenueReport(offset: $offSet, first: 5, agentName: $agentName, agentLeadName: $agentLeadName, listingStartDate: $startDate, listingEndDate: $endDate, billingName: $billingName) {
    totalCount
    edges {
      node {
        listing{
          firstPublishedAt
          lastPublishedAt
          id
          regularId
          postId
        }
        payment {
          id
          dateCreated
          paymentAmount
          isSuccess
          orderId
          billingSet {
            edges {
              node {
                id
              }
            }
          }
        }
        invoiceNo
        invoiceDate
        invoiceAmount
        description
        paymentMethod
        promoCode
        revenue
        packagePrice
        platform
        billing {
          name
        }
        agent {
          user {
            name
          }
          referralCode
          id
        }
        agentLead {
          user {
            name
          }
          referralCode
          id 
        }
        advertiser {
          legalName
        }
      }
    }
  }
}
`

export const DeferredRevenue = gql`
query($offSet: Int, $selectDate: DateTime) {
  deferredRevenueReport(offset: $offSet, first: 5, listingDate: $selectDate) {
    totalCount
    edges {
      node {
        invoiceNo
        invoiceDate
        invoiceAmount
        description
        billing {
          name
        }
        platform
        duration
        deferredRevenue
        revenue
        agent {
          user {
            name
          }
          id
          referralCode
        }
        agentLead {
          user {
            name
          }
          id
          referralCode
        }
        listing{
          firstPublishedAt
        }
      }
    }
  }
}
`

export const AllPromoCodes = gql`
query($offSet: Int) {
  allPromoCodes(offset:$offSet, first:5){
    totalCount
    edges {
      node {
        id
        name
        code
        startDate
        expirationDate
        discount
        price
        limit
      }
    }
  }
}
`

export const AllCommission = gql`
query($offSet: Int) {
  allCommissions(offset: $offSet, first: 5) {
    totalCount
    edges {
      node {
        id
        percentage
        role {
          id
          name
        }
      }
    }
  }
}
`

export const DownloadLeadAgentReport = gql`
{
  downloadAgentLeadReport{
    token
    url
  }
}`

export const DownloadAgentReport = gql`
{
  downloadAgentReport{
    token
    url
  }
}`

export const DownloadCarDealerReport = gql`
{
  downloadCarDealerReport{
    token
    url
  }
}`

export const DownloadAdvertiserReport = gql`
query downloadAdvertiserReport($id: ID, $name: String, $startDate: DateTime, $endDate: DateTime, $advertiserType: AdvertiserTypeEnum) {
  downloadAdvertiserReport(id: $id, name: $name, startDate: $startDate, endDate: $endDate, advertiserType: $advertiserType) {
    token
    url
  }
}`


export const AllFbPins = gql`
query ($pending: Boolean, $active: Boolean, $expired: Boolean, $offSet: Int, $title: String, $postId: ID, $packageId: ID, $advertiser: String, $agent: String,) {
  allFbPins(pending: $pending, active: $active, expired: $expired, offset: $offSet, first:5,title: $title,postId: $postId, packageId: $packageId, advertiser: $advertiser, agent: $agent) {
    totalCount
    edges {
      node {
        id
        regularId
        fbPostLink
        pending
        active
        expired
        dateStarted
        region{
          name
        }
        dateCreated
        image {
          originalUrl
          width
          height
          id
          file
        }
        listing {
          postId
          addOns
          adsPackage {
            name
            regularId
          }
          title
          owner {
            firstName
            lastName
          }
          agent{
            user{
              name
            }
            parent{
              user{
                name
              }
            }
          }
        }
      }
    }
  }
}
`

export const AllFbPosts = gql`
query ($pending: Boolean, $active: Boolean, $expired: Boolean, $offSet: Int,$title: String, $postId: ID, $packageId: ID, $advertiser: String, $agent: String, ) {
  allFbPosts(pending: $pending, active: $active, expired: $expired, offset: $offSet, first: 5,  title: $title,postId: $postId, packageId: $packageId, advertiser: $advertiser, agent: $agent) {
    totalCount
    edges {
      node {
        id
        regularId
        pending
        active
        expired
        dateCreated
        dateStarted
        fbPostLink
        fbImage: image{
          id
          originalUrl
          width
          height
          file
        }
        image{
          id
          originalUrl
          width
          height
          file
        }
        region {
          name
        }
        listing {
          postId
          addOns
          adsPackage {
            name
            regularId
          }
          title
          owner {
            firstName
            lastName
          }
          agent{
            user{
              name
            }
            parent{
              user{
                name
              }
            }
          }
        }
      }
    }
  }
}
`

export const ProductType = gql`
query product_types {
  productTypes(first: 2) {
    edges {
      node {
        id
        name
      }
    }
  }
}`

export const ResetPasswordVerificationCode = gql`
query resetPasswordVerificationCode($username: String) {
  resetPasswordVerificationCode(username: $username)
}
`

export const AllAdvertiserBillings = gql`
query allAdvertiserBillings($id: ID, $offSet: Int) {
  allAdvertiserBillings(id: $id, first:5, offset:$offSet) {
    totalCount
    edges {
      node {
        postId
        invoiceNo
        description
        invoiceAmount
        listing {
          title
          adsPackage {
            name
          }
          addOns
        }
        addOns{
          id
          name
        }
        adsPackage{
          id
          name
        }
      }
    }
  }
}
`

export const Products = gql`
query products($productType: [ID]) {
  products(first: 100, channel: "default", filter:{productTypes:$productType }) {
    edges {
      node {
        id
        name
        pricing {
          priceRange {
            start {
              gross {
                amount
              }
            }
            stop {
              gross {
                amount
              }
            }
          }
        }
        variants {
          id
          name
          sku
          quantityAvailable
          pricing {
            price {
              gross {
                amount
              }
            }
          }
        }
        attributes {
          attribute {
            name
          }
          values {
            name
          }
        }
      }
    }
  }
}
`



export const DownloadClassifiedAdsListingReport = gql`
query downloadClassifiedAdsListingReport($postId: ID, $packageId: ID, $advertiser: String, $agent: String) {
  downloadClassifiedAdsListingReport(postId: $postId, packageId: $packageId, advertiser: $advertiser, agent: $agent) {
    url
  }
}`


export const DownloadFacebookPinPostReport = gql`
query downloadFacebookPinPostReport($postId: ID, $packageId: ID, $advertiser: String, $agent: String) {
  downloadFacebookPinPostReport(postId: $postId, packageId: $packageId, advertiser: $advertiser, agent: $agent) {
    url
  }
}`


export const DownloadFacebookPostReport = gql`
query downloadFacebookPostReport($postId: ID, $packageId: ID, $advertiser: String, $agent: String) {
  downloadFacebookPostReport(postId: $postId, packageId: $packageId, advertiser: $advertiser, agent: $agent) {
    url
  }
}`

export const InvoiceListingReport = gql`
query invoiceListingReport($startDate: DateTime, $endDate: DateTime, $offSet: Int) {
  invoiceListingReport(startDate: $startDate, endDate: $endDate, first: 5, offset: $offSet) {
    totalCount
    edges {
      node {
        id
        key: id
        dateCreated
        invoiceNo
        orderId
        advertiser {
          legalName
        }
        billing {
          name
        }
        preTaxedAmount
        taxAmount
        paymentAmount
        agent {
          referralCode
          user {
            name
          }
          parent {
            user {
              name
            }
          }
        }
      }
    }
  }
}`

export const DownloadInvoiceListingReport = gql`
query downloadInvoiceListingReport($startDate: DateTime, $endDate: DateTime) {
  downloadInvoiceListingReport(startDate: $startDate, endDate: $endDate) {
    url
  }
}`

export const DownloadInvoiceDocuments = gql`
query($orderIds: [ID]!){
  downloadInvoiceDocuments(orderIds: $orderIds){
    url
  }
}
`