import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Layout, Space, Form, Input, DatePicker, Radio, Col, Button, message, InputNumber } from "antd";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useMutation } from 'react-apollo';
import '../ads-setting.css'
import { CreatePromoCode, UpdatePromoCode } from '../../../../server/mutation';
import Moment from 'moment';
import locale from 'antd/lib/locale/zh_CN'
import { ConfigProvider } from 'antd';
const AddPromoCode = (param) => {

    const history = useHistory()
    const [editPromoCode, setEditPromoCode] = useState(false);
    const [code, setCode] = useState('')
    const [discountSelection, setDiscountSelection] = useState("percentage");
    const [form] = Form.useForm()

    const [NewPromoCode] = useMutation(CreatePromoCode, {
        onCompleted(data) {
            if (data.createPromoCode.ok == null) {
                message.error(data.createPromoCode.error);
            } else {
                message.success('Promo code created.')
                history.push('/ads-setting')
            }
        }
    })

    const [EditPromoCode] = useMutation(UpdatePromoCode, {
        onCompleted(data) {
            if (data.updatePromoCode.ok == null) {
                message.error(data.updatePromoCode.error);
            } else {
                message.success('Promo code edited.')
                history.push('/ads-setting')
            }
        }
    })



    useEffect(() => {
        if (param.location.postContent) {
            console.log(param.location.postContent)
            setEditPromoCode(true)
            setCode(param.location.postContent.row.code)
            form.setFieldsValue({
                name: param.location.postContent.row.name,
                code: param.location.postContent.row.code,
                start_date: Moment(param.location.postContent.row.start),
                expired_date: Moment(param.location.postContent.row.expired),
                price: param.location.postContent.row.prices,
                percentage: param.location.postContent.row.discount,
                limit: param.location.postContent.row.limit
            });

            if (param.location.postContent.row.discount === null) {
                setDiscountSelection("price")
            } else {
                setDiscountSelection("percentage")
            }
        }
    }, [])

    const onFinish = (values) => {
        console.log(values)
        var expired_date = Moment.parseZone(values.expired_date).utc().format()
        var start_date = Moment.parseZone(values.start_date).utc().format()


        if (editPromoCode === false) {
            NewPromoCode({
                variables: {
                    name: values.name,
                    code: values.code,
                    expirationDate: expired_date,
                    startDate: start_date,
                    type: (discountSelection === "percentage" ? 'percentage' : 'fixed'),
                    value: (discountSelection === "percentage" ? values.percentage : values.price),
                    limit: values.limit
                }
            })
        } else if (editPromoCode === true) {
            EditPromoCode({
                variables: {
                    id: param.match.params.id,
                    name: values.name,
                    code: (code === values.code ? '' : values.code),
                    expirationDate: expired_date,
                    startDate: start_date,
                    type: (discountSelection === "percentage" ? 'percentage' : 'fixed'),
                    value: (discountSelection === "percentage" ? values.percentage : values.price),
                    limit: values.limit
                }
            })
        }


    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onChange = e => {
        setDiscountSelection(e.target.value);
        var percentage = (editPromoCode === true ? param.location.postContent.row.discount : '')
        var price = (editPromoCode === true ? param.location.postContent.row.prices : '')

        if (e.target.value === 1) {
            form.setFieldsValue({
                price: '',
                percentage: percentage
            });
        } else {
            form.setFieldsValue({
                price: price,
                percentage: ''
            });
        }

    };

    const disableDays = (current) =>{
        return current.isBefore(Moment(), 'days')
    }

    const disabledStartDateHours = () =>{
        const listOfDisabledHours = []

        for (let index = 1; index < 24; index++) {
            listOfDisabledHours.push(index)
        }

        return listOfDisabledHours
    }

    const disabledStartDateMinute = () =>{
        const listOfDisabledMinute = []

        for (let index = 1; index < 60; index++) {
            listOfDisabledMinute.push(index)
        }

        return listOfDisabledMinute
    }

    const disabledEndDateHours = () =>{
        const listOfDisabledHours = []

        for (let index = 0; index < 24; index++) {  
            if(index !== 23){ 
                listOfDisabledHours.push(index)
            } 
        }
        return listOfDisabledHours
    }

    const disabledEndDateMinute = () =>{
        const listOfDisabledMinute = []

        for (let index = 0; index < 60; index++) {
            if(index !== 59){
                listOfDisabledMinute.push(index)
            }
        }

        return listOfDisabledMinute
    }

    return (
        <Layout>
            <Space direction='horizontal' size='middle' className="promo-code-header">
                <ArrowLeftOutlined onClick={() => history.goBack()} />
                <h1>{editPromoCode === true ?
                    'Edit Promo Code 编辑促销代码' : 'Add New Promo Code 新增促销代码'}</h1>
            </Space>
            <Layout className="add-promo-main-form">
                <Col span={12}>
                    <Form
                        name="basic"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        layout="vertical"
                        form={form}
                    >
                        <Form.Item
                            label="Campaign Name 活动名称"
                            name="name"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Code 代码"
                            name="code"
                        >
                            <Input maxLength={12} />
                        </Form.Item>
                        <ConfigProvider locale={locale}>
                            <Space direction="horizontal" size="middle">
                                <Form.Item
                                    label="Start Date 开始日期"
                                    name="start_date"
                                >
                                    <DatePicker 
                                        disabledDate={disableDays}
                                        showTime={{
                                            format:"HH:mm",
                                            disabledHours: disabledStartDateHours,
                                            disabledMinutes: disabledStartDateMinute,
                                            defaultValue:(Moment("0:00:00 ", "HH:mm:ss a")),
                                            hideDisabledOptions:true 
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Expiration Date 截止日期"
                                    name="expired_date"
                                >
                                    <DatePicker 
                                        disabledDate={disableDays}
                                        showTime={{
                                            format:"HH:mm",
                                            disabledHours: disabledEndDateHours,
                                            disabledMinutes: disabledEndDateMinute,
                                            defaultValue:(Moment("23:59:00 ", "HH:mm:ss a")),
                                            hideDisabledOptions:true 
                                        }}
                                    />
                                </Form.Item>
                            </Space>
                        </ConfigProvider>

                        <div style={{ marginBottom: '24px' }}>
                            <Radio.Group onChange={onChange} value={discountSelection}>
                                <Radio value={"percentage"}>Discount Percentage 折扣巴仙</Radio>
                                <Radio value={"price"}>Discount Price 折扣价格</Radio>
                            </Radio.Group>
                        </div>
                        <Space direction="horizontal" size={26}>
                            <Form.Item name="percentage">
                                <InputNumber 
                                    min={1} max={100} 
                                    disabled={discountSelection !== "percentage" ? true : false} 
                                    style={{width:"100%"}}
                                />
                            </Form.Item>
                            <Form.Item
                                name="price">
                                <InputNumber 
                                    min={0.1}
                                    disabled={discountSelection !== "price" ? true : false} 
                                    style={{width:"100%"}}
                                />
                            </Form.Item>
                        </Space>
                        <Form.Item
                            label="Voucher Limit 促销代码数量"
                            name="limit">
                            <InputNumber min={0} style={{width:"100%"}}/>
                        </Form.Item>
                        {/* <Form.Item
                            label="T&C 使用者条例"
                            name="tnc">
                            <Input.TextArea />
                        </Form.Item> */}
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="promo-code-submit-button">
                                Save 储存
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Layout>

        </Layout>
    )
}

export default AddPromoCode;