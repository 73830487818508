import React from 'react';
import PropTypes from 'prop-types';
import { useCubeQuery } from '@cubejs-client/react';
import { Spin, Row, Col, Statistic, Table } from 'antd';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { useDeepCompareMemo } from 'use-deep-compare';
import moment from 'moment';
const PRIMARY_COLOUR= '#CF4848'
const COLORS_SERIES = [
  '#5b8ff9',
  '#5ad8a6',
  '#5e7092',
  '#f6bd18',
  '#6f5efa',
  '#6ec8ec',
  '#945fb9',
  '#ff9845',
  '#299796',
  '#fe99c3',
];
const PALE_COLORS_SERIES = [
  '#d7e3fd',
  '#daf5e9',
  '#d6dbe4',
  '#fdeecd',
  '#dad8fe',
  '#dbf1fa',
  '#e4d7ed',
  '#ffe5d2',
  '#cce5e4',
  '#ffe6f0',
];
const commonOptions = {
  maintainAspectRatio: true,
  interaction: {
    intersect: false,
  },
  plugins: {
    legend: {
      position: 'bottom',
    },
  },
  scales: {
    x: {
      ticks: {
        autoSkip: true,
        maxRotation: 0,
        padding: 12,
        minRotation: 0,
      },
    },
  },
};

const LineChartRenderer = ({ resultSet }) => {
  const datasets = useDeepCompareMemo(
    () =>
      resultSet.series().map((s, index) => ({
        label: s.title,
        data: s.series.map((r) => r.value),
        borderColor: COLORS_SERIES[index],
        pointRadius: 1,
        tension: 0.1,
        pointHoverRadius: 1,
        borderWidth: 2,
        tickWidth: 1,
        fill: false,
      })),
    [resultSet]
  );
  const data = {
    labels: resultSet.categories().map((c) => c.x),
    datasets,
  };

  return <Line type="line" data={data} options={commonOptions}/>;
};

const BarChartRenderer = ({ resultSet, pivotConfig }) => {
  const dateFormat = resultSet.loadResponse.pivotQuery.timeDimensions?.[0]?.granularity
  const dateFormats = {
    week: "YYYY-MM-DD",
    month: "MMMM",
    year: "YYYY"
  }

  const datasets = useDeepCompareMemo(
    () =>
      resultSet.series().map((s, index) => ({
        label: s.title,
        data: s.series.map((r) => r.value),
        backgroundColor: COLORS_SERIES[index],
        fill: false,
      })),
    [resultSet]
  );
  const data = {
    labels: resultSet.categories().map((c) => moment(c.x).format(dateFormats[dateFormat])),
    datasets,
  };

  const option =  {
    // maintainAspectRatio: true,
    interaction: {
      intersect: false,
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
    scales: {
      x: {
        // ticks: {
        //   autoSkip: true,
        //   maxRotation: 0,
        //   padding: 12,
        //   minRotation: 0,
        // },
      },
    },
  };
  const stacked = !([]).includes('measures');
  const options = {
    ...option,
    scales: {
      x: { ...option.scales.x, stacked },
      y: { ...option.scales.y, stacked },
    },
  };
  console.log(data)

  return <Bar type="bar" data={data} options={options} />;
};

const AreaChartRenderer = ({ resultSet }) => {
  const datasets = useDeepCompareMemo(
    () =>
      resultSet.series().map((s, index) => ({
        label: s.title,
        data: s.series.map((r) => r.value),
        pointRadius: 1,
        pointHoverRadius: 1,
        backgroundColor: PRIMARY_COLOUR,
        borderWidth: 0,
        fill: true,
        tension: 0,
      })),
    [resultSet]
  );
  const data = {
    labels: resultSet.categories().map((c) => moment(c.x).format("YYYY-MM-DD")),
    datasets,
  };
  const Options  = {
    
    maintainAspectRatio: true,
    interaction: {
      intersect: false,
    },
    plugins: {
      legend: {
        position: 'bottom',
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        display:false
      },
   }
  };
  const options = {
    ...Options,
    scales: {
      ...Options.scales,
      y: {
        stacked: true,
        display:false
      },
    },
  };
  console.log(data)

  return <Line type="area" data={data} options={options} />;
};

const formatTableData = (columns, data) => {
  function flatten(columns = []) {
    return columns.reduce((memo, column) => {
      if (column.children) {
        return [...memo, ...flatten(column.children)];
      }

      return [...memo, column];
    }, []);
  }

  const typeByIndex = flatten(columns).reduce((memo, column) => {
    return { ...memo, [column.dataIndex]: column };
  }, {});

  function formatValue(value, { type, format } = {}) {
    if (value === undefined) {
      return value;
    }

    if (type === 'boolean') {
      return Boolean(value).toString();
    }

    if (type === 'number' && format === 'percent') {
      return [parseFloat(value).toFixed(2), '%'].join('');
    }

    return value && value.toString();
  }

  function format(row) {
    return Object.fromEntries(
      Object.entries(row).map(([dataIndex, value]) => {
        return [dataIndex, formatValue(value, typeByIndex[dataIndex])];
      })
    );
  }

  return data.map(format);
};

const TableRenderer = ({ resultSet, isAgentLead, pivotConfig }) => {
  const [tableColumns, dataSource] = useDeepCompareMemo(() => {
    const columns = resultSet.tableColumns(pivotConfig);
    return [
      columns,
      formatTableData(columns, resultSet.tablePivot(pivotConfig)),
    ];
  }, [resultSet, pivotConfig]);

  tableColumns.forEach(function(column) {
    console.log(isAgentLead);
    console.log(column);
		if (isAgentLead == "true" && column.key == "CmsAgent.id") {
			column.title = "Agent Lead ID 代理主管编号";
		} else if (isAgentLead == "true" && column.key == "UsersUser.name") {
			column.title = "Agent Lead Name 代理主管姓名";
		} else if (column.key == "CmsAgent.id"){
      column.title = "Agent ID 代理编号";
    } else if (column.key == "UsersUser.name"){
      column.title = "Agent Name代理姓名"
    }

    if (column.key == "CmsPayment.paymentAmount"){
      column.title = "Payment Amount 编辑管理系统销售金额"
    }
  });

  console.log("This is the table Columns -> ", tableColumns);

  return (
    <Table pagination={true} columns={tableColumns} dataSource={dataSource} />
  );
};

const TypeToChartComponent = {
  line: ({ resultSet }) => {
    return <LineChartRenderer resultSet={resultSet} />;
  },
  bar: ({ resultSet, pivotConfig }) => {
    return <BarChartRenderer resultSet={resultSet} pivotConfig={pivotConfig} />;
  },
  area: ({ resultSet }) => {
    return <AreaChartRenderer resultSet={resultSet} />;
  },
  pie: ({ resultSet }) => {
    const data = {
      labels: resultSet.categories().map((c) => c.x),
      datasets: resultSet.series().map((s) => ({
        label: s.title,
        data: s.series.map((r) => r.value),
        backgroundColor: COLORS_SERIES,
        hoverBackgroundColor: COLORS_SERIES,
      })),
    };
    return <Pie type="pie" data={data} options={commonOptions} />;
  },
  number: ({ resultSet, fontSize }) => {
    return (
      // <Row
      //   type="flex"
      //   justify="center"
      //   align="middle"
      //   style={{
      //     height: '100%',
      //     border: 'none'
      //   }}
      // >
      //   <Col>
        <div>
          {resultSet.seriesNames().map((s) => (
             <Statistic  valueStyle={{fontSize: fontSize }} value={resultSet.totalRow()[s.key]} />
          ))}
          </div>
      //   </Col>
      // </Row>
    );
  },
  table: ({ resultSet, isAgentLead, pivotConfig }) => {
    return <TableRenderer resultSet={resultSet} isAgentLead={isAgentLead} pivotConfig={pivotConfig} />;
  },
};
const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map((key) => ({
    [key]: React.memo(TypeToChartComponent[key]),
  }))
  .reduce((a, b) => ({ ...a, ...b }));

const renderChart =
  (Component, fontSize, isAgentLead) =>
  ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} isAgentLead={isAgentLead } fontSize={fontSize } />) ||
    (error && error.toString()) || <Spin />;

const ChartRenderer = ({ vizState, options, fontSize, isAgentLead }) => {
  const { query, chartType } = vizState;
  const component = TypeToMemoChartComponent[chartType];
  const renderProps = useCubeQuery(query);
  return component && renderChart(component, fontSize, isAgentLead)(renderProps);
};

ChartRenderer.propTypes = {
  vizState: PropTypes.object,
  cubejsApi: PropTypes.object,
};
ChartRenderer.defaultProps = {
  vizState: {},
  cubejsApi: null,
};
export default ChartRenderer;
