import { Layout, Space, Breadcrumb, Input, Avatar, Row, Divider, Empty, message, Button, Select, Table, Modal, Tag } from "antd";
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { useQuery, useMutation } from 'react-apollo';
import { Agent, AllAgents } from '../../../../server/query'
import { RetiredAgent, AssignAdvertiser, EditAgent } from '../../../../server/mutation'
import { ROLES } from '../../../../server/constant'
import {
    ArrowLeftOutlined,
} from '@ant-design/icons';
import '../agent.css'
import Moment from 'moment';

const AgentDetailView = (param) => {

    const { confirm } = Modal;
    const { loading, error, data, refetch } = useQuery(Agent, { variables: { id: param.match.params.id } })
    const { loading: loadingAgentList, data: dataAgentList } = useQuery(AllAgents)
    const [retiredAgent] = useMutation(RetiredAgent, {
        onCompleted(data) {
            if (data.retireAgent.ok == null) {
                message.error(data.retireAgent.error);
            } else {
                message.success('Agent removed.')
                history.push('/agent')
            }
        }
    })
    const [updateAgentMutation] = useMutation(EditAgent, {
        onCompleted(data) {
            if (data.updateAgent.ok == null) {
                message.error(data.updateAgent.error);
            } else {
                message.success('Agent updated.')
                setEditModalVisible(false)
                refetch()
            }
        }
    })
    const [AssignCarDealer] = useMutation(AssignAdvertiser, {
        onCompleted(data) {
            if (data.assignAdvertiser.ok == null) {
                message.error(data.assignAdvertiser.error);
            } else {
                message.success('Car dealer reassign.')
                setRemoveModalVisible(false)
                setTransferId('')
                refetch()
            }
        }

    })

    const [carDealerList, setCarDealerList] = useState([])
    const [ReassignList, setReassignList] = useState([]);
    const [agentList, setAgentList] = useState([])
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [superAdmin, setSuperAdmin] = useState(false)
    const [projectLead, setProjectLead] = useState(false)
    const [statusModal, setStatusModal] = useState('delete')
    const [mobile, setMobile] = useState('')
    const [removeModalVisible, setRemoveModalVisible] = useState(false)
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [TransferId, setTransferId] = useState('')
    const [AgentId, setAgentId] = useState('')
    const history = useHistory()
    const { Option } = Select;


    useEffect(() => {
        var roles = localStorage.getItem(ROLES)

        // if (roles.includes('agent')) {
        //     history.push('/inbox')
        // } else {

        if (roles.includes('super_admin') || roles.includes('operation') || roles.includes('finance')) {
            setProjectLead(true)
            setSuperAdmin(true)
        }
        if (roles.includes('project_lead')) {
            setProjectLead(true)
        }
        // }

        if (loading === false && data.agent != null) {
            rerenderListView(data.agent.allAdvertisers.edges)
            setName(data.agent.user.name)
            setMobile(data.agent.user.phoneNumber)
            setEmail(data.agent.user.email)
        }

        console.log('dataAgentList', dataAgentList);
        if (loadingAgentList === false && dataAgentList.allAgents != null) {
            setAgentList(dataAgentList.allAgents.edges)
        }





    }, [data, dataAgentList, error, loading, loadingAgentList]);

    function rerenderListView(data) {
        var list = []
        for (var i = 0; i < data.length; i++) {
            list.push({
                'key': data[i].node.id || '',
                'id': data[i].node.id || '',
                'regular_id': data[i].node.regularId || '',
                'name': data[i].node.legalName || '',
                'company_name': (data[i].node.carDealer != null ? data[i].node.carDealer.companyName : ''),
                'listing': (data[i].node.allListings != null ? data[i].node.allListings.totalCount : ''),
                'current_package': '',
                'fee': data[i].node?.sales,
            })
        }
        setCarDealerList(list)
    }

    const columns = [
        {
            title: 'ID \n编号',
            dataIndex: 'regular_id',
            key: 'regular_id'

        },
        {
            title: 'PIC Name \n负责人姓名',
            dataIndex: ['name', 'id'],
            key: 'name',
            render: (text, row) => <Link type="text" to={`/agent/car-dealer-detail/${row['id']}`} style={{ color: '#1890FF' }}>{row['name']}</Link>,
        },
        {
            title: 'Company Name \n公司名称',
            dataIndex: 'company_name',
            key: 'company_name',
            sorter: (a, b) => a.company_name - b.company_name,
        },
        {
            title: 'Ads \n广告',
            dataIndex: 'listing',
            key: 'listing',
            sorter: (a, b) => a.listing - b.listing,
        },
        {
            title: 'Current package \n目前配套',
            dataIndex: 'current_package',
            key: 'current_package',
            render: text => <Tag color="geekblue">{text}</Tag>
        },
        {
            title: 'Amount \n销售金额',
            dataIndex: 'fee',
            key: 'fee',
            sorter: (a, b) => a.fee - b.fee,
            render: text => <p>RM{text ?? 0}</p>
        },

        {
            title: '',
            dataIndex: 'id',
            key: 'action',
            render: (text, row) => (
                <Button type="text" style={{ color: '#C62828' }} onClick={() => { setStatusModal('edit'); setRemoveModalVisible(true); setAgentId(row['id']) }}>重新分配</Button>

            ),
        },
    ]

    const basicColumns = [
        {
            title: 'ID \n编号',
            dataIndex: 'regular_id',
            key: 'regular_id'

        },
        {
            title: 'PIC Name \n负责人姓名',
            dataIndex: 'name',
            key: 'name',
            render: (text, row) => <Link type="text" style={{ color: '#1890FF' }}>{row['name']}</Link>,
        },
        {
            title: 'Company Name \n公司名称',
            dataIndex: 'company_name',
            key: 'company_name',
            sorter: (a, b) => a.company_name - b.company_name,
        },
        {
            title: 'Ads \n广告',
            dataIndex: 'listing',
            key: 'listing',
            sorter: (a, b) => a.listing - b.listing,
        },
        {
            title: 'Current Package \n目前配套',
            dataIndex: 'current_package',
            key: 'current_package',
            render: text => <Tag color="geekblue">{text}</Tag>
        },
        {
            title: 'Amount \n销售金额',
            dataIndex: 'fee',
            key: 'fee',
            sorter: (a, b) => a.fee - b.fee,
            render: text => <p>RM{text ?? 0}</p>
        },
    ]

    const rowSelection = {
        onChange: (selectedRowKeys) => {
            setReassignList(selectedRowKeys)
        },
    };

    const handleRemoveOK = () => {

        if (ReassignList.length === 0 && AgentId !== "") {
            AssignCarDealer({ variables: { id: AgentId, agent: TransferId } })

        }
        if (ReassignList.length > 0) {
            for (let i = 0; i < ReassignList.length; i++) {

                AssignCarDealer({ variables: { id: ReassignList[i], agent: TransferId } })
            }
        }
    }

    function showDeleteConfirm() {

        confirm({
            title: `Are you sure to remove ${data.agent.user.name}? 是否要删除 ${data.agent.user.name} ？`,

            okText: 'Yes 是',
            okType: 'danger',
            cancelText: 'No 否',
            onOk() {
                retiredAgent({ variables: { id: param.match.params.id } })
            },
            onCancel() {
            },
        });
    }



    const goBack = () => {
        history.goBack()
    }

    const handleRemoveCancel = () => {
        setRemoveModalVisible(false)
    }

    function onChangeSelection(value) {
        setTransferId(value)
    }

    const onChangeModalInput = (e, type) => {
        if (type === 'name') { setName(e.target.value) }
        if (type === 'mobile') { setMobile(e.target.value) }
    }

    const handleCancel = () => {
        setEditModalVisible(false)
    }

    const handleOk = () => {
        updateAgentMutation({ variables: { id: param.match.params.id, name: name, phoneNumber: mobile } })
    }

    return (
        <Layout>
            <Modal title="Edit Profile 编辑档案" visible={editModalVisible} onOk={handleOk} onCancel={handleCancel} className="create-modal" footer={[
                <Button key="back" onClick={handleCancel} style={{ borderRadius: '4px' }}>
                    Back 返回
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk} className="submit-button">
                    Edit 编辑
                </Button>,
            ]}>
                <Layout>
                    <Space direction='vertical' style={{ background: 'white' }} size="large">
                        <div>
                            <h1>Agent Name 代理姓名</h1>
                            <Input placeholder="Agent Name 代理姓名" onChange={(e) => onChangeModalInput(e, 'name')} value={name} />
                        </div>
                        <div>
                            <h1>Mobile Number 手机号码</h1>
                            <Input placeholder="Mobile Number 手机号码" type="number" onChange={(e) => onChangeModalInput(e, 'mobile')} value={mobile} />
                        </div>
                        <div>
                            <h1>Email 电子邮件</h1>
                            <Input placeholder="Email 电子邮件" disabled style={{ color: 'black', background: 'white', cursor: 'auto' }} onChange={(e) => onChangeModalInput(e, 'email')} value={email} />
                        </div>
                    </Space>
                </Layout>
            </Modal>
            <Modal title="Reassign Agent 重新分配代理" visible={removeModalVisible} onOk={handleRemoveOK} onCancel={handleRemoveCancel} className="create-modal" footer={[
                <Button key="back" onClick={handleRemoveCancel} style={{ borderRadius: '4px' }}>
                    Cancel 取消
                </Button>,
                <Button key="submit" type="primary" onClick={handleRemoveOK} className="submit-button">
                    Confirm 确认
                </Button>,
            ]}>
                <Layout>
                    <Space direction="vertical" style={{ background: 'white' }} >
                        <p>Please select an agent to transfer 请选择欲转换的代理</p>
                        {agentList.length !== 0 ?
                            <Select
                                showSearch
                                style={{ width: 200 }}
                                placeholder="Select an Agent 选择代理"
                                optionFilterProp="children"
                                onChange={onChangeSelection}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {agentList.map((agent) => (
                                    <Option value={agent.node.id}>{agent.node.user.name}</Option>
                                ))}
                            </Select>
                            : null
                        }
                    </Space>
                </Layout>
            </Modal>
            {data ? <Layout>
                <Space direction='vertical' style={{ padding: '16px 24px', background: 'white' }}>
                    <Breadcrumb>
                        <Breadcrumb.Item>Homepage 首页</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            Car Ads 广告
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="/agent">Agent 代理</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{data.agent.user.name}</Breadcrumb.Item>
                    </Breadcrumb>
                    <Row justify="space-between" style={{ paddingTop: '20px' }}>
                        <Space direction="horizontal" style={{ alignItems: 'flex-start' }} size={24}>
                            <div style={{ alignSelf: 'baseline' }}>
                                <ArrowLeftOutlined onClick={goBack} />
                            </div>
                            <Avatar size={80} src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                        </Space>
                        <Space size={16} direction="horizontal" split={<Divider type="vertical" style={{ borderColor: '#E9E9E9', height: '40px', margin: '0px 40px' }} />} style={{ paddingRight: '40px' }}>
                            <Space direction="vertical" align="end">
                                <p>Total Advertiser 总广告商数</p>
                                <h2>{data.agent.allAdvertisers.totalCount}</h2>
                            </Space>
                            <Space direction="vertical" align="end">
                                <p>Total Sales 总业绩</p>
                                <h2>RM{data.agent?.sales ?? 0}</h2>
                            </Space>
                        </Space>
                    </Row>
                </Space>

                <Layout style={{ background: 'white', margin: '28px', padding: '24px' }}>
                    <Row justify="space-between">
                        <h2>Basic Info 基本资料</h2>
                        {superAdmin ?
                            <Space direction="horizontal">
                                <Button style={{ borderRadius: '4px' }} onClick={() => { showDeleteConfirm() }}>Remove Account 删除账号</Button>
                                <Button className='add-new-button' onClick={() => { setEditModalVisible(true) }}>Edit Profile 编辑档案</Button>
                            </Space> : null}

                    </Row>
                    <Row justify="space-between" className="basic-info-row">
                        <Space direction='vertical' >
                            <p style={{ margin: "0px" }}>Account Creation Date 账号建立日期: <span>{Moment(data.agent.user.dateJoined).format('DD/MM/YYYY')}</span></p>
                            <p style={{ margin: "0px" }}>Agent 代理姓名: <span>{data.agent.user.name}</span></p>
                            <p style={{ margin: "0px" }}>Mobile Number 手机号码: <span>{data.agent.user.phoneNumber}</span></p>
                            <p style={{ margin: "0px" }}>Address 地址: </p>
                        </Space>
                        <Space direction="vertical">
                            <p style={{ margin: "0px" }}>Agent ID 代理编号: <span>{data.agent?.secondaryId}</span></p>
                            <p style={{ margin: "0px" }}>Email 电子邮件: <span>{data.agent.user.email}</span></p>
                        </Space>

                    </Row>
                    <Divider />
                    <Row justify="space-between">
                        <p>Advertiser 广告商 <span>{data.agent.allAdvertisers.totalCount} Advertiser 广告商</span></p>
                        {projectLead ? <Button disabled={ReassignList.length === 0 ? true : false} onClick={() => { setStatusModal('edit'); setRemoveModalVisible(true) }}>Reassign 重新分配</Button> : null}
                    </Row>

                    <Table
                        rowSelection={projectLead ? rowSelection : null}
                        style={{ whiteSpace: "pre" }}
                        columns={projectLead ? columns : basicColumns}
                        dataSource={carDealerList}
                    />

                </Layout>
            </Layout> : <Empty />}
        </Layout>

    )
}

export default AgentDetailView;