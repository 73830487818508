import { Layout, Form, Input, Button, message, Image, Checkbox, Row, Col, Modal } from "antd";
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { LOGIN, ROLES, NAME } from '../../server/constant'
import client from '../../server/apollo_config'
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import './login.css'
import { userProfile } from "../../server/query";
import { useHistory } from "react-router-dom";

const LoginPage = () => {
    const history = useHistory();
    const [isResetRequested, setResetRequest] = useState(false)
    const [resetPasswordTimer, setResetTimer] = useState(null)
    const [isFPModalVisible, setFPModal] = useState(false)

    useEffect(() =>{
        const login = localStorage.getItem(LOGIN)

        if(login === "true"){
            history.push('/')
        }
    },[history])

    useEffect(() =>{
        if(resetPasswordTimer === 0){
            setResetTimer(null)
            history.push('/forget-password')
            return
        }

        if(resetPasswordTimer === null){
            return
        }

        const resetTimerInterval = setInterval(() => {
            setResetTimer(prevState => {
                return prevState - 1
            })
        }, 1000)
        
        return () =>{
            clearInterval(resetTimerInterval)
        }
    }, [resetPasswordTimer, history])

    const forgotPassword = async (email) => {
        try {
            await Auth.forgotPassword(email).then(() => {
                setResetTimer(5)
                message.success("验证码已发送至您的电子邮件！");
            });
        }
        catch (error) {
            message.error(`无法处理您的请求！\n${error.message}`);
            console.log("Error with forget password", error);
        }
    }

    const onFinish = async function (values) {
        try {
            const user = await Auth.signIn(values.email, values.password).then(e => {
                const { challengeName } = e

                if(challengeName === "NEW_PASSWORD_REQUIRED"){
                    setFPModal(true)
                    return
                }
                console.log('token auth', e.signInUserSession.accessToken.jwtToken)
                localStorage.setItem(LOGIN, 'true')

                client.query({
                    query: userProfile,
                    fetchPolicy: "network-only"
                }).then((res) => {
                    localStorage.setItem(ROLES, res.data.myUserProfile.roles)
                    localStorage.setItem(NAME, res.data.myUserProfile.name)
                    // history.push("/");
                    window.location.reload(true);
                })
                .catch((err)=> {
                    localStorage.setItem(LOGIN, 'false')
                    message.error('The account has been deactivated. Please contact administration for more details.',5);   
                })
            })

        } catch (error) {
            console.log('error signing in', error);
            if (String(error).toLowerCase().includes("incorrect username or password")) {
                message.error('Incorrect username or password.');
            } else if (String(error).toLowerCase().includes("password attempts exceeded")) {
                message.error('Password attempts exceeded. Please wait a few seconds before trying again.');
            }
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <Layout className="main-layout">
            <Layout className="main-space">
                <div style={{ textAlign: 'center', marginBottom: '32px' }}>
                    <Image
                        width={160}
                        src='/login-logo.svg'
                        preview={false}
                    />
                </div>
                <Form
                    initialValues={{ remember: true }}

                    size="large"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Please input your email.' }]}
                    >
                        <Input className='login-input' prefix={<UserOutlined className="site-form-item-icon" style={{ color: '#C62828' }} />} placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your password.' }]}
                    >
                        <Input.Password
                            className='login-input'
                            prefix={<LockOutlined className="site-form-item-icon" style={{ color: '#C62828' }} />}
                            type="password"
                            placeholder={"新密码 New Password"}
                        />
                    </Form.Item>
                    <Row style={{marginBottom:"10px"}}>
                        {/* <Col span={12}>
                            <Form.Item name="remember" valuePropName="checked" >
                                <Checkbox className="remember-me-checkbox">Remember me</Checkbox>
                            </Form.Item>
                        </Col> */}
                        <Col offset={12} span={12} style={{ textAlign: 'right' }}>
                            <Button type="text" className='forgot-button' onClick={()=>{setFPModal(true)}}>忘记密码 Forgot Password</Button>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button htmlType="submit" block style={{ background: '#C62828', color: 'white', borderColor: '#C62828', borderRadius: '4px' }}>
                            登入 Login
                        </Button>
                    </Form.Item>
                </Form>
                <Modal
                    closable={false}
                    centered
                    footer={ isResetRequested ? 
                        [] :
                        [
                            <>
                                <Button
                                    onClick={() => setFPModal(false)}
                                >
                                    取消
                                </Button>
                                <Button
                                    form="FPForm"
                                    key="submit"
                                    htmlType="submit"
                                    style={{ background: '#C62828', color: 'white', borderColor: '#C62828', borderRadius: '4px' }}
                                >
                                    提交
                                </Button>
                            </>
                        ]
                    }
                    title={isResetRequested ? "收到请求" : "忘记密码"}
                    visible={isFPModalVisible}
                    okButtonProps={{
                        style: {
                            backgroundColor: "#C62828",
                            borderColor: "#C62828"
                        }
                    }}
                >
                    {!isResetRequested ?
                        <div>
                            请输入您的电子邮件以接收重置验证码
                        </div> :
                        null
                    }
                    <Form 
                        id='FPForm'
                        onFinish={(e) => {
                            setResetRequest(true)
                            forgotPassword(e.email)
                        }}
                        style={{
                            marginTop: '20px'
                        }}
                    >
                        {isResetRequested ?
                            `将您重定向到重置密码页面 ${resetPasswordTimer ?? ""}` :
                            <Form.Item
                                name="email"
                                rules={[{ required: true, message: "Required" }]}
                            >
                                <Input className='login-input' placeholder="Username" type="email"/>
                            </Form.Item>
                        }
                    </Form>
                </Modal>
            </Layout>
        </Layout>
    )
}

export default LoginPage;