import { Layout, Row, Button, Space, Input, Col, Avatar, Divider, Pagination, Empty, Modal, message, Tag, Select } from "antd";
import { UserOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { MyTasks, AllInternalUsers, AllUserRoles } from '../../server/query'
import { CreateTask, DeleteTask, EditTask } from '../../server/mutation'
import Moment from 'moment';
import './inbox.css'
import { ROLES } from '../../server/constant'

const { Search, TextArea } = Input;
const { Option } = Select;
const { confirm } = Modal;


const InboxPage = () => {

    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [ViewModalVisible, setViewModalVisible] = useState(false);
    const [statusModal, setStatusModal] = useState('create');
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState([]);
    const [recipientList, setRecipientList] = useState([]);
    const [taskTitle, setTaskTitle] = useState('');
    const [taskDescription, setTaskDescription] = useState('');
    const [inboxList, setInboxList] = useState([]);
    const [selectedId, setSelectedId] = useState([]);
    const [rolesList, setRolesList] = useState([]);
    const [superAdmin, setSuperAdmin] = useState(false)
    const [totalTasks, setTotalTasks] = useState(0);
    const [offSet, setOffSet] = useState('0');

    const { loading: allUserRolesLoading, error: allUserRolesError, data: allUserRolesData } = useQuery(AllUserRoles)

    const { loading: allInternalUsersLoading, error: allInternalUsersErrors, data: allInternalUsersData } = useQuery(AllInternalUsers,);
    const [AddTasks] = useMutation(CreateTask, {
        onCompleted(data) {
            if (data.createTask.ok == null) {
                message.error(data.createTask.error);
            } else if (data.createTask.ok === 'yes') {
                mutationSuccess('Task created.')
            }
        }
    });
    const [DeleteTaskMutation] = useMutation(DeleteTask, {
        onCompleted(data) {
            if (data.deleteTask.ok == null) {
                message.error('Error in deleting task.');
            } else if (data.deleteTask.ok === 'yes') {
                mutationSuccess('Task deleted.')
            }
        }
    });
    const [EditTaskMutation] = useMutation(EditTask, {
        onCompleted(data) {
            if (data.editTask.ok == null) {
                message.error(data.editTask.error);
            } else if (data.editTask.ok === 'yes') {
                mutationSuccess('Task updated.')
            }
        }
    });

    const { loading, errors, data, refetch } = useQuery(MyTasks, {
        variables: { offSet }
    });

    function showDeleteConfirm(item) {
        console.log('item', item)
        confirm({
            title: 'Are you sure delete this message? 是否要删除这则信件？',
            content: item.title,
            okText: 'Yes 是',
            okType: 'danger',
            cancelText: 'No 否',
            onOk() {
                DeleteTaskMutation({ variables: { id: item.id } })

            },
            onCancel() {
            },
        });
    }



    useEffect(() => {
        if (loading === false && data !== undefined) {
            setInboxList(data.myTasks.edges)
            console.log('data.myTasks.totalCount',)
            setTotalTasks(data.myTasks.totalCount)
        }

        if (allUserRolesLoading === false && allUserRolesData !== undefined) {
            rerenderRoles(allUserRolesData.allUserRoles)
        }

        var roles = localStorage.getItem(ROLES)

        if (roles.includes('super_admin') || roles.includes('project_lead')) {
            setSuperAdmin(true)
        }

    }, [allInternalUsersData, allInternalUsersLoading, allUserRolesData, allUserRolesLoading, data, errors, loading]);

    const onSearch = value => {
        console.log(value);
    }

    function mutationSuccess(text) {
        message.success(text);
        setCreateModalVisible(false);
        setStatusModal('create')
        setTaskTitle('')
        setTaskDescription('')
        setSelectedAgent([])
        setSelectedGroup([])
        refetch();

    }

    const showModal = () => {
        setCreateModalVisible(true);
        setStatusModal('create')
        setSelectedAgent([])
        setSelectedGroup([])
    };

    const onChangeSearch = (e) => {
        var search = e.target.value
        const result = inboxList.filter(item => item.node.title.includes(search))
        setInboxList(result)

        if (search === '') { setInboxList(data.myTasks.edges) }
    }

    function rerenderRoles(item) {
        var list = []
        for (var i = 0; i < item.length; i++) {
            list.push({
                value: item[i].value,
                name: item[i].displayName
            })
        }

        setRolesList(list)

    }

    const handleOk = () => {
        var list = reassignValue()
        console.log('selectedAgent', selectedAgent)
        if (statusModal === 'create' && list.length > 0) {
            AddTasks({ variables: { title: taskTitle, description: taskDescription, users: [], groups: list } })
        }

        if (statusModal === 'edit' && list.length > 0) {
            EditTaskMutation({ variables: { id: selectedId, title: taskTitle, description: taskDescription, users: [], groups: list } })

        }
    };

    function reassignValue() {

        var list = []


        for (var i = 0; i < selectedAgent.length; i++) {
            list.push({
                'name': selectedAgent[i]
            })
        }

        console.log('list', list)
        return list;
    }

    const closeView = () => {
        setViewModalVisible(false)
        setStatusModal('create')
        setSelectedAgent([])
        setSelectedGroup([])
        setTaskTitle('')
        setTaskDescription('')
    }

    const handleCancel = () => {
        setCreateModalVisible(false);
        setStatusModal('create')
        setTaskTitle('')
        setTaskDescription('')
    };

    const onChangeTitle = (e) => {
        setTaskTitle(e.target.value)
    }

    const onChangeDescription = (e) => {
        setTaskDescription(e.target.value)
    }

    function openViewModal(id, title, description, agent) {
        setTaskTitle(title)
        setSelectedId(id)
        setTaskDescription(description)
        setViewModalVisible(true)
        setStatusModal('edit')

        var list = []
        for (var i = 0; i < agent.length; i++) {
            list.push(agent[i].node.group.name)
        }

        var list2nd = []

        setRecipientList(list)

        agent.forEach((item) => {
            const result = rolesList.find(user => user.name === item.node.group.name)
            console.log(result)

            if (result) {
                list2nd.push(result.value);
            }
        })

        setSelectedAgent(list2nd)

        console.log('list2nd', list2nd)

    }

    function openEditModal() {
        setViewModalVisible(false)
        setCreateModalVisible(true)
    }

    function selectUserChange(value) {
        console.log(value)
        setSelectedAgent(value)
    }

    function deleteTask(taskId) {
        DeleteTaskMutation({ variables: { id: taskId } })
    }

    const roles = localStorage.getItem(ROLES)

    return (
        <Layout>
            <Modal title={statusModal === 'create' ? "Create Message 创建信件" : "Edit Message 编辑信件"} visible={createModalVisible} onOk={handleOk} onCancel={handleCancel} className="create-modal"
                footer={[
                    <Button key="back" onClick={handleCancel} style={{ borderRadius: '4px' }}>
                        Back 返回
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk} className="submit-button">
                        Send 发送
                    </Button>,
                ]}
            >
                <Layout>
                    <Space direction='vertical' style={{ background: 'white' }} size="large">

                        <div>
                            <h1>Title 信件标题</h1>
                            <Input placeholder="" onChange={(e) => onChangeTitle(e)} value={taskTitle} />
                        </div>
                        <div>
                            <h1>Description 信件内容</h1>
                            <TextArea showCount maxLength={2000} onChange={(e) => onChangeDescription(e)} value={taskDescription} />
                        </div>
                        <div>
                            <h1>Select Recipient 选择收件人</h1>
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Please Select 请选择"
                                onChange={selectUserChange}
                                optionLabelProp="label"
                                value={selectedAgent}
                            >

                                {rolesList.map((user) => (
                                    <Option value={user.value} label={user.name}>
                                        {user.name}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </Space>
                </Layout>
            </Modal>

            <Modal title="View Message 检视信件" visible={ViewModalVisible} onOk={handleOk} onCancel={closeView} className="create-modal"
                footer={[
                    <Button key="back" onClick={closeView} style={{ borderRadius: '4px' }}>
                        Back 返回
                    </Button>,
                    <Button key="submit" type="primary" onClick={openEditModal} className="submit-button" style={superAdmin ? { display: 'initial' } : { display: 'none' }} >
                        Edit 编辑
                    </Button>,

                ]}

            >
                <Layout>
                    <Space direction='vertical' style={{ background: 'white' }} size="large">
                        <div>
                            <h1>Title 信件标题</h1>
                            <Input value={taskTitle} disabled style={{ color: 'black', background: 'white', cursor: 'auto' }} />
                        </div>
                        <div>
                            <h1>Description 信件内容</h1>
                            <TextArea value={taskDescription} disabled style={{ color: 'black', background: 'white', cursor: 'auto' }} />
                        </div>

                        <div>
                            <h1>Recipient 收件人</h1>
                            <Row style={{ padding: '4px', border: '1px solid #d9d9d9', borderRadius: '2px' }}>
                                {recipientList.map((recipient, i, arr) => (<Tag>{recipient}</Tag>))}
                            </Row>
                        </div>



                    </Space>
                </Layout>
            </Modal>


            <Row className="page-header">
                <h1>Inbox 收件箱</h1>
                {superAdmin ? <Button type="primary" onClick={showModal}>Create New Inbox 建立新信件</Button> : null}
            </Row>

            <Layout className='main-content'>
                <Space direction='horizontal' style={{ justifyContent: 'space-between' }}>
                    <h1>Send Message 发送信件</h1>
                    <Search placeholder="Input search text 输入搜寻关键字" allowClear onSearch={onSearch} onChange={(e) => onChangeSearch(e)} style={{ width: 280 }} />
                </Space>
                <Layout className='inbox-list'>
                    {inboxList.length > 0 ? <div>{inboxList.map((item) => (
                        <Row style={{ background: 'white', }}>

                            <Col span={2} style={{ textAlign: 'center', alignSelf: 'center' }}>
                                {item.node.sender.photo != null ? <Avatar size="default" icon={<UserOutlined />} /> : <Avatar size="large" src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                            </Col>
                            <Col span={8}>
                                <Space direction="vertical" className="inbox-space-first-box" size={3}>
                                    <p>{item.node.title}</p>
                                    <span>{item.node.description}</span>
                                </Space>
                            </Col>
                            <Col span={6}>

                                <p>Recipient 收件人</p>
                                <Row className="recipient-row">
                                    {item.node.taskRecipients.edges.map((recipient, i, arr) => (<span>{recipient.node.group?.name}  {i !== (arr.length - 1) ? ', ' : ''}</span>))}
                                </Row>

                            </Col>
                            <Col span={4}>
                                <Space direction="vertical" className="inbox-space">
                                    <p>Date 日期</p>
                                    <span>{Moment(item.node.createdAt).format('DD/MM/YYYY')}</span>
                                </Space>
                            </Col>
                            <Col className="action-column" span={4} style={{ textAlign: 'center', alignSelf: 'center' }}>

                                <Space direction='horizontal' size={2} split={<Divider type="vertical" style={{ borderColor: '#D8D8D8' }} />} style={{ paddingRight: '40px' }}>
                                    <Button type="link" onClick={() => openViewModal(item.node.id, item.node.title, item.node.description, item.node.taskRecipients.edges)}>View 检视</Button>

                                    {
                                        superAdmin ? <Button type="link" onClick={() => showDeleteConfirm(item.node)}>Delete 删除</Button> : null
                                    }
                                </Space>
                            </Col>
                            <Divider style={{ margin: '16px 0 16px 0' }} />
                        </Row>
                    ))}
                    </div>
                        : <Empty />}
                    <div style={{ textAlign: 'end' }}>
                        <Pagination onChange={(page, pageSize) => {
                            setOffSet((page - 1) * 5);
                        }} total={totalTasks} defaultPageSize={5} />
                    </div>
                </Layout>

            </Layout>

        </Layout>

    )
}

export default InboxPage;