import { useQuery } from 'react-apollo';
import { Layout, Space, Breadcrumb, Form, Input, Button, Row, Col, Table, Pagination, Select, DatePicker, ConfigProvider } from "antd";
import React, { useState } from 'react';
import { AllAuditListingsLogs, AllUserRoles } from "../../../server/query";
import Moment from 'moment';
import locale from 'antd/lib/locale/zh_CN';

const { RangePicker } = DatePicker;


const AuditLogPage = () => {
    const listOfSelectableRoles = ['agent', 'agent_lead', 'project_lead', 'finance', 'operation', 'super_admin']

    const [form] = Form.useForm();
    const [isSearching, setIsSearching] = useState(false)
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const { loading: allUserRolesLoading, error: allUserRolesError, data: allUserRolesData } = useQuery(AllUserRoles)
    const { loading: allAuditLogsLoading, error, data: allAuditLogsData, refetch } = useQuery(
        AllAuditListingsLogs, {
        skip: !allUserRolesData,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "no-cache",
        variables: { offset: 0 }
    })


    const onFinish = (values) => {
        refetch({offset: 0, ...values, startDate: values.date?.[0] ?? undefined, endDate: values.date?.[1] ?? undefined})
        setCurrentPageNumber(1)
        setIsSearching(true)
    }

    function onResetClicked() {
        form.resetFields();
        console.log(form.getFieldsValue())
        refetch({offset: 0, ...form.getFieldsValue(), startDate: undefined, endDate: undefined})
        setCurrentPageNumber(1)
        setIsSearching(false)
    }

    const column = [
        {
            title: 'Post ID \n贴文编号',
            dataIndex: ["node", "listing", "postId"],
            key: 'postId',
        },
        {
            title: 'User',
            dataIndex: ["node", "listing", "owner"],
            key: 'user',
            render: (text) =>{
                if(text == null){   
                    return ""
                }

                const { firstName, lastName, name } = text

                if(name){
                    return name
                }

                if(lastName){
                    return `${firstName} ${lastName}`
                }

                return firstName
            }
        },
        {
            title: 'Roles \n角色',
            dataIndex: ["node", "group", "name",],
            key: 'roles',
            // render: (text) =>{
            //     if(text != null){
            //         const foundRole =  allUserRolesData.allUserRoles.find(roleObj =>{
            //             const { value } = roleObj
            //             return text?.includes(value)
            //         })

            //         if(foundRole != null){
            //             return foundRole.displayName
            //         }

            //         return null
            //     }
            // }
        },
        {
            title: 'Log \n记录',
            dataIndex: ['node', 'action'],
            key: 'action',
            render: (text) => onChangeAction(text)
        },
        {
            title: "IP Address",
            dataIndex: ["node", "ipAddress"],
            key:'ipAddress'
        },
        {
            title: 'Date \n日期',
            dataIndex: ['node', 'actionTime'],
            key: 'date',
            render: text => <div>{Moment(text).format('DD/MM/YYYY')}</div>
        }
    ]
    function onChangeAction(action) {
        let text;
        switch (action) {
            case 'ADDITION':
                text = 'Add New 新增'
                break;
            case 'CHANGE':
                text = 'Edit 编辑'
                break;
            case 'DELETION':
                text = 'Remove 删除'
                break;
            default: break
        }

        return text
    }

    return (
        <Layout>
            <Space direction='vertical' className="commission_header">
                <Breadcrumb style={{ marginBottom: '16px' }}>
                    <Breadcrumb.Item>Homepage 首页</Breadcrumb.Item>
                    <Breadcrumb.Item>Audit Log 审计记录</Breadcrumb.Item>
                </Breadcrumb>
                <h1>Audit Log 审计记录</h1>
            </Space>
            <Layout style={{ margin: '16px 32px' }}>
                <Form 
                    className="filter-form" 
                    name='basic'
                    onValuesChange={() =>{
                        if(isSearching){
                            setIsSearching(false)
                        }
                    }} 
                    onFinish={onFinish} 
                    form={form} 
                    autoComplete="false"
                >
                    <Row align="top" justify="space-between">
                        <Col span={8}>
                            <Row>
                                <Col span={6}>
                                    <p>Post ID <br />贴文编号</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="postId">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    <p>User</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="user">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    <p>Roles <br />角色</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="role">
                                        <Select>
                                            {allUserRolesData?.allUserRoles?.map(roleObj =>{
                                                const { displayName, value } = roleObj
                                                if(listOfSelectableRoles.includes(value)){
                                                    return <Select.Option value={displayName}>{displayName}</Select.Option>
                                                }

                                                return null
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row>
                                <Col span={6}>
                                    <p>Date <br />日期</p>
                                </Col>
                                <Col span={16}>
                                    <ConfigProvider locale={locale}>
                                        <Form.Item name="date">
                                            <RangePicker style={{width:"100%"}}/>
                                        </Form.Item>
                                    </ConfigProvider>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    <p>Log <br />记录</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="actionFlag">
                                        <Select>
                                            <Select.Option value="1">Add New 新增</Select.Option>
                                            <Select.Option value="2">Edit 编辑</Select.Option>
                                            <Select.Option value="3">Remove 删除</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    <p>IP Address</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="ipAddress">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8} style={{ textAlign: 'end' }}>
                            <Space direction="horizontal">
                                <Button onClick={() => onResetClicked()}>
                                    Reset 重设
                                </Button>
                                <Button className="search-button" htmlType="submit">
                                    Search 搜寻
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>

                <div style={{ background: 'white' }}>
                    <Table 
                        className="main-table" 
                        style={{ whiteSpace: "pre" }} 
                        columns={column} 
                        loading={allAuditLogsLoading}
                        dataSource={allAuditLogsData?.allListingLogs?.edges} 
                        pagination={false} 
                    />
                    <div style={{ textAlign: 'end', margin: '24px' }}>
                        <Pagination 
                            current={currentPageNumber}
                            onChange={(page, pageSize) => {
                                const currentOffset = (page - 1) * 5

                                setCurrentPageNumber(page)

                                if(isSearching){
                                    const searchParms = form.getFieldsValue()
                                    refetch({
                                        offset: currentOffset,
                                        ...searchParms,
                                        startDate: searchParms.date?.[0] ?? undefined, endDate: searchParms.date?.[1] ?? undefined
                                    })
                                    return
                                }

                                form.resetFields();
                                refetch({
                                    offset: currentOffset,
                                    ...form.getFieldsValue(),
                                    startDate: undefined,
                                    endDate: undefined,
                                })

                            }} 
                            total={allAuditLogsData?.allListingLogs?.totalCount ?? 0} 
                            defaultPageSize={5} 
                            showSizeChanger={false} 
                        />
                    </div>
                </div>

            </Layout>
        </Layout>
    )
}


export default AuditLogPage;