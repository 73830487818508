import { Layout, Row, Button, Table, Space, Pagination, Modal, message } from "antd";
import React, { useEffect, useState } from 'react';
import './ads-setting.css'
import { useQuery, useMutation } from 'react-apollo';
import {  AllPromoCodes, Products } from "../../../server/query";
import { DeletePromoCode } from "../../../server/mutation";
import './ads-setting.css'
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import { ROLES } from '../../../server/constant'
import Moment from 'moment';
import { saleorClient } from "../../../server/apollo_config";
const AddSettingPage = () => {
    const history = useHistory()

    const { confirm } = Modal;
    const [promoCodeList, setPromoCodeList] = useState([])
    const [addOnsList, setAddOnsList] = useState()
    const [subscriptionList, setSubscriptionList] = useState()
    const [promoOffSet, setPromoOffSet] = useState(0)
    const [addOnsOffset, setAddOnsOffset] = useState(0)
    const [subscriptionOffSet, setSubscriptionOffSet] = useState(0)
    const [totalPromoCode, setTotalPromoCode] = useState(0)
    const [subscriptionVariables, setSubscriptionVariables] = useState({})
    const [addOnsVariables, setAddOnsVariables] = useState({})
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [roles, setRoles] = useState('')

    const [DeletePromoCodeMutation] = useMutation(DeletePromoCode, {
        onCompleted(data) {
            if (data.deletePromoCode.ok == null) {
                message.error('Error in deleting task.');
            } else if (data.deletePromoCode.ok === 'yes') {
                refetch()
            }
        }
    });

    const { loading, data, refetch } = useQuery(AllPromoCodes, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "no-cache",
        variables: { offSet: promoOffSet }
    })

    const { loading: subscriptionLoading, data: subscriptionData } = useQuery(Products, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "no-cache",
        client: saleorClient,
        variables: { productType: "UHJvZHVjdFR5cGU6Mg==" }
    })
    const { loading: addOnsLoading, data: addOnsData } = useQuery(Products, {
        client: saleorClient,
        variables: { productType: "UHJvZHVjdFR5cGU6NA==" }
    })

    const promoColumns = [
        {
            title: 'Campaign Name \n活动名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Code \n代码',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Start Date \n开始日期',
            dataIndex: 'start',
            key: 'start',
            render: (text, row) => row['start'] !== '' ? (Moment(row['start']).format('DD/MM/YYYY')) : ''
        },
        {
            title: 'Expiration Date \n截止日期',
            dataIndex: 'expired',
            key: 'expired',
            render: (text, row) => row['expired'] !== '' ? (Moment(row['expired']).format('DD/MM/YYYY')) : ''
        },
        {
            title: 'Discount Percentage \n折扣巴仙',
            dataIndex: 'discount',
            key: 'discount',
        },
        {
            title: 'Discount Price \n折扣价格',
            dataIndex: 'prices',
            key: 'prices',
        },
        {
            title: 'Voucher Limit \n促销代码数量',
            dataIndex: 'limit',
            key: 'limit',
        },
        {
            title: '',
            dataIndex: ['code', 'id'],
            key: 'action',
            render: (text, row) => (
                <Space size="middle" className="Table-action">
                    <Link type="text" style={{ color: '#C62828' }} to={{
                        pathname: `/ads-setting/add-promo-code/${row['id']}`,
                        postContent: { row }
                    }}>Edit 编辑</Link>
                    <Button type="text" style={{ color: '#C62828' }} onClick={() => showDeleteConfirm(row['id'], row['code'])}>Remove 删除</Button>
                </Space>
            ),
        },
    ]
    const viewOnlyPromoColumns = [
        {
            title: 'Campaign Name \n广告系列名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Code \n代码',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Start Date \n开始日期',
            dataIndex: 'start',
            key: 'start',
            render: (text, row) => row['start'] !== '' ? (Moment(row['start']).format('DD/MM/YYYY')) : ''
        },
        {
            title: 'Expiration Date \n截止日期',
            dataIndex: 'expired',
            key: 'expired',
            render: (text, row) => row['expired'] !== '' ? (Moment(row['expired']).format('DD/MM/YYYY')) : ''
        },
        {
            title: 'Discount Percentage \n折扣巴仙',
            dataIndex: 'discount',
            key: 'discount',
        },
        {
            title: 'Discount Price \n折扣价格',
            dataIndex: 'prices',
            key: 'prices',
        },
        {
            title: 'Voucher Limit \n促销代码数量',
            dataIndex: 'limit',
            key: 'limit',
        },
    ]

    const AddOnsColumn = [
        {
            title: 'Package Name \n配套名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Price \n价格',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Additional Option \n附加选项',
            dataIndex: 'option',
            key: 'option',
            render: (text, row) => (
                text.map((variant) => {
                    const { name } = variant

                    if(name === "MYWHEELS"){
                        return "MyWheels "
                    }

                    return `${name} `}
                )
            )
        },
    ]

    const SubscriptionColumn = [
        {
            title: 'Package Name \n配套名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Package Type \n配套类型',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Time Limit \n时效',
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: 'Purchase Limit',
            dataIndex: 'purchase',
            key: 'purchase',
        },
        {
            title: 'Price \n价格',
            dataIndex: 'price',
            key: 'price',
        },
    ]

    useEffect(() => {

        const roles = localStorage.getItem(ROLES)
        setRoles(roles)

        if (roles.includes('super_admin') || roles.includes('project_lead') || roles.includes('operation')) {

        } else {
            history.push('/inbox')
        }


        if (loading === false && data !== undefined) {
            renderPromoCode(data.allPromoCodes.edges)
            setTotalPromoCode(data.allPromoCodes.totalCount)
        }

        if (subscriptionLoading === false && subscriptionData !== null) {
            renderSubscription(subscriptionData.products.edges)
        }
        if (addOnsLoading === false && addOnsData !== null) {
            renderAddsOn(addOnsData.products.edges)
        }

    }, [addOnsData, addOnsLoading, data, loading, subscriptionData, subscriptionLoading, history])


    function renderAddsOn(data) {
        var list = []

        for (var i = 0; i < data.length; i++) {
            if(data[i]?.node?.name === "CP Regional Facebook Pin" ){
                continue
            }
            console.log(data[i].node.variants)
            list.push(
                {
                    "name": data[i].node.name,
                    "price": `${data[i].node.pricing.priceRange.start.gross.amount}`,
                    "option": data[i].node.variants
                }
            )

        }

        setAddOnsList(list)
    }

    function renderSubscription(data) {
        console.log('data', data)
        var list = []
        for (var i = 0; i < data.length; i++) {
            list.push(
                {
                    "name": data[i].node.name,
                    "type": data[i].node.name,
                    "time": data[i].node.name,
                    "price": `${data[i].node.pricing.priceRange.start.gross.amount}`
                }
            )

        }
        console.log('list', list)
        setSubscriptionList(list)
    }

    function renderPromoCode(data) {
        var list = []
        for (var i = 0; i < data.length; i++) {

            list.push({
                'id': data[i].node.id,
                'name': data[i].node.name,
                'code': data[i].node.code,
                'expired': data[i].node.expirationDate,
                'start': data[i].node.startDate,
                'discount': data[i].node.discount,
                'prices': data[i].node.price,
                'limit': data[i].node.limit,
            })
        }
        
        setPromoCodeList(list)
        setTotalPromoCode(list.length)
    }

    function showDeleteConfirm(id, code) {
        confirm({
            title: `Are you sure delete this code - ${code}? 是否要删除 ${code}？`,

            okText: 'Yes 是',
            okType: 'danger',
            cancelText: 'No 否',
            onOk() {
                DeletePromoCodeMutation({ variables: { id: id } })

            },
            onCancel() {
            },
        });
    }

    return (
        <Layout>
            <Layout className="promo-code-layout">

                <Row justify='space-between' style={{ marginBottom: '24px' }}>
                    <h1>Promo Code 促销代码 <span>{totalPromoCode} package 配套</span></h1>
                    {roles.includes('project_lead') ? null : <Button className="add-new-button"><Link to='/ads-setting/add-promo-code'>Add New 新增</Link></Button>}

                </Row>
                <div style={{ background: 'white' }}>
                <Table dataSource={promoCodeList} style={{ whiteSpace: "pre"}} columns={roles.includes('project_lead') ? viewOnlyPromoColumns : promoColumns} pagination={false} />
                <div style={{ textAlign: 'end', margin: '24px' }}>
                        <Pagination onChange={(page, pageSize) => {
                            setPromoOffSet((page - 1) * 5);
                        }} total={totalPromoCode} defaultPageSize={5} />
                    </div>
                </div>

            </Layout>
            <Layout className="promo-code-layout">
                <Row justify='space-between' style={{ marginBottom: '24px' }}>
                    <h1>Add-Ons 附加项目 <span>7 package 配套</span></h1>

                </Row>
                <Table dataSource={addOnsList} style={{ whiteSpace: "pre" }} columns={AddOnsColumn} />
            </Layout>
            <Layout className="promo-code-layout">
                <Row justify='space-between' style={{ marginBottom: '24px' }}>
                    <h1>Subscription 订购 <span>7 package name 配套名称</span></h1>
                </Row>
                <Table dataSource={subscriptionList} style={{ whiteSpace: "pre" }} columns={SubscriptionColumn} />
            </Layout>

        </Layout>
    )
}

export default AddSettingPage;