
import { ROLES } from '../server/constant'

function PermissionCheck(location) {

    var pathname = location.pathname


    const agent_page = pathname.includes('car dealer')
    const lead_agent_page = pathname.includes('agent') || pathname.includes('car dealer')
    const project_lead_page = pathname.includes('lead agent') || pathname.includes('car dealer') || pathname.includes('agent')

    const super_admin = localStorage.getItem(ROLES).includes('super_admin')
    const lead_agent = localStorage.getItem(ROLES).includes('agent_lead')
    const agent = localStorage.getItem(ROLES).includes('agent')
    const project_lead = localStorage.getItem(ROLES).includes('project_lead')
    const role = localStorage.getItem(ROLES)
    if (role === 'agent' && agent_page === false) {
        return false
    } else if (role === 'lead_agent' && lead_agent_page === false) {
        return false
    } else if (role === 'project_lead' && project_lead_page === false) {
        return false
    } else if (role === 'super_admin') {
        return true
    } else return true





    // if (agent_page === false && super_admin === false) {
    //     return false
    // } else if (lead_agent_page === true && lead_agent === false) {
    //     return false
    // }

    // else return true
}

export default PermissionCheck