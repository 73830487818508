import { Layout, Row, Button, Space, Input, Col, Form, Table, Empty, message, Pagination, InputNumber, Modal, DatePicker, Select, Upload, Radio, Menu, Badge, Dropdown } from "antd";
import { DownOutlined, ShareAltOutlined, PlusOutlined, CopyOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import moment from "moment";
import axios from "axios";
import client from '../../../server/apollo_config'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { print } from "graphql";
import { useQuery, useMutation } from 'react-apollo';
import { AllListings, AllListingFormInformation, DownloadClassifiedAdsListingReport } from '../../../server/query.js';
import { UpdateListing, DeleteListing, PublishListing, ScheduleListing, UnpublishListing, UnscheduleListing } from '../../../server/mutation.js';
import { Auth } from 'aws-amplify';
import { ROLES } from '../../../server/constant'
import { useLocation, useHistory } from "react-router-dom";
import "./ads-listing.css";
// import PermissionCheck from "../../../components/permission_check.js";
import locale from 'antd/lib/locale/zh_CN';
import S3 from 'aws-s3';
import { v4 as uuidv4 } from 'uuid';

const { Option } = Select;

const AdsListingPage = () => {
    const [filterForm] = Form.useForm();
    const [editForm] = Form.useForm()
    
    const fields = [
        {
            name: "title",
            label: "Title 标题",
            type: "text",
        }, 
        {
            name: "description",
            label: "Description 简介",
            type: "text",
        }, {
            name: "carBrand",
            label: "Brand 品牌",
            type: "select",
        }, {
            name: "carModel",
            label: "Model 型号",
            type: "select",
        },
        {
            name: "carModelVariant",
            label: "Variant 车型",
            type: "select",
        }, {
            name: "carCondition",
            label: "Condition 状态",
            type: "select",
        }, {
            name: "manufactureYear",
            label: "Manufacture Year 制造年份",
            type: "number",
        }, {
            name: "mileage",
            label: "Mileage 里程数",
            type: "number",
        }, {
            name: "engine",
            label: "Engine CC 引擎cc",
            type: "text",
        }, {
            name: "transmission",
            label: "Transmission 变速系统",
            type: "text",
        }, {
            name: "seatCapacity",
            label: "Seat Capacity 座位容量",
            type: "number",
        }, {
            name: "price",
            label: "Price 价钱",
            type: "number",
        }, 
        // {
        //     name: "licensePlate",
        //     label: "Plate Number 车牌号码",
        //     type: "text",
        // }, 
        {
            name: "region",
            label: "State 州属",
            type: "select",
        },{
            name: "videoUrl",
            label: "Youtube Video URL 影片链接",
            type: "text",
        }, {
            name: "images",
            label: "Images 图像",
            type: "image",
        }, {
            name: "publishNow",
            label: "Publish Now 立即刊登",
            type: "radio",
        }, {
            name: "scheduleTime",
            label: "Schedule Time 排定刊登时间",
            type: "date",
        }
    ];

    const [editModalVisible, setEditModalVisible] = useState(false);
    // const [viewModalVisible, setViewModalVisible] = useState(false);
    // const [statusModal, setStatusModal] = useState('create');
    // const [selectedGroup, setSelectedGroup] = useState([]);
    // const [selectedAgent, setSelectedAgent] = useState([]);
    // const [taskTitle, setTaskTitle] = useState('');
    // const [taskDescription, setTaskDescription] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const [offSet, setOffSet] = useState(0);
    const [status, setStatus] = useState('all');
    const [adsList, setAdsList] = useState([]);
    const [publishNow, setPublishNow] = useState(false);
    const [listingFormInformation, setListingFormInformation] = useState({});
    const [selectedAdsListing, setSelectedAdsListing] = useState({});
    const [title, setTitle] = useState("");
    const [postId, setPostId] = useState("");
    const [packageId, setPackageId] = useState("");
    const [advertiser, setAdvertiser] = useState("");
    const [agent, setAgent] = useState("");
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [selectedCarInformation, setSelectedCarInformation] = useState({
        carModel: [],
        carModelVariant: [],
    });
    const [imageInformation, setImageInformation] = useState({
        images: [],
    });
    const { loading, errors, data, refetch } = useQuery(AllListings, {
        variables: { offSet, status, title, packageId, postId, advertiser, agent }
    });
    // const { loading: listingCreationLoading, errors: listingCreationError, data: listingCreationData } = useQuery(ListingCreationQuery);
    const { loading: allListingFormInformationLoading, errors: allListingFormInformationErrors, data: allListingFormInformationData, refetch: allListingFormInformationRefetch } = useQuery(AllListingFormInformation);
    const [DeleteListingMutation, { data: deleteListingResult, loading: deleteListingLoading, error: deleteListingError }] = useMutation(DeleteListing);
    const [UpdateListingMutation, { data: updateListingResult, loading: updateListingLoading, error: updateListingError }] = useMutation(UpdateListing);
    const history = useHistory();
    // const location = useLocation();

    const [publishListing] = useMutation(PublishListing, {
        onCompleted(data) {
            if (data.publishListing.ok == null) {
                message.error(data.publishListing.error);
            } else {
                message.success('Listing published.')
            }
        }
    })

    const [unpublishListing] = useMutation(UnpublishListing, {
        onCompleted(data) {
            if (data.unpublishListing.ok == null) {
                message.error(data.unpublishListing.error);
            } else {
                message.success('Listing unpublished.')
            }
        }
    })

    const [scheduleListing] = useMutation(ScheduleListing, {
        onCompleted(data) {
            if (data.scheduleListing.ok == null) {
                message.error(data.scheduleListing.error);
            } else {
                message.success('Listing scheduled.')
            }
        }
    })

    async function downloadReportFunction() {
        client.query({
            query: DownloadClassifiedAdsListingReport,
            variables: { postId: postId, packageId: packageId, advertiser: advertiser, agent: agent },
            fetchPolicy: "network-only"
        }).then((res) => {
            console.log(res)
            let element = document.createElement('a');
            element.setAttribute('href', res.data.downloadClassifiedAdsListingReport.url);
            element.setAttribute('target', "_blank");
            // element.setAttribute('download', 'output.xlsx');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }).catch((error) => {
            console.log(error)
        })
    }

    const [unscheduleListing] = useMutation(UnscheduleListing, {
        onCompleted(data) {
            if (data.unscheduleListing.ok == null) {
                message.error(data.unscheduleListing.error);
            } else {
                message.success('Listing unscheduled.')
            }
        }
    })

    const column = [
        {
            title: "ID \n编号",
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Title \n标题',
            dataIndex: ['title', 'description', 'url', 'status'],
            key: "title",
            render: (text, row) => <Space direction="vertical" size={0}>
                <p className="title-row">{row['title']}</p>
                <Space size="small">
                    <p className="title-row">
                        <span >{row['description']}</span>
                    </p>
                    {row['status'] === 'live' ? <CopyToClipboard text={row['url']}>
                        <CopyOutlined style={{ color: "#C62828" }} />
                    </CopyToClipboard> : null}
                </Space>

            </Space>
        },
        {
            title: 'Advertiser \n广告商',
            dataIndex: 'owner',
            key: "owner",
        },
        {
            title: "Published Date \n发布日期",
            dataIndex: "datePublished",
            key: "datePublished",
            render: (text) => {
                return(
                    <div style={{width:"150px"}}>
                        {/* <p style={{ textTransform: "capitalize" }}>{row["status"]} Date 日期</p> */}
                        {text ? 
                            <Moment format="DD-MM-YYYY hh:mm a">
                                {/* {getListingDate(row['item'])} */}
                                {text}
                            </Moment>: 
                            <span>-</span>
                        }
                    </div>
                )
            }
        },
        {
            title: "Modified Date \n修改日期",
            dataIndex: 'lastModified',
            key: "lastModified",
            render: (text) =>(
                <div style={{width:"150px"}}>
                    {text ? 
                        <Moment format="DD-MM-YYYY hh:mm a">
                            {/* {getListingDate(row['item'])} */}
                            {text}
                        </Moment>: 
                        <span>-</span>
                    }
                </div>
            )
        },
        {
            title: "Expiry Date \n到期日",
            dataIndex: 'expiryDate',
            key: "expiryDate",
            render: (text) =>(
                <div style={{width:"150px"}}>
                     {text ? 
                        <Moment format="DD-MM-YYYY hh:mm a">
                            {/* {getListingDate(row['item'])} */}
                            {text}
                        </Moment>: 
                        <span>-</span>
                    }
                </div>
            )
        },
        {
            title: 'Status \n状态',
            dataIndex: 'status',
            key: "status",
            render: (text) => statusWidget(text)
        },
        {
            title: 'Add-Ons \n附加项目',
            dataIndex: 'addOns',
            key: "addOns",
            render: (text) => <div> {text && formatAddOns(text)}</div>
        },
        {
            title: 'Agent \n代理',
            dataIndex: 'agent',
            key: "agent",
        },
        {
            title: 'Agent Lead \n代理主管',
            dataIndex: 'agentLead',
            key: "agentLead",
        },
        {
            title: 'Ads Package ID \n广告配套编号',
            dataIndex: 'adsPackageId',
            key: 'adsPackageId',
        },
        {
            title: 'Package \n配套',
            dataIndex: 'adsPackage',
            key: 'adsPackage',
        },
        {
            title: '',
            dataIndex: 'item',
            key: 'action',
            render: (text) => (
                <Dropdown overlay={actionsMenu(text)}>
                    <Button type="link" onClick={e => e.preventDefault()}>
                        More 查看更多 <DownOutlined style={{ color: "red" }} />
                    </Button>
                </Dropdown>
            ),
        },
    ]

    useEffect(() => {

        const roles = localStorage.getItem(ROLES)

        if (roles.includes('super_admin') || roles.includes('project_lead') || roles.includes('operation')) {

        } else {
            history.push('/inbox')
        }

        console.log(data, errors)
        if (loading === false && data != null) {
            // Don't forget to re-authenticate
            // setAdsList(data.allListings.edges);
            rerenderListView(data.allListings.edges);
            setTotalCount(data.allListings.totalCount)
        }
        if (allListingFormInformationLoading === false) {
            setListingFormInformation(allListingFormInformationData);
        }
    }, [allListingFormInformationData, allListingFormInformationErrors, allListingFormInformationLoading, data, errors, history, loading]);

    function rerenderListView(data) {
        var list = []
        for (var i = 0; i < data.length; i++) {

            list.push({
                title: data[i].node.title,
                description: data[i].node.description,
                owner: data[i].node.owner?.advertiser?.legalName,
                datePublished: data[i].node.lastPublishedAt,
                status: data[i].node.status,
                publishedDate: data[i].node.firstPublishedAt,
                lastModified: data[i].node.latestRevisionCreatedAt,
                expiryDate: data[i].node.expireAt,
                addOns: data[i].node.addOns,
                item: data[i],
                agent: data[i].node.agent?.user?.name,
                agentLead: data[i].node.agent?.parent?.user?.name,
                adsPackageId: data[i].node.adsPackage?.regularId,
                adsPackage: data[i].node.adsPackage?.name,
                id: data[i].node.postId,
                videoUrl: data[i].node.videoUrl,
                url: process.env.REACT_APP_MYWHEELS_URL + data[i].node.urlPath.split('/home')[1]
            })
        }
        console.log(list)
        setAdsList(list);

    }

    async function deleteListing(slug) {
        const data  = await DeleteListingMutation({ variables: { slug: slug } });
        const { ok, error } = data.data.deleteListing
        
        if(error){
            message.error(error)
            console.log(error)
            return
        }

        if(ok === "yes"){
            refetch()
            
        }
    }


    const handleCancel = () => {
        setEditModalVisible(false);
    };

    function statusWidget(status) {
        var text;
        var color;
        switch (status) {
            case 'expired':
                text = ' Expired 逾期'
                color = "#bfbfbf"
                break;
            case 'live':
                text = ' Published 已刊登'
                color = '#52c41a'
                break;
            case 'schedule':
                text = ' Pending 待定'
                color = '#f5222d'
                break;
            default: break
        }

        return <Badge color={color} text={text} style={{ textTransform: "capitalize" }} />

    }

    function formatAddOns(addOns) {
        return addOns.map(addon => addon.replace("Fb", "Facebook")).join(", ")
    }

    function changeListingStatus(event) {
        setStatus(event.target.value);
    }

    async function onFinish(values) {
        const {
            carCondition, manufactureYear, licensePlate, mileage, 
            price, seatCapacity, title, transmission, 
            description, region, engine, videoUrl, carBrand, carModel, carModelVariant,
            scheduleTime
        } = editForm.getFieldsValue()

        if(publishNow === false && scheduleTime == null){
            message.error("Please select a valid publish time!")
            return
        }

        if(carCondition == null){
            message.error("Please select a valid car condition!")
            return
        }

        if(carBrand == null){
            message.error("Please select a valid car brand!")
            return
        }

        const scheduleTimeISO = publishNow ? null : scheduleTime.toISOString()

        const session = await Auth.currentSession();
        var token = session.getAccessToken().getJwtToken();

        let options = {
            url: process.env.REACT_APP_PUBLIC_GRAPHENE_URL,
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                query:{},
                variables:{}
            }
        }

        options.data.query = print(UpdateListing)
        options.data.variables = {
            slug: selectedAdsListing.slug,
            carBrand,
            carModel,
            carModelVariant,
            carCondition,
            manufactureYear,
            licensePlate,
            mileage,
            price,
            seatCapacity,
            title,
            transmission: transmission,
            description: description,
            imageObjects: imageInformation.images.map((image) => {
                const {originalUrl: url, width, height, uuid, title } = image
                return {
                    url,
                    fileName: uuid ?? title,
                    width, height,
                }
            }),
            region: region,
            engine: engine,
            videoUrl
        }

        axios(options).then(async res => {
            const { ok, error } = res.data.data.updateListing
            
            if(error){
                message.error(error)
                console.log(error)
                return
            }

            if(ok){
                if (publishNow) {
                    // await unscheduleListing({variables: {slug: selectedAdsListing.slug}})
                    const { data:{publishListing: { ok, error }} } = await publishListing({ variables: { slug: selectedAdsListing.slug } })
                    if(error){
                        message.error(error)
                        console.log(error)
                        return
                    }

                    if(ok){
                        refetch()
                        setEditModalVisible(false);
                    }
                    
                } else {

                    const { data:{ unpublishListing: { ok, error }} } = await unpublishListing({ variables: { slug: selectedAdsListing.slug } })
                    if(error){
                        message.error(error)
                        console.log(error)
                        return
                    }

                    if(ok){
                        const { data:{ scheduleListing: { ok, error }} } = await scheduleListing({ variables: { slug: selectedAdsListing.slug, schedule: scheduleTimeISO } })
                        if(error){
                            message.error(error)
                            console.log(error)
                            return
                        }

                        if(ok){
                            refetch();      
                            setEditModalVisible(false); 
                        }
                    }
                }
            }
        }).catch(error => {
            message.error(error)
            console.log(error);
        });
    }

    function onFinishFilter(values) {
        setTitle(values.title)
        setPostId(values.post_id)
        setPackageId(values.package_id)
        setAgent(values.agent)
        setAdvertiser(values.advertiser)

    }

    function onResetClicked() {
        filterForm.resetFields();
        setTitle('')
        setPostId('')
        setPackageId('')
        setAgent('')
        setAdvertiser('')

    }

    function onRadioChange(event) {
        setPublishNow(event.target.value);
    }

    function onSelectChange(value, option, fieldName) {
        let carModelOptions, carModelVariantOptions = null;
        switch (fieldName) {
            case "carBrand":
                carModelOptions = listingFormInformation.allCarBrands.edges.find(carBrand => carBrand.node.slug === value).node.allCarModels;
                carModelVariantOptions = carModelOptions.edges?.[0]?.node?.allCarModelVariants;
                setSelectedCarInformation({ carModel: carModelOptions, carModelVariant: carModelVariantOptions });
                break;
            case "carModel":
                carModelOptions = listingFormInformation.allCarBrands.edges.find(carBrand => carBrand.node.slug === editForm.getFieldValue("carBrand")).node.allCarModels;
                carModelVariantOptions = carModelOptions.edges.find(carModel => carModel.node.slug === value).node.allCarModelVariants;
                setSelectedCarInformation(prevState => ({ ...prevState, carModelVariant: carModelVariantOptions }));
                break;
            case "other":
                carModelOptions = listingFormInformation.allCarBrands.edges.find(carBrand => carBrand.node.slug === editForm.getFieldValue("carBrand")).node.allCarModels;
                carModelVariantOptions = carModelOptions.edges.find(carModel => carModel.node.slug === value).node.allCarModelVariants;
                setSelectedCarInformation(prevState => ({ ...prevState, carModelVariant: carModelVariantOptions }));
                break;
            default:
                return;
        }
    }

    function onFormValuesChange(changedValues) {
        const formFieldName = Object.keys(changedValues)[0];
        switch (formFieldName) {
            case "carBrand":
                editForm.setFieldsValue({ carModel: undefined, carModelVariant: undefined });
                break;
            case "carModel":
                editForm.setFieldsValue({ carModelVariant: undefined });
                break;
            default:
                return;
        }
    }

    const disableDays = (current) =>{
        return current < moment().subtract(1, 'day')
    }

    const disabledHours = () => {
        let hours = [];
        let date = editForm.getFieldValue('scheduleTime')
    
        //Check if user has selected today's date in the datepicker 
        if (date?.toObject()?.date === moment().toObject().date) {
            for (let i = 0; i < moment().hour(); i++) {
                //If current hour is past 45th minute mark
                if(moment().minutes() > 45){
                    //Disable current hour
                    hours.push(i + 1)
                }
                hours.push(i);
            }
        }
        return hours;
    }

    const disabledMinutes = (selectedHour) => {
        const minutes = []
        if(selectedHour === -1){
            return [0, 15, 30, 45]
        }
        else{
            for (let i = 0; i < 60; i+=15) {
                if(selectedHour === moment().toObject().hours && moment().toObject().minutes > i) {
                    minutes.push(i)
                }
            }
           
        }
        return minutes;
    }

    function getInputType(field) {
        switch (field.type) {
            case "text":
                return field.name === "description" ?
                    <Input.TextArea minRows={2} maxLength={2000} placeholder={field.label} /> :
                    <Input placeholder={field.label} />;

            case "number":
                return <InputNumber style={{ width: '100%' }} />;

            case "select":
                const listingFormInformationFilters = {
                    'carBrand': listingFormInformation?.allCarBrands?.edges,
                    'carModel': selectedCarInformation?.carModel?.edges,
                    'carModelVariant': selectedCarInformation?.carModelVariant?.edges,
                    'carCondition': listingFormInformation?.allListingCarConditionTags?.edges,
                    'region': listingFormInformation?.allListingRegions,
                };
                const { [field.name]: listingFormInformationFilter } = listingFormInformationFilters;

                const selectOptions = [];

                function getOptionType(field, filterItem) {
                    if (field.name === "region") return <Option key={filterItem.value} value={filterItem.value}>{filterItem.displayName}</Option>;

                    return <Option key={filterItem.node.slug} value={filterItem.node.slug}>{filterItem.node?.title || filterItem.node?.name}</Option>
                }

                if (listingFormInformationFilter) listingFormInformationFilter.forEach(item =>
                    selectOptions.push(getOptionType(field, item)));

                return <Select onChange={(value, option) => onSelectChange(value, option, field.name)}>{selectOptions}</Select>;

            case "radio":
                return <Radio.Group onChange={onRadioChange} buttonStyle="solid">
                    <Radio.Button defaultChecked value={true}>Yes 是</Radio.Button>
                    <Radio.Button value={false}>No 否</Radio.Button>
                </Radio.Group>;

            case "date":
                return (
                    <DatePicker 
                        locale={locale} 
                        placeholder="Select Date 选择日期" 
                        disabled={publishNow} 
                        showTime={{ 
                            defaultValue: moment("00:00", "hh:mm a"), 
                            format: "hh:mm a",
                            disabledHours,
                            disabledMinutes,
                            minuteStep: 15
                        }} 
                        disabledDate={disableDays}
                    />
                )

            case "image":
                const customRequest = ({ file }) =>{
                    // less than 10kb or more than 10mb
                    if (file.size < 10240 || file.size > 10485760) {
                        message.error("抱歉！图片大小只限于10KB至10MB。Sorry, accepted image size is only within 10KB to 10MB");
                        return ;
                    }
            
                    const config = {
                        bucketName: process.env.REACT_APP_PUBLIC_S3_NAME,
                        region: process.env.REACT_APP_PUBLIC_S3_REGION,
                        dirName: `media/original_images`,
                        accessKeyId: process.env.REACT_APP_PUBLIC_S3_ACCESS_KEY,
                        secretAccessKey: process.env.REACT_APP_PUBLIC_S3_SECRET_KEY,
                    }
                    const S3Client = new S3(config)
                    const uuid = uuidv4() //as filename
                    S3Client.uploadFile(file, uuid)
                    .then(data => {
                        const img = new Image()
                        img.onload = () =>{
                            setImageInformation(prevImageInfoObj =>{
                                return {
                                    images: [...prevImageInfoObj.images, { ...file, url: data.location, originalUrl: data.location, name: file.name, width: img.width, height: img.height, uuid: uuid  }]
                                }
                            })
                        }
            
                        img.src = data.location
                    })
                    .catch(err => console.error(err))
                }

                const onRemove = (file) =>{
                    const config = {
                        bucketName: process.env.REACT_APP_PUBLIC_S3_NAME,
                        region: process.env.REACT_APP_PUBLIC_S3_REGION,
                        dirName: `media/original_images`,
                        accessKeyId: process.env.REACT_APP_PUBLIC_S3_ACCESS_KEY,
                        secretAccessKey: process.env.REACT_APP_PUBLIC_S3_SECRET_KEY,
                    }
                    const S3Client = new S3(config)
                    const splitFileNameDot = file.name.split(".")
                    S3Client.deleteFile(splitFileNameDot[0])
                    .then(() => {
                        setImageInformation(prevImageInfoObj =>{
                            const { images: prevImages } = prevImageInfoObj

                            const newImages = prevImages.filter(currFile => { return currFile.uid !== file.uid })
                            return {
                                images: [...newImages]
                            }
                        })
                    })
                    .catch(err => console.error(err))
                }            

                return (
                    <Upload
                        maxCount={25}
                        locale={locale}
                        listType="picture-card"
                        fileList={imageInformation.images}
                        customRequest={customRequest}
                        onRemove={onRemove}
                        accept='.jpg,.jpeg,.png'
                    >
                        <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>Upload 上传</div>
                        </div>
                    </Upload>
                )
            default:
                return null
        };
    }

    const actionsMenu = item => (

        <Menu>
            <Menu.Item>
                <Button type="link" 
                    onClick={() => {
                        setEditModalVisible(true);
                        let selectedListing = Object.assign({}, item.node);
                        selectedListing = Object.assign(selectedListing, {
                            carBrand: item.node.carBrand?.title.toLowerCase().replace(" ", "-"),
                            carModel: item.node.carModel?.title.toLowerCase().replace(" ", "-"),
                            carModelVariant: item.node.carModelVariant?.title.toLowerCase().replace(" ", "-"),
                            carCondition: item.node.carCondition?.name.toLowerCase().replace(" ", "-"),
                            region: item.node.region.value,
                            scheduleTime: item.node?.goLiveAt ? moment(new Date(Date.parse(item.node?.goLiveAt)), "YYYY-MM-DD HH:mm:ss") : null,
                            publishNow: item.node?.live,
                        });
                        setSelectedAdsListing(selectedListing);

                        // extract url to attune for Antd Upload component restriction. 
                        selectedListing.images = selectedListing.images.map((element, index) => ({ ...element, 
                            uid: index,
                            url: element?.rendition?.url,
                            originalUrl: element?.url,
                            name: element?.title
                        }));

                        setImageInformation({ images: selectedListing.images});
                        // pre-populate forms with selected listing
                        editForm.setFieldsValue(selectedListing);

                        // to enable/disable schedule time date picker
                        setPublishNow(item.node?.live);

                        // for the purpose of displaying related car models and car model variants based on selected listing on the forms
                        let carModelOptions = listingFormInformation.allCarBrands.edges.find(carBrand => carBrand.node.slug === selectedListing.carBrand)?.node?.allCarModels;
                        let carModelVariantOptions = carModelOptions?.edges.find(carModel => carModel?.node?.slug === selectedListing.carModel)?.node?.allCarModelVariants;
                        setSelectedCarInformation({ carModel: carModelOptions, carModelVariant: carModelVariantOptions });
                    }}
                >
                    Edit 编辑
                </Button>
            </Menu.Item>
            <Menu.Item>
                <Button type="link" onClick={() => deleteListing(item.node.slug)}>Delete 删除</Button>
            </Menu.Item>
        </Menu>
    );

    return (
        <Layout>
            <Modal 
                title='编辑广告' 
                visible={editModalVisible} 
                onCancel={handleCancel} 
                width="700px" className="create-modal" 
                footer={[
                    <Button key="back" onClick={handleCancel} style={{ borderRadius: '4px' }}>
                        Back 返回
                    </Button>,
                    <Button type="primary" onClick={onFinish} className="submit-button">
                        Edit 编辑
                    </Button>,
                ]}
            >
                <Form id="form" form={editForm} onValuesChange={onFormValuesChange}>
                    <Row span={24} justify="space-between">
                        {fields.map(field =>
                            <Col span={11}>
                                <Form.Item name={field.name} label={field.label} labelCol={{ span: 24 }}>
                                    {getInputType(field)}
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </Form>
            </Modal>

            <Layout style={{ margin: '16px 32px' }}>
                <Form className="filter-form" name="basic" onFinish={onFinishFilter}
                    form={filterForm}
                    autoComplete="off">
                    <Row align="top" justify='space-between'>
                        <Col span={8}>
                            <Row>
                                <Col span={6}>
                                    <p>Ads Package ID <br />广告配套编号</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="package_id">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row>
                                <Col span={8}>
                                    <p>Post ID <br />贴文编号</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="post_id">
                                        <Input/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8} style={{ textAlign: 'end' }}>
                            <Space direction="horizontal">
                                <Button onClick={() => downloadReportFunction()}>
                                    Export 汇出
                                </Button>
                                <Button onClick={() => onResetClicked()}>
                                    Reset 重设
                                </Button>
                                <Button className="search-button" htmlType="submit">
                                    Search 搜寻
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                    <Row align="top" >
                        <Col span={8}>
                            <Row>
                                <Col span={6}>
                                    <p>Title <br />标题</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="title">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row>
                                <Col span={8}>
                                    <p>Advertiser Name <br />广告商姓名</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="advertiser">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                    <Row align="top" >
                        <Col span={8}>
                            <Row>
                                <Col span={6}>
                                    <p>Agent Name <br/>代理姓名</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="agent">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>

                <div style={{ background: 'white', padding: '28px 32px' }}>

                    <Row direction='horizontal' style={{ justifyContent: 'space-between', marginBottom: "36px"}}>
                        <h1>Classified Ads 汽车广告</h1>
                        <Space size={12}>
                            <Radio.Group defaultValue="all" onChange={changeListingStatus}>
                                <Radio.Button value="all">All 全部</Radio.Button>
                                <Radio.Button value="scheduled">Pending 待定</Radio.Button>
                                <Radio.Button value="live">Published 已刊登</Radio.Button>
                                <Radio.Button value="expired">Expired 逾期</Radio.Button>
                            </Radio.Group>
                        </Space>
                    </Row>
                    {/* <Layout className='ads-list'> */}
                        {/* {adsList.length > 0 ? adsList.map((item) => (
                        <Row style={{ background: 'white', }} align="middle">
                            <Col span={7} >
                                <p className="title-row">{item.node.title}</p>
                                <Space size="small">
                                    <p className="title-row">
                                        <span >{item.node.description}</span>
                                    </p>
                                    <Button type="link" size="small">
                                        <CopyOutlined style={{ color: "#C62828" }} />
                                    </Button>
                                </Space>

                            </Col>
                            <Col span={3}>
                                <Space direction="vertical" className="ads-listing-space">
                                    <p>广告商</p>
                                    <span>{item.node.owner.name}</span>
                                </Space>
                            </Col>
                            <Col span={3}>
                                <Space direction="vertical" className="ads-listing-space">
                                    <p style={{ textTransform: "capitalize" }}>{item.status} 日期</p>
                                    {item.node.lastPublishedAt
                                        ? <Moment format="YYYY-MM-DD HH:mm">
                                            {getListingDate(item)}
                                        </Moment>
                                        : <span>-</span>}
                                </Space>
                            </Col>

                            <Col span={3}>
                                <Space direction="vertical" className="ads-listing-space">
                                    <p style={{ textTransform: "capitalize" }}>{item.status} last modified date</p>
                                    {item.node.latestRevisionCreatedAt
                                        ? <Moment format="YYYY-MM-DD HH:mm">
                                            {item.node.latestRevisionCreatedAt}
                                        </Moment>
                                        : <span>-</span>}
                                </Space>
                            </Col>

                            <Col span={2}>
                                <Space size={10}>
                                    <span style={{ width: "7px", height: "7px", backgroundColor: getStatusColor(item.node.status), display: "block", borderRadius: "7px" }}>
                                    </span>
                                    <p>{expiryStatus[item.node.status]}</p>
                                </Space>
                            </Col>
                            <Col span={4}>
                                <Row>
                                    <p className="ellipsis">
                                        {item.node.addOns && formatAddOns(item.node.addOns)}
                                    </p>
                                </Row>
                            </Col>
                            <Col className="action-column" span={2} style={{ textAlign: 'center', alignSelf: 'center' }}>
                                <Row align="middle">
                                    <Dropdown overlay={actionsMenu(item)}>
                                        <Button type="link" onClick={e => e.preventDefault()}>
                                            查看更多 <DownOutlined style={{ color: "red" }} />
                                        </Button>
                                    </Dropdown>
                                </Row>
                            </Col>
                            <Divider style={{ margin: '16px 0 16px 0' }} />
                        </Row>,
                    )) : <Empty />} */}

                        <Table dataSource={adsList} columns={column} scroll={{ x: 1800 }}
                            pagination={{
                                showSizeChanger: false,
                                current: currentPageNumber, total: totalCount, pageSize: 5, onChange: (page, pageSize) => {
                                    var off = (page - 1) * 5
                                    setOffSet(off);
                                    setCurrentPageNumber(page);
                                }
                            }}
                        />
                    {/* </Layout> */}
                </div>
            </Layout>
        </Layout>

    )
}

export default AdsListingPage;