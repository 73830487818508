import { useQuery, useMutation } from 'react-apollo';
import { Layout, Space, Breadcrumb, Modal, Input, Button, Row, Col, Table, Pagination, Select, message } from "antd";
import React, { useState, useEffect } from 'react';
import { allPayments, DownloadCommissionReport, DownloadInvoiceDocument } from '../../../server/query';
import { ROLES } from '../../../server/constant'
import { useHistory, useLocation } from "react-router-dom";
import client from '../../../server/apollo_config'
// import { Document, Page } from 'react-pdf';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
const CustomerTransactionPage = () => {
    const [customerTransaction, setCustomerTransaction] = useState();
    const [totalCount, setTotalCount] = useState('0');
    const [offSet, setOffSet] = useState(0);
    const [roles, setRoles] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [pdfFile, setPdfFile] = useState('');
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);

    const column = [
        {
            title: 'ID 编号',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name \n名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Type \n类型',
            dataIndex: 'has_company',
            key: 'has_company',
            render: text => <p>{text ? 'Company' : 'Individual'}</p>
        },
        {
            title: 'Payment \n支付',
            dataIndex: 'payment_amount',
            key: 'payment_amount'
        },
        {
            title: 'View \n看法',
            dataIndex: 'orderId',
            key: 'action',
            render: (text) => (text ? <Button type="text" className="table-action-button" onClick={() => { onCLickView(text) }}>View 看法</Button> : null)
        },
        {
            title: 'Download \n下载',
            dataIndex: 'orderId',
            key: 'action',
            render: (text) => (text ? <Button type="text" className="table-action-button" onClick={() => { onClickDownload(text) }}>Download 下载</Button> : null)
        }

    ]

    const { loading, error, data, refetch } = useQuery(
        allPayments, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "no-cache",
        variables: { offSet }
    })

    const history = useHistory()

    useEffect(() => {
        const roles = localStorage.getItem(ROLES)
        setRoles(roles)

        const isAllowedRole = ['super_admin', 'project_lead', 'finance', 'operation'].includes(roles)

        if (isAllowedRole === false) {
            history.push('/inbox')
            return
        }

        if (loading === false && data !== undefined) {
            console.log('data', data.allPayments.edges)

            rerenderListView(data.allPayments.edges)
            setTotalCount(data.allPayments.totalCount)
        }
    }, [loading, data, history])

    function rerenderListView(data) {
        var list = []
        for (var i = 0; i < data.length; i++) {
            list.push({
                'id': data[i].node?.listing?.postId ? data[i].node.listing.postId : data[i].node?.listingGhost?.split("|")?.length === 3 ? data[i].node?.listingGhost?.split("|")[1] : null,
                'name': data[i].node.advertiser?.user?.name,
                'has_company': data[i].node.advertiser?.hasCompany,
                'payment_amount': data[i].node.paymentAmount,
                'orderId': data[i].node.orderId,
            })
        }

        setCustomerTransaction(list)
    }

    function onClickDownload(orderId) {
        if (orderId == null) {
            message.error(`No order id found for this transaction. 未找到此交易的订单 ID `)
        } else {
            client.query({
                query: DownloadInvoiceDocument,
                fetchPolicy: 'network-only',
                variables: { orderId }

            }).then((res) => {
                let element = document.createElement('a');
                element.setAttribute('href', res.data.downloadInvoiceDocument.url);
                element.setAttribute('target', "_blank");
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            })
        }
    }

    function onCLickView(orderId) {
        if (orderId == null) {
            message.error(`No order id found for this transaction. 未找到此交易的订单 ID `)
        } else {
            client.query({
                query: DownloadInvoiceDocument,
                fetchPolicy: 'network-only',
                variables: { orderId }

            }).then((res) => {
                setIsModalVisible(true)
                setPdfFile(res.data.downloadInvoiceDocument.url)
            })
        }
    }

    function onItemClick({ pageNumber: itemPageNumber }) {
        setPageNumber(itemPageNumber);

    }

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (

        <Layout>
            <Modal title="" visible={isModalVisible} width="900px"

                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel} style={{ borderRadius: '4px' }}>
                        Back 返回
                    </Button>,

                ]}>

                <Document file={pdfFile} onItemClick={onItemClick}>
                    <Page pageNumber={pageNumber || 1} />
                </Document>

            </Modal>

            <Space direction='vertical' className="commission_header">
                <Breadcrumb style={{ marginBottom: '16px' }}>
                    <Breadcrumb.Item>Homepage 首页</Breadcrumb.Item>
                    <Breadcrumb.Item>Customer Transaction 客户交易</Breadcrumb.Item>
                </Breadcrumb>
                <h1>Customer Transaction 客户交易</h1>
            </Space>
            <Layout style={{ margin: '16px 32px' }}>
                <div style={{ background: 'white' }}>
                    <Table className="main-table" style={{ whiteSpace: "pre" }} columns={column} dataSource={customerTransaction} pagination={{
                            current: currentPageNumber,
                            total: totalCount,
                            showSizeChanger:false,
                            pageSize: 10,
                            onChange: (page, pageSize) => {
                                var off = (page - 1) * 10
                                setOffSet(off);
                                setCurrentPageNumber(page);  
                            },
                        }}  />
                </div>

            </Layout>
        </Layout>
    )
}

export default CustomerTransactionPage;