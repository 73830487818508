import { Layout, Space, Breadcrumb, Form, Input, Button, DatePicker, Row, Col, Table, Pagination, Select, ConfigProvider, message, Image } from "antd";
import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { AdvertiserSearch, AllAdvertisers, DownloadAdvertiserReport, DownloadAdvertiserVerificationDocument, ViewVerificationDocuments } from "../../../server/query";
import client from '../../../server/apollo_config'
import './advertiser.css'
import Moment from 'moment';
import PermissionCheck from "../../../components/permission_check";
import { ROLES } from '../../../server/constant'
import { useHistory, useLocation } from "react-router-dom";
import locale from "antd/lib/locale/zh_CN";
const { RangePicker } = DatePicker;


const AdvertiserListPage = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const [advertiserList, setAdvertiserList] = useState([])
    const [offSet, setOffSet] = useState(0);
    const [id, setID] = useState(null);
    const [name, setName] = useState(null);
    const [advertiserType, setAdvertiserType] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [totalList, setTotalList] = useState(0)
    const [currentPageNumber, setCurrentPageNumber] = useState(1);  
    const [roles, setRoles] = useState('')
    const [selfie, setSelfie] = useState('')
    const [icFront, setIcFront] = useState('')
    const [icBack, setIcBack] = useState('')
    const [visible, setVisible] = useState(false);
    const [searchTable, setSearchTable] = useState(false)

    const { loading, error, data, refetch } = useQuery(AllAdvertisers, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "no-cache",
        variables: { offSet }
    })

    function onClickView(id) {
        console.log('id', id)
        client.query({
            query: ViewVerificationDocuments,
            variables: { id: id },
            fetchPolicy: "network-only"
        }).then((res) => {
            console.log('res', res)
            if (res.data.advertiser?.icBack != null || res.data.advertiser?.icFront == !null || res.data.advertiser?.selfie == !null) {
                setSelfie(res.data.advertiser.selfie);
                setIcBack(res.data.advertiser.icBack);
                setIcFront(res.data.advertiser.icFront);
                setVisible(true)
                if (res.data.advertiser.icBack === null) {
                    message.error(`Back image of ic is not uploaded.`)
                }

            } else {
                message.error(`No Document has been uploaded. 
                没有文件上传`)
            }
        })

    }

    function onClickDownload(advertiserId) {
        if (advertiserId == null) {
            message.error(`No document found for this advertiser. 未找到此广告客户的文件`)
        } else {
            client.query({
                query: DownloadAdvertiserVerificationDocument,
                fetchPolicy: 'network-only',
                variables: { advertiserId }

            }).then((res) => {
                let element = document.createElement('a');
                element.setAttribute('href', res.data.downloadAdvertiserVerificationDocuments.url);
                element.setAttribute('target', "_blank");
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            })
        }
    }
    
    const columns = [
        {
            title: 'Advertiser ID \n广告商编号',
            dataIndex: 'regular_id',
            key: 'id',
        },
        {
            title: 'Type \n类型',
            dataIndex: 'type',
            key: 'type',
            render: text => (text === true ? <p>Company 公司</p> : <p>Individual 个人</p>)

        },
        {
            title: 'Advertiser Name \n广告商姓名',
            dataIndex: 'advertiser_name',
            key: 'advertiser_name',
        },
        {
            title: 'Company Name \n公司名称',
            dataIndex: 'company_name',
            key: 'company_name',
        },
        {
            title: 'Mobile Number \n手机号码',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: 'Email \n电子邮件',
            dataIndex: 'email',
            key: 'email',
            width: 300,
        },
        {
            title: 'Registration Date \n注册日期',
            dataIndex: 'date',
            key: 'date',
            render: text => Moment(text).format('DD/MM/YYYY')
        },
        {
            title: 'View \n看法',
            dataIndex: ['advertiser_id', 'advertiser'],
            key: 'action',
            render: (text, row) => row['hasDocument'] ? <Button type="text" className="table-action-button" onClick={() => { onClickView(row['id']) }}>View 看法</Button> : '',
            fixed: 'right',
            width: 150
        },
        {
            title: 'Download \n下载',
            dataIndex: 'advertiser_id',
            key: 'action',
            render: (text, row) => row['hasDocument'] ? <Button type="text" className="table-action-button" onClick={() => { onClickDownload(row['id']) }}>Download 下载</Button> : '',
            fixed: 'right',
            width: 150

        }
    ]
    var location = useLocation()

    const listOfViewOnlyRoles = ['operation', 'project_lead']

    useEffect(() => {
        const roles = localStorage.getItem(ROLES)
        setRoles(roles)

        const isAllowedRole = ['super_admin', 'project_lead', 'finance', 'operation'].includes(roles)

        if (isAllowedRole === false) {
            history.push('/inbox')
            return
        } 

        if (loading === false) {
            rerenderListView(data.allAdvertisers.edges)
            setTotalList(data.allAdvertisers.totalCount)
        }
    }, [data, history, loading])

    function rerenderListView(items) {
        var list = []
        for (var i = 0; i < items.length; i++) {
            list.push({
                'id': items[i].node.id,
                'regular_id': items[i].node.regularId,
                'type': items[i].node.hasCompany,
                'advertiser_name': items[i].node.legalName,
                'company_name': items[i].node.carDealer?.companyName,
                'mobile': items[i].node.user.phoneNumber,
                'email': items[i].node.user.email,
                'date': items[i].node.dateCreated,
                'hasDocument': items[i].node?.selfie || items[i].node?.icFront || items[i].node?.icBack,
            })
        }

        setAdvertiserList(list)
    }

    async function downloadReportFunction() {
        client.query({
            query: DownloadAdvertiserReport,
            fetchPolicy: "network-only",
            variables: { id, name, advertiserType, startDate, endDate }
        }).then((res) => {
            let element = document.createElement('a');
            element.setAttribute('href', res.data.downloadAdvertiserReport.url);
            element.setAttribute('target', "_blank");
            // element.setAttribute('download', 'output.xlsx');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        })
    }

    const onFinish = (values) => {
        console.log('Success:', values);
        setSearchTable(true)
        setCurrentPageNumber(1)
        setID(values.id)
        setName(values.name)
        setAdvertiserType(values.Select)
        if (values.date !== undefined && values.date !== null) {
            client.query({
                query: AdvertiserSearch,
                fetchPolicy: "network-only",
                variables: { id: values.id, name: values.name, advertiserType: values.Select, startDate: values.date[0], endDate: values.date[1] }
            }).then((res) => {
                console.log(res)
                rerenderListView(res.data.advertiserSearch.edges)
            })
            setStartDate(values.date[0])
            setEndDate(values.date[1])
        } else {

            client.query({
                query: AdvertiserSearch,
                fetchPolicy: "network-only",
                variables: { id: values.id, name: values.name, advertiserType: values.Select }
            }).then((res) => {
                console.log(res)
                rerenderListView(res.data.advertiserSearch.edges)
            })


        }
    };

    function onResetClicked() {
        form.resetFields();
        setSearchTable(false)
        setOffSet(0)
        setCurrentPageNumber(1)
        setID(null)
        setName(null)
        setAdvertiserType(null)
        setStartDate(null)
        setEndDate(null)
        rerenderListView(data.allAdvertisers.edges)
    }
    return (
        <Layout>
            <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis) }} >
                <Image
                    width={200}
                    style={{ display: 'none' }}
                    src={selfie}
                    preview={{
                        visible,
                        src: selfie,
                        onVisibleChange: value => {
                            setVisible(value);
                        },
                    }}
                />
                <Image
                    width={200}
                    style={{ display: 'none' }}
                    src={icFront}
                    preview={{
                        visible,
                        src: icFront,
                        onVisibleChange: value => {
                            setVisible(value);
                        },
                    }}
                />
                <Image
                    width={200}
                    style={{ display: 'none' }}
                    src={icBack}
                    preview={{
                        visible,
                        src: icBack,
                        onVisibleChange: value => {
                            setVisible(value);
                        },
                    }}
                />
            </Image.PreviewGroup>
            
            <Space direction='vertical' className="commission_header">
                <Breadcrumb style={{ marginBottom: '16px' }}>
                    <Breadcrumb.Item>Homepage 首页</Breadcrumb.Item>
                    <Breadcrumb.Item>Advertiser List 广告商清单</Breadcrumb.Item>
                </Breadcrumb>
                <h1>Advertiser List 广告商清单</h1>
            </Space>
            <Layout style={{ margin: '16px 32px' }}>

                <Form className="filter-form" name="basic"
                    onFinish={onFinish}
                    form={form}
                    autoComplete="off">
                    <Row align="top" justify='space-between'>
                        <Col span={8}>
                            <Row>
                                <Col span={6}>
                                    <p>Date <br />日期</p>
                                </Col>
                                <Col span={16}>
                                    <ConfigProvider locale={locale}>
                                        <Form.Item name="date">
                                            <RangePicker />
                                        </Form.Item>
                                    </ConfigProvider>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row>
                                <Col span={8}>
                                    <p>Advertiser ID <br />广告商编号</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="id">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8} style={{ textAlign: 'end' }}>
                            <Space direction="horizontal">
                                {
                                    listOfViewOnlyRoles.includes(roles) ? null : <Button onClick={() => downloadReportFunction()}>
                                        Export 汇出
                                    </Button>
                                }

                                <Button onClick={() => onResetClicked()}>
                                    Reset 重设
                                </Button>
                                <Button className="search-button" htmlType="submit">
                                    Search 搜寻
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                    <Row align="top" >
                        <Col span={8}>
                            <Row>
                                <Col span={6}>
                                    <p>Type <br />类型</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="Select">
                                        <Select>
                                            <Select.Option value="individual">Individual 个人</Select.Option>
                                            <Select.Option value="company">Company 公司</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row>
                                <Col span={8}>
                                    <p>Advertiser Name <br />广告商姓名</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="name">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>

                {searchTable === false ? <div style={{ background: 'white' }}>
                    <Table className="pagination-table" style={{ whiteSpace: "pre" }} scroll={{ x: 1500 }} columns={columns} dataSource={advertiserList} pagination={false} />
                    <div style={{ textAlign: 'end', marginBottom: '64px', marginRight: '24px' }}>
                        <Pagination
                            current={currentPageNumber}
                            showSizeChanger={false}
                            onChange={(page, pageSize) => {
                                setOffSet((page - 1) * 5);
                                setCurrentPageNumber(page)
                            }} total={totalList} defaultPageSize={5} />
                    </div>
                </div> : <Table className="main-table" style={{ whiteSpace: "pre" }} columns={columns} defaultPageSize={5} dataSource={advertiserList} />}
            </Layout>
        </Layout>
    )
}

export default AdvertiserListPage;