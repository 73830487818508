import {
    Layout,
    Space,
    Input,
    Button,
    Row,
    Col,
    Table,
    Modal,
    Select,
    message,
    Divider,
    Form,
    ConfigProvider
} from "antd";
import React, { useState, useEffect } from "react";
import { Link, useRouteMatch, useLocation, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo";
import "./agent-lead.css";
import {
    CreateAgentLead,
    UpdateAgentLead,
    RetiredAgentLead,
} from "../../../server/mutation";
import {
    AllAgentLead,
    AgentSearch,
    DownloadAgentReport,
    DownloadLeadAgentReport,
} from "../../../server/query";
import { ROLES } from "../../../server/constant";
import PermissionCheck from "../../../components/permission_check";
import client from "../../../server/apollo_config";
import locale from 'antd/lib/locale/zh_CN';
import { DatePicker } from "antd";
import Moment from 'moment';
const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";

const AgentLeadPage = () => {
    const { Option } = Select;

    const [form] = Form.useForm();
    const [offSet, setOffSet] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [totalAgentLeads, setTotalAgentLeads] = useState(0);
    const [agentLeadList, setAgentLeadList] = useState([]);
    const [dropdownAgentList, setDropdownAgentList] = useState([]);

    const [agentID, setAgentID] = useState("");
    const [agentName, setAgentName] = useState("");
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [removeModalVisible, setRemoveModalVisible] = useState(false);
    const [statusModal, setStatusModal] = useState("create");
    const [ID, setID] = useState("");
    const [TransferId, setTransferId] = useState("");
    const [superAdmin, setSuperAdmin] = useState(true);

    const [projectLead, setProjectLead] = useState(false);
    const [variables, setVariables] = useState({
        offSet,
        agentId: agentID,
        agentName: agentName,
        agentType: "agent_lead",
    })

    const [CreateAgent] = useMutation(CreateAgentLead, {
        onCompleted(data) {
            if (data.createAgentLead.ok == null) {
                message.error(data.createAgentLead.error);
            } else {
                mutationSuccess("Account created.");
                setCreateModalVisible(false);
                refetch();
            }
        },
    });
    const [UpdateAgent] = useMutation(UpdateAgentLead, {
        onCompleted(data) {
            if (data.updateAgentLead.ok == null) {
                message.error(data.updateAgentLead.error);
            } else {
                mutationSuccess("Account updated.");
                setCreateModalVisible(false);
                refetch();
            }
        },
    });
    const [RetiredAgent] = useMutation(RetiredAgentLead, {

        onCompleted(data) {
            console.log(data)
            if (data.retireAgentLead.ok == null) {
                message.error(data.retireAgentLead.error);
            } else {
                mutationSuccess("已删除代理主管");
                setRemoveModalVisible(false);
                refetch();
            }
        },
    });

    const { loading, error, data, refetch } = useQuery(AgentSearch,
        {
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "network-only",
            variables: variables
        });

    const history = useHistory();
    useEffect(() => {
        const roles = localStorage.getItem(ROLES)
        if (roles.includes('super_admin') || roles.includes('project_lead') || roles.includes('operation') || roles.includes('finance')) {
            if (roles.includes("project_lead")) {
                setSuperAdmin(false)
            }
        } else {
            history.push('/inbox')
        }
        if (loading === false && data !== undefined) {
            rerenderListView(data.agentSearch.edges);
            setTotalAgentLeads(data.agentSearch.totalCount);
        }
    }, [data, history, loading]);
    let { url } = useRouteMatch();

    const onchangeDate = (date, dateString) => {

        var expired_date = Moment.parseZone(dateString[1]).utc().format()
        var start_date = Moment.parseZone(dateString[0]).utc().format()

        if (date != null) {
            setVariables({
                offSet,
                agentId: agentID,
                agentName: agentName,
                agentType: "agent_lead",
                startDate: start_date,
                endDate: expired_date
            })
        } else if (date == null) {
            setVariables({
                offSet,
                agentId: agentID,
                agentName: agentName,
                agentType: "agent_lead",
            })
        }
    }


    const columns = [
        {
            title: "ID \n编号",
            dataIndex: "code",
            key: "code",
            width: "60px"
        },
        {
            title: "Name \n姓名",
            dataIndex: ["name", "id"],
            key: "name",
            render: (text, row) => (
                <Link
                    type="text"
                    style={{ color: "#1890FF" }}
                    to={`${url}/agent-lead-detail/${row["id"]}`}
                >
                    {row["name"]}
                </Link>
            ),
        },
        {
            title: "Mobile Number \n手机号码",
            dataIndex: 'phoneNumber',
        },
        {
            title: "Date \n日期",
            dataIndex: 'date',
            render: (text, row) => row['date'] !== '' ? (Moment(row['date']).format('DD/MM/YYYY')) : '',
        },
        {
            title: "Agent \n代理",
            dataIndex: "car_agent",
            key: "car_agent",

            sorter: (a, b) => a.car_agent - b.car_agent,
        },
        {
            title: "Advertiser \n广告商",
            dataIndex: "dealer_amount",
            key: "dealer_amount",
            render: (text) => <p>{text}</p>,

            sorter: (a, b) => a.car_dealer_amount - b.car_dealer_amount,
        },
        {
            title: "Sales \n业绩",
            dataIndex: "sales",
            key: "sales",
            render: (text) => <p>RM{text ?? 0}</p>,
            sorter: (a, b) => a.sales - b.sales,
            filterDropdown: (
                <div className="custom-filter-dropdown">
                    <div>
                        <RangePicker
                            onChange={onchangeDate}
                            format={dateFormat}
                        />
                    </div>
                </div>
            ),
        },
        {
            title: "",
            dataIndex: ["name", "phoneNumber", "id", "email"],
            key: "action",
            render: (text, row) => (
                <Space
                    className="Table-action"
                    size={2}
                    split={<Divider type="vertical" style={{ borderColor: "#D8D8D8" }} />}
                >
                    <Button
                        type="text"
                        className="table-action-button"
                        onClick={() =>
                            openModal(
                                "edit",
                                row["id"],
                                row["name"],
                                row["phoneNumber"],
                                row["email"]
                            )
                        }
                    >
                        Edit 编辑
                    </Button>
                    <Button
                        type="text"
                        className="table-action-button"
                        onClick={() => openModal("remove", row["id"])}
                    >
                        Remove 删除
                    </Button>
                </Space>
            ),
        },
    ];
    const basicColumns = [
        {
            title: "ID \n编号",
            dataIndex: "code",
            key: "code",
        },
        {
            title: "Name \n姓名",
            dataIndex: ["name", "id"],
            key: "name",
            render: (text, row) => (
                <Link
                    type="text"
                    style={{ color: "#1890FF" }}
                    to={`${url}/agent-lead-detail/${row["id"]}`}
                >
                    {row["name"]}
                </Link>
            ),
        },
        {
            title: "Mobile Number \n手机号码",
            dataIndex: 'phoneNumber',
        },
        {
            title: "Agent \n代理",
            dataIndex: "car_agent",
            key: "car_agent",

            sorter: (a, b) => a.car_agent - b.car_agent,
        },
        {
            title: "Advertiser \n广告商",
            dataIndex: "dealer_amount",
            key: "dealer_amount",
            render: (text) => <p>{text}</p>,

            sorter: (a, b) => a.car_dealer_amount - b.car_dealer_amount,
        },
        {
            title: "Sales \n业绩",
            dataIndex: "sales",
            key: "sales",
            render: (text) => <p>RM{text ?? 0}</p>,
            sorter: (a, b) => a.sales - b.sales,
            filterDropdown: (
                <div className="custom-filter-dropdown">
                    <div>
                        <RangePicker
                            onChange={onchangeDate}
                            format={dateFormat}
                        />
                    </div>
                </div>
            ),
        },
    ];

    function rerenderListView(data) {
        var list = [];

        for (var i = 0; i < data.length; i++) {
            // agent_lead
            list.push({
                id: data[i].node.id,
                code: data[i].node?.secondaryId,
                name: data[i].node.user?.name,
                car_agent: data[i].node.allChildren.totalCount,
                dealer_amount: data[i].node.allAdvertisers.totalCount,
                sales: data[i].node.sales,
                email: data[i].node.user?.email,
                phoneNumber: data[i].node.user?.phoneNumber,
                date: data[i].node?.user?.dateJoined,
            });
        }

        setAgentLeadList(list);
        setDropdownAgentList(list)
    }



    const onFinish = (values) => {
        if (statusModal === "create") {
            CreateAgent({
                variables: {
                    name: values.name,
                    phoneNumber: values.mobile,
                    email: values.email,
                    secondaryId: values.agentLeadId,
                    password: values.password,
                },
            });
        }
        if (statusModal === "edit") {
            UpdateAgent({ variables: { id: ID, name: values.name, phoneNumber: values.mobile } });
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    function mutationSuccess(text) {
        message.success(text);
        resetForm();
    }

    function resetForm() {
        form.resetFields()
        setStatusModal("create");
        setTransferId("");
    }

    function onSearchClicked() {
        setCurrentPageNumber(1);
        setOffSet(0);
        setVariables({
            offSet,
            agentId: agentID,
            agentName: agentName,
            agentType: "agent_lead",
        })
    }

    function onResetClicked() {
        setAgentName("");
        setAgentID("");
        setCurrentPageNumber(1);
        setOffSet(0);
        setVariables({
            offSet,
            agentId: '',
            agentName: '',
            agentType: "agent_lead",
        })
    }

    const onChangeInput = (e, type) => {
        if (type === "name") {
            setAgentName(e.target.value);
        }
        if (type === "id") {
            setAgentID(e.target.value);
        }
    };

    const handleCancel = () => {
        setCreateModalVisible(false);
        setStatusModal('create')
        form.resetFields()
    }

    function openModal(type, id, name, mobile, email) {
        if (type === "edit") {
            setCreateModalVisible(true);
            setStatusModal("edit");
            setID(id);
            form.setFieldsValue({
                name: name,
                mobile: parseInt(mobile),
                email: email
            })
        }

        if (type === "remove") {

            const result = agentLeadList.filter(agent => agent.id !== id)
            setDropdownAgentList(result)

            setRemoveModalVisible(true);
            setID(id);
        }
    }

    const handleRemoveOK = () => {
        RetiredAgent({ variables: { id: ID, transfer: TransferId } });
    };
    const handleRemoveCancel = () => {
        setRemoveModalVisible(false);
        setDropdownAgentList(agentLeadList)
        setTransferId("")
    };

    function onChangeSelection(value) {
        setTransferId(value);
    }

    async function downloadReportFunction() {
        client
            .query({
                query: DownloadLeadAgentReport,
                fetchPolicy: "network-only",
            })
            .then((res) => {
                let element = document.createElement("a");
                element.setAttribute("href", res.data.downloadAgentLeadReport.url);
                element.setAttribute("target", "_blank");
                // element.setAttribute('download', 'output.xlsx');
                element.style.display = "none";
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            });
    }

    return (
        <Layout className="lead-agent-main">
            <Modal
                title={statusModal === "create" ? "Create Agent Lead 新增代理主管" : "Edit Agent Lead 编辑代理主管"}
                visible={createModalVisible}

                onCancel={handleCancel}
                className="create-modal"
                footer={null}
            >
                <Form
                    name="basic"
                    form={form}
                    layout='vertical'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Name 姓名"
                        name="name"
                        rules={[{ required: true, message: '*必填' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Mobile Number 手机号码"
                        name="mobile"
                        rules={[
                            {
                                required: true,
                                message: '*必填'
                            },
                        ]}
                    >
                        <Input type="number" />
                    </Form.Item>
                    {
                        statusModal === 'create' ? <Form.Item
                            label="Email 电子邮件"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    type: 'email',
                                    message: '*必填'
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                            :
                            <Form.Item
                                label="Email 电子邮件"
                                name="email"

                            >
                                <Input disabled style={{
                                    color: "black",
                                    background: "white",
                                    cursor: "auto",
                                }} />
                            </Form.Item>
                    }
                    {statusModal === 'create' ? <Form.Item
                        label="Agent Lead ID 代理主管编号"
                        name="agentLeadId"
                        rules={[
                            {
                                required: true,
                                message: '*必填, 仅限数字和字符',
                                pattern: new RegExp("^[a-zA-Z0-9]*$"),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item> : null}
                    {statusModal === 'create' ? <Form.Item

                        label="Password 密码"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: '*必填'
                            },
                        ]}

                    >
                        <Input />
                    </Form.Item> : null}

                    <div style={{ textAlign: 'end' }}>
                        <Space direction="horizontal">
                            <Form.Item>
                                <Button
                                    key="back"
                                    onClick={handleCancel}
                                    style={{ borderRadius: "4px" }}
                                >
                                    Back 返回
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="submit-button">
                                    {statusModal === 'create' ? "Add New 新增" : 'Save Changes 储存'}
                                </Button>
                            </Form.Item>
                        </Space>
                    </div>

                </Form>
            </Modal>

            <Modal
                title="Remove Agent Lead 删除代理主管"
                visible={removeModalVisible}
                onOk={handleRemoveOK}
                onCancel={handleRemoveCancel}
                className="create-modal"
                footer={[
                    <Button
                        key="back"
                        onClick={handleRemoveCancel}
                        style={{ borderRadius: "4px" }}
                    >
                        Cancel 取消
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={handleRemoveOK}
                        className="submit-button"
                    >
                        Confirm 确认
                    </Button>,
                ]}
            >
                <Layout>
                    <Space direction="vertical" style={{ background: "white" }}>
                        <p>Please select an agent lead to transfer 请选择欲转换的代理主管</p>
                        <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Select an Agent Lead 选择代理主管"
                            optionFilterProp="children"
                            onChange={onChangeSelection}
                            value={TransferId}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {dropdownAgentList.map((agent) => (

                                <Option value={agent.id} key={agent.id}>
                                    {agent.name}
                                </Option>


                            ))}
                        </Select>
                    </Space>
                </Layout>
            </Modal>
            <Row className="agent-lead-header" gutter={[16]}>
                <Col span={8}>
                    <Row>
                        <Col span={5} style={{ alignSelf: "self-end" }}>
                            <h1>Agent Lead ID <br />代理主管编号:</h1>
                        </Col>
                        <Col span={16}>
                            <Input
                                placeholder="Please Enter 请输入"
                                type="number"
                                value={agentID}
                                onChange={(e) => onChangeInput(e, "id")}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={8}>
                    <Row>
                        <Col span={4} style={{ alignSelf: "self-end" }}>
                            <h1>Name <br />姓名:</h1>
                        </Col>
                        <Col span={16}>
                            <Input
                                placeholder="Please Enter 请输入"
                                value={agentName}
                                onChange={(e) => onChangeInput(e, "name")}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={8} align="end">
                    <Space direction="horizontal">
                        <Button style={{ borderRadius: "4px" }} onClick={onResetClicked}>
                            Reset 重设
                        </Button>
                        <Button
                            style={{
                                background: "#C62828",
                                color: "white",
                                borderRadius: "4px",
                                borderColor: "#C62828",
                            }}
                            onClick={onSearchClicked}
                        >
                            Search 搜寻
                        </Button>
                    </Space>
                </Col>
            </Row>

            <Space direction="vertical" className="agent-lead-table">
                <Row justify="space-between" style={{ paddingBottom: "22px" }}>
                    <h2>
                        Agent Lead 代理主管&emsp;<span>{totalAgentLeads} agent lead 代理主管</span>
                    </h2>
                    {superAdmin ? (
                        <Space direction="horizontal">
                            <Button
                                style={{ borderRadius: "4px" }}
                                onClick={() => downloadReportFunction()}
                            >
                                Download 下载
                            </Button>
                            <Button
                                className="add-new-button"
                                onClick={() => setCreateModalVisible(true)}
                            >
                                Add New 新增
                            </Button>
                        </Space>
                    ) : null}
                </Row>
                <ConfigProvider locale={locale}>
                    <Table
                        tool
                        style={{ whiteSpace: "pre" }}
                        dataSource={agentLeadList}
                        sortDirections={'descend'}
                        columns={superAdmin ? columns : basicColumns}
                        rowKey={(obj) => obj.id}
                        pagination={{
                            current: currentPageNumber,
                            total: totalAgentLeads,
                            showSizeChanger:false,
                            pageSize: 5,
                            onChange: (page, pageSize) => {
                                var off = (page - 1) * 5
                                setOffSet(off);
                                setCurrentPageNumber(page);
                                setVariables({
                                    offSet: off, agentId: agentID,
                                    agentName: agentName,
                                    agentType: "agent_lead",
                                })
                            },
                        }}
                    />
                </ConfigProvider>
            </Space>
        </Layout>
    );
};

export default AgentLeadPage;
