
import { Layout, Menu, Image, Row, Space, Avatar } from 'antd';
import { withRouter } from 'react-router';
import React, { useState, useEffect } from 'react';
import { Switch, HashRouter, Route, Link, useRouteMatch, useLocation, Redirect } from "react-router-dom";
import {
    SearchOutlined,
    BellOutlined,
    UserOutlined,
    LogoutOutlined

} from '@ant-design/icons';
import DashboardPage from '../pages/DashboardPage';
import HomePage from '../pages/Homepage';
import AgentLeadPage from '../pages/car-ads/Agent-Lead'
import AgentPage from '../pages/car-ads/agent'
import CarDealerPage from '../pages/car-ads/car-dealer'
import AdsListingPage from '../pages/operation/ads-listing'
import AdsSettingPage from '../pages/operation/ads-setting'
import TeamSettingPage from '../pages/operation/team setting'
import AdvertiserListPage from '../pages/finance/advertiser-list'
import InvoiceListingPage from '../pages/finance/invoice-listing'
import CommissionReportPage from '../pages/finance/commission-report'
import CommissionSettingPage from '../pages/finance/commission-setting';
import DeferredRevenueReport from '../pages/finance/deferred-revenue-report'
import FinanceSettingPage from '../pages/finance/finance-setting'
import RevenueReportPage from '../pages/finance/revenue-report'
import SalesReportPage from '../pages/finance/sales-report';
import InboxPage from '../pages/inbox'
import SettingPage from '../pages/setting/super-admin-setting'
import './sidebar.css'
import LoginPage from '../pages/login';
import AgentLeadDetailView from '../pages/car-ads/Agent-Lead/detail-view';
import AgentDetailView from '../pages/car-ads/agent/detail-view';
import CarDealerDetailView from '../pages/car-ads/car-dealer/detail-view';
import AddPromoCode from '../pages/operation/ads-setting/add-promo-code';
import { useQuery } from 'react-apollo';
import { userProfile } from '../server/query';
import { ROLES } from '../server/constant';
import BasicSettingPage from '../pages/setting/basic-setting';
import FacebookPostPage from '../pages/operation/facebook-post';
import FacebookPinPage from '../pages/operation/facebook-pin';
import AuditLogPage from '../pages/finance/audit-log';
import UserListPage from '../pages/finance/user-list';
import CustomerTransactionPage from '../pages/finance/customer-transaction';
// import ChangePasswordPage from '../pages/change-password';
import UserData from '../pages/operation/advertiser-document';
import AdvertiserDocument from '../pages/operation/advertiser-document';
import ChangePasswordPage from '../pages/change-password';
import WagTailUser from '../pages/finance/wagtail-user';
import ForgetPasswordPage from '../pages/forget-password';

const { SubMenu } = Menu;
const { Header, Content, Sider, Footer } = Layout;



const SideBar = ({ location }) => {
    const { loading, error, data } = useQuery(userProfile)
    let { path, url } = useRouteMatch();
    const [roles, setRoles] = useState([])
    const [name, setName] = useState('')
    const { pathname } = useLocation();
    const loc = useLocation()

    function logOutAccount() {
        localStorage.clear()
        window.location.reload(true);
    }

    useEffect(() => {
        if (loading === false) {
            if (data === undefined) {
                logOutAccount()
            } else {
                console.log('data', data)
                setRoles(data.myUserProfile.roles)
                setName(data.myUserProfile.name)
                localStorage.setItem(ROLES, data.myUserProfile.roles)
            }
        }
    }, [data, loading]);

    function Permission() {

        let href = window.location.href.split('/')

        if (roles.includes('super_admin') === true || roles.includes('project_lead') === true) {
            return (
                <Menu
                    mode="inline"
                    defaultSelectedKeys={'/'}
                    selectedKeys={'/' + href[4]}
                    style={{ height: '100%', borderRight: 0 }}>
                    <div style={{ margin: '15px 24px' }}>
                        <Image
                            width={70}
                            src='/chinapress_logo.svg'
                            preview={false}
                        />
                    </div>
                    <Menu.Item key="/"><Link to="/">Homepage 首页</Link></Menu.Item>
                    <Menu.Item key="/inbox"><Link to="/inbox">Inbox 收件箱</Link></Menu.Item>
                    <SubMenu key="Ads" title="Car Ads 广告">
                        <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/agent-lead"><Link to="/agent-lead">Agent Lead <br />代理主管</Link></Menu.Item>
                        <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/agent"><Link to="/agent">Agent <br />代理</Link></Menu.Item>
                        <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/car-dealer"><Link to="/car-dealer">Advertiser <br />广告商</Link></Menu.Item>
                    </SubMenu>
                    <SubMenu key="Operation" title="Operation 系统管理">
                        <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/ads-listing"><Link to="/ads-listing">Classified Ads <br />汽车广告</Link></Menu.Item>
                        <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/facebook-pin"><Link to="/facebook-pin">Facebook Pin Post <br />粉丝专页置顶贴文</Link></Menu.Item>
                        <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/facebook-post"><Link to="/facebook-post">Facebook Post <br />粉丝专页贴文</Link></Menu.Item>
                        <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/ads-setting"><Link to="/ads-setting">Classified Ads Setting <br />汽车广告设定</Link></Menu.Item>
                        <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/team-setting"><Link to="/team-setting">Operation Team Setting <br />系统管理员设定</Link></Menu.Item>

                    </SubMenu>
                    <SubMenu key="Finance" title="Finance 财务">
                        <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/invoice-listing"><Link to="/invoice-listing">Invoice Listing <br /></Link></Menu.Item>
                        <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/commission-report"><Link to="/commission-report">Commission Report <br />佣金报告</Link></Menu.Item>
                        <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/commission-setting"><Link to="/commission-setting">Commission Setting <br />佣金设置</Link></Menu.Item>
                        <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/revenue-report"><Link to="/revenue-report">Revenue Report <br />营收报告 </Link></Menu.Item>
                        <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/deferred-revenue"><Link to="/deferred-revenue">Deferred Revenue Report <br />递延收入报告 </Link></Menu.Item>
                        <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/sales-report"><Link to="/sales-report">Sales Report <br />业绩报告</Link></Menu.Item>
                        <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/advertiser-list"><Link to="/advertiser-list">Advertiser List <br />广告商清单</Link></Menu.Item>
                        {/* <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/advertiser-document"><Link to="/advertiser-document">Advertiser Verify Document <br />广告商认证文件</Link></Menu.Item> */}
                        <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/audit-log"><Link to="/audit-log">Audit Log <br />审计记录</Link></Menu.Item>
                        {/* <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/customer-transaction"><Link to="/customer-transaction">Customer Transaction <br />客户交易</Link></Menu.Item> */}
                        {
                            roles.includes('super_admin') ? 
                            <>
                            <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/user-list"><Link to="/user-list">User List <br />代理列表</Link></Menu.Item>
                            <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/wagtail-user-list"><Link to="/wagtail-user-list">Wagtail User List <br />代理列表</Link></Menu.Item>
                            </>
                            
                             : null
                        }
                        <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/finance-setting"><Link to="/finance-setting">Finance Setting <br />财务管理员设定</Link></Menu.Item>

                    </SubMenu>
                    {
                        roles.includes('super_admin') ? <Menu.Item key="/setting"><Link to="/setting">Setting 设定</Link></Menu.Item> :
                            <SubMenu key="Setting" title="Setting 设定">
                                <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/basic-setting"><Link to="/basic-setting">Basic Setting <br />基本设定</Link></Menu.Item>
                                <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/change-password"><Link to="/change-password">Change Password <br />更改密码</Link></Menu.Item>
                            </SubMenu>
                    }


                </Menu>
            )
        }

        else if (roles.includes('advertiser') || roles.includes('car_dealer')) {
            return (<Menu
                mode="inline"
                defaultSelectedKeys={'/inbox'}
                selectedKeys={'/' + href[4]}
                style={{ height: '100%', borderRight: 0 }}>
                <div style={{ margin: '15px 24px' }}>
                    <Image
                        width={70}
                        src='/chinapress_logo.svg'
                        preview={false}
                    />
                </div>
                <Menu.Item key="/inbox"><Link to="/inbox">Inbox 收件箱</Link></Menu.Item>
                <SubMenu key="Ads" title="Car Ads 广告">
                    <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/agent"><Link to="/agent">Agent <br />代理</Link></Menu.Item>
                </SubMenu>
                <SubMenu key="Finance" title="Finance 财务">
                    <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/audit-log"><Link to="/audit-log">Audit Log <br />审计记录</Link></Menu.Item>
                </SubMenu>
                <SubMenu key="Setting" title="Setting 设定">
                    <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/basic-setting"><Link to="/basic-setting">Basic Setting <br />基本设定</Link></Menu.Item>
                    <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/change-password"><Link to="/change-password">Change Password <br />更改密码</Link></Menu.Item>
                </SubMenu>
            </Menu>)

        }
        else {
            return (
                <Menu
                    mode="inline"
                    defaultSelectedKeys={'/inbox'}
                    selectedKeys={'/' + href[4]}
                    style={{ height: '100%', borderRight: 0 }}>
                    <div style={{ margin: '15px 24px' }}>
                        <Image
                            width={70}
                            src='/chinapress_logo.svg'
                            preview={false}
                        />
                    </div>
                    <Menu.Item key="/inbox"><Link to="/inbox">Inbox 收件箱</Link></Menu.Item>
                    <SubMenu key="Ads" title="Car Ads 广告">
                        {roles.includes('agent') || roles.includes('agent_lead') ? null : <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/agent-lead"><Link to="/agent-lead">Agent Lead <br />代理主管</Link></Menu.Item>}
                        {roles.includes('agent') ? null : <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/agent"><Link to="/agent">Agent <br />代理</Link></Menu.Item>}
                        <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/car-dealer"><Link to="/car-dealer">Advertiser <br />广告商</Link></Menu.Item>
                    </SubMenu>
                    {
                        roles.includes('operation') ? <SubMenu key="Operation" title="Operation 系统管理">
                            <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/ads-listing"><Link to="/ads-listing">Classified Ads <br />汽车广告</Link></Menu.Item>
                            <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/facebook-pin"><Link to="/facebook-pin">Facebook Pin Post <br />粉丝专页置顶贴文</Link></Menu.Item>
                            <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/facebook-post"><Link to="/facebook-post">Facebook Post <br />粉丝专页贴文</Link></Menu.Item>
                            <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/ads-setting"><Link to="/ads-setting">Classified Ads Setting <br />汽车广告设定</Link></Menu.Item>
                            <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/team-setting"><Link to="/team-setting">Operation Team Setting <br />系统管理员设定</Link></Menu.Item>
                        </SubMenu> : null
                    }
                    {
                        roles.includes('finance') || roles.includes('operation') ? <SubMenu key="Finance" title="Finance 财务">
                            <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/commission-report"><Link to="/commission-report">Commission Report <br />佣金报告</Link></Menu.Item>
                            <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/commission-setting"><Link to="/commission-setting">Commission Setting <br />佣金设置</Link></Menu.Item>
                            <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/revenue-report"><Link to="/revenue-report">Revenue Report <br />营收报告 </Link></Menu.Item>
                            <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/deferred-revenue"><Link to="/deferred-revenue">Deferred Revenue Report <br />递延收入报告 </Link></Menu.Item>
                            <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/sales-report"><Link to="/sales-report">Sales Report <br />业绩报告</Link></Menu.Item>
                            <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/advertiser-list"><Link to="/advertiser-list">Advertiser List <br />广告商清单</Link></Menu.Item>
                            <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/audit-log"><Link to="/audit-log">Audit Log <br />审计记录</Link></Menu.Item>
                            {/* <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/customer-transaction"><Link to="/customer-transaction">Customer Transaction <br />客户交易</Link></Menu.Item> */}
                            <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/finance-setting"><Link to="/finance-setting">Finance Setting <br />财务管理员设定</Link></Menu.Item>

                        </SubMenu> : <SubMenu key="Finance" title="Finance 财务">
                            {/* <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/advertiser-document"><Link to="/advertiser-document">Advertiser Verify Document <br />广告商认证文件</Link></Menu.Item> */}
                            <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/audit-log"><Link to="/audit-log">Audit Log <br />审计记录</Link></Menu.Item>
                        </SubMenu>
                    }
                    <SubMenu key="Setting" title="Setting 设定">
                        <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/basic-setting"><Link to="/basic-setting">Basic Setting <br />基本设定</Link></Menu.Item>
                        <Menu.Item style={{ minHeight: "45px", lineHeight: "20px" }} key="/change-password"><Link to="/change-password">Change Password <br />更改密码</Link></Menu.Item>
                    </SubMenu>
                </Menu>
            )

        }
    }

    return (
        <HashRouter>
            <Layout>
                <Layout>
                    <Sider width={200} className="site-layout-background" style={{ height: '100vh' }}>
                        {Permission()}
                    </Sider>
                    <Content style={{
                        height: '100vh',
                        overflow: 'scroll'
                    }}>

                        <Row style={{ justifyContent: 'end', padding: '17px 24px', background: 'white', margin: '2px', boxShadow: '0px 1px 4px rgba(0, 21, 41, 0.12)' }}>
                            <Space direction='horizontal' size="middle">
                                <LogoutOutlined onClick={logOutAccount} style={{ color: 'rgba(0, 0, 0, 0.65)' }} />
                                <Space direction='horizontal'>
                                    <Avatar size="small" icon={<UserOutlined />} />
                                    <p style={{ margin: '0px', color: 'rgba(0, 0, 0, 0.65)' }}>{name}</p>
                                </Space>
                            </Space>

                        </Row>
                        <Layout>
                            <Switch>
                                {/* <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} /> */}
                                <Route key="/dashboard" exact path="/dashboard" component={DashboardPage} />
                                <Route key="/" exact path="/" component={HomePage} />
                                <Route key="/agent-lead" exact path="/agent-lead" component={AgentLeadPage} />
                                <Route key="/agent" exact path="/agent" component={AgentPage} />
                                <Route key="/car-dealer" exact path="/car-dealer" component={CarDealerPage} />
                                <Route key="/ads-listing" exact path="/ads-listing" component={AdsListingPage} />
                                <Route key="/ads-setting" exact path="/ads-setting" component={AdsSettingPage} />
                                <Route key="/ads-setting/add-promo-code" exact path="/ads-setting/add-promo-code/:id?" component={AddPromoCode} />
                                <Route key="/facebook-pin" exact path="/facebook-pin" component={FacebookPinPage} />
                                <Route key="/facebook-post" exact path="/facebook-post" component={FacebookPostPage} />
                                <Route key="/team-setting" exact path="/team-setting" component={TeamSettingPage} />

                                <Route key="/invoice-listing" exact path="/invoice-listing" component={InvoiceListingPage} />
                                <Route key="/commission-report" exact path="/commission-report" component={CommissionReportPage} />
                                <Route key="/commission-setting" exact path="/commission-setting" component={CommissionSettingPage} />
                                <Route key="/deferred-revenue" exact path="/deferred-revenue" component={DeferredRevenueReport} />
                                <Route key="/finance-setting" exact path="/finance-setting" component={FinanceSettingPage} />
                                <Route key="/advertiser-list" exact path="/advertiser-list" component={AdvertiserListPage} />
                                <Route key="/revenue-report" exact path="/revenue-report" component={RevenueReportPage} />
                                <Route key="/sales-report" exact path="/sales-report" component={SalesReportPage} />
                                <Route key="/audit-log" exact path="/audit-log" component={AuditLogPage} />
                                <Route key="/user-list" exact path="/user-list" component={UserListPage} />
                                <Route key="/wagtail-user-list" exact path="/wagtail-user-list" component={WagTailUser} />
                                <Route key="/advertiser-document" exact path="/advertiser-document" component={AdvertiserDocument} />
                                <Route key="/customer-transaction" exact path="/customer-transaction" component={CustomerTransactionPage} />


                                <Route key="/inbox" exact path="/inbox" component={InboxPage} />
                                <Route key="/setting" exact path="/setting" component={SettingPage} />
                                <Route key="/login" exact path="/login" component={LoginPage} />
                                <Route key="/forget-password" exact path="/forget-password" component={ForgetPasswordPage} />
                                <Route key="agent-lead-detail" path={[`/agent-lead/agent-lead-detail/:id`, `/agent/agent-lead-detail/:id`, `/car-dealer-detail/agent-lead-detail/:id`]} component={AgentLeadDetailView} />
                                <Route key="/agent/agent-detail" exact path={["/agent/agent-detail/:id", '/agent-lead/agent-detail/:id', '/car-dealer-detail/agent-detail/:id']} component={AgentDetailView} />
                                <Route key="/car-dealer/car-dealer-detail" exact path={["/car-dealer/car-dealer-detail/:id", "/agent/car-dealer-detail/:id"]} component={CarDealerDetailView} />
                                <Route key="/basic-setting" exact path='/basic-setting' component={BasicSettingPage} />
                                <Route key="/change-password" exact path='/change-password' component={ChangePasswordPage} />


                            </Switch>
                            <Footer style={{ textAlign: 'center' }}>
                                <Space direction='vertical' size={1}>
                                    <Image referrerPolicy="no-referrer" src="/redsquare_logo.png" width={102} preview={false} />
                                    <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>Copyright © 2022 mywheels.com.my  车天地</span>
                                </Space>
                            </Footer>
                        </Layout>
                    </Content>
                </Layout>
            </Layout>
        </HashRouter>
    )

}

export default withRouter(SideBar);