import { Layout, Space, Row, Button, Table, Modal, Input, message, Divider } from "antd";
import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { allOperations } from "../../../server/query";
import { CreateOperation, RetireInternalUser, UpdateOperation } from "../../../server/mutation";
import PermissionCheck from '../../../components/permission_check'
import { useHistory, useLocation } from "react-router-dom";
import { ROLES } from '../../../server/constant'
import './team-setting.css'

const TeamSettingPage = () => {
    const { confirm } = Modal;
    const [membersList, setMembersList] = useState([])
    const [memberTotal, setMemberTotal] = useState([])
    const [statusModal, setStatusModal] = useState('create')

    const [name, setName] = useState('')
    const [mobile, setMobile] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [ID, setID] = useState('')
    const [roles, setRoles] = useState('')
    const [createModalVisible, setCreateModalVisible] = useState(false)
    const { loading, error, data, refetch } = useQuery(allOperations, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "no-cache",
    })
    const [AddOperation] = useMutation(CreateOperation, {
        onCompleted(data) {
            if (data.createOperation.ok == null) {
                message.error(data.createOperation.error);
            } else {
                mutationSuccess('Agent created.')
                refetch()
            }
        }
    })
    const [EditOperation] = useMutation(UpdateOperation, {
        onCompleted(data) {
            if (data.updateOperation.ok == null) {
                message.error(data.updateOperation.error);
            } else {
                mutationSuccess('Agent updated.')
                refetch()
            }
        }
    })
    const [DeleteOperation] = useMutation(RetireInternalUser, {
        onCompleted(data) {
            if (data.retireInternalUser.ok == null) {
                message.error(data.retireInternalUser.error);
            } else {
                mutationSuccess('Agent deleted.')
                refetch()
            }
        }
    })

    const column = [
        {
            title: 'Name \n姓名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: "Email \n电子邮件",
            dataIndex: "email"
        },
        {
            title: "Mobile Number \n手机号码",
            dataIndex: 'phoneNumber'
        },
        {
            title: '',
            dataIndex: ['name', 'phoneNumber', 'id', 'email'],
            key: 'action',
            render: (text, row) => (
                <Space className="Table-action" size={2} split={<Divider type="vertical" style={{ borderColor: '#D8D8D8' }} />}>
                    <Button type="text" style={{ color: '#C62828' }} onClick={() => { setCreateModalVisible(true); setName(row['name']); setID(row['id']); setMobile(parseInt(row['phoneNumber'])); setEmail(row['email']); setStatusModal('edit') }}>Edit 编辑</Button>
                    <Button type="text" style={{ color: '#C62828' }} onClick={() => { showDeleteConfirm(row['id'], row['name']) }}>Delete 删除</Button>
                </Space>
            ),
        },
    ]

    const viewOnlyColumn = [
        {
            title: 'Name \n姓名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: "Email \n电子邮件",
            dataIndex: "email"
        },
        {
            title: "Mobile Number \n手机号码",
            dataIndex: 'phoneNumber'
        },
    ]

    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        const roles = localStorage.getItem(ROLES)
        setRoles(roles)

        if (roles.includes('super_admin') || roles.includes('project_lead') || roles.includes('operation')) {

        } else {
            history.push('/inbox')
        }

        if (loading === false && data != null) {
            rerenderListView(data.allOperations.edges)
            setMemberTotal(data.allOperations.totalCount)
        }
    }, [data, loading]);


    function mutationSuccess(text) {
        message.success(text);
        resetForm()

    }

    function resetForm() {
        setCreateModalVisible(false)
        setName('')
        setMobile('')
        setEmail('')
        setPassword('')
        setStatusModal('create')

    }

    function rerenderListView(data) {
        var list = []
        for (var i = 0; i < data.length; i++) {
            list.push({
                'id': data[i].node.id,
                'name': data[i].node.name,
                'phoneNumber': data[i].node.phoneNumber,
                'email': data[i].node.email,
                'date': data[i].node.dateJoined,
            })
        }

        list.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
        });
        setMembersList(list)
    }

    const onPressedCreateButton = () => {
        console.log(name, mobile, email)
        if (statusModal === 'create') {
            AddOperation({ variables: { name: name, phoneNumber: mobile, email: email, password: password } })
        }
        if (statusModal === 'edit') {
            EditOperation({ variables: { id: ID, name: name, phoneNumber: mobile } })

        }
    }

    function showDeleteConfirm(id, name) {

        confirm({
            title: `Are you sure to remove ${name}? 是否要删除 ${name} ？`,

            okText: 'Yes 是',
            okType: 'danger',
            cancelText: 'No 否',
            onOk() {
                DeleteOperation({ variables: { id: id } })
            },
            onCancel() {
            },
        });
    }

    const onPressedCancelButton = () => {
        setCreateModalVisible(false)
        setStatusModal('create')
    }

    const onChangeModalInput = (e, type) => {
        if (type === 'name') { setName(e.target.value) }
        if (type === 'mobile') { setMobile(e.target.value) }
        if (type === 'email') { setEmail(e.target.value) }
        if (type === 'password') { setPassword(e.target.value) }
    }


    return (
        <Layout className="finance-team">
            <Modal title={statusModal === 'create' ? "Create Operation 新增系统管理员" : "Edit Operation 编辑系统管理员"} visible={createModalVisible} onOk={onPressedCreateButton} onCancel={onPressedCancelButton} className="create-modal" footer={[
                <Button key="back" onClick={onPressedCancelButton} style={{ borderRadius: '4px' }}>
                    Back 返回
                </Button>,
                <Button key="submit" type="primary" onClick={onPressedCreateButton} className="submit-button">
                    {statusModal === 'create' ? "Add New 新增" : 'Edit 编辑'}
                </Button>,
            ]}>
                <Layout>
                    <Space direction='vertical' style={{ background: 'white' }} size="large">
                        <div>
                            <h1>Name 姓名</h1>
                            <Input placeholder="Name" onChange={(e) => onChangeModalInput(e, 'name')} value={name} />
                        </div>
                        <div>
                            <h1>Mobile Number 手机号码</h1>
                            <Input placeholder="Mobile Number" type="number" onChange={(e) => onChangeModalInput(e, 'mobile')} value={mobile} />
                        </div>

                        {statusModal === 'create' ? <div>
                            <h1>Email 电子邮件</h1>
                            <Input placeholder="Email" onChange={(e) => onChangeModalInput(e, 'email')} value={email} />
                        </div>
                            : <div>
                                <h1>Email 电子邮件</h1>
                                <Input placeholder="Email" disabled style={{ color: 'black', background: 'white', cursor: 'auto' }} onChange={(e) => onChangeModalInput(e, 'email')} value={email} />
                            </div>}
                        {statusModal === 'create' ? <div>
                            <h1>Password 密码</h1>
                            <Input placeholder="Password" onChange={(e) => onChangeModalInput(e, 'password')} value={password} />
                        </div> : null}

                    </Space>
                </Layout>
            </Modal>
            <Row justify="space-between" align='top'>
                <Space direction='horizontal' align='baseline'>
                    <h1>Operation Team 系统管理员</h1>
                    <h2> {memberTotal} members 成员</h2>
                </Space>
                {
                    roles.includes('super_admin') || roles.includes('project_lead') || roles.includes('operation') ? <Button style={{ minWidth: '120px' }} className="add-button" onClick={() => setCreateModalVisible(true)}>Add New 新增</Button> : null
                }

            </Row>
            <Table dataSource={membersList} style={{ whiteSpace: "pre" }} columns={roles.includes('project_lead') ? viewOnlyColumn : column} key={membersList} />
        </Layout>
    )
}

export default TeamSettingPage;