import { Layout, Space, Breadcrumb, Form, Input, Button, message, Row, Col, Table, Image, Select, Tooltip } from "antd";
import React, { useState, useEffect } from 'react';
import { ROLES } from '../../../server/constant'
import { useQuery, useMutation } from 'react-apollo';
import { useHistory, useLocation } from "react-router-dom";
import { AllUsers, DownloadAdvertiserVerificationDocument, ViewVerificationDocuments } from "../../../server/query";
import Moment from 'moment';
import client from '../../../server/apollo_config'

const UserListPage = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const [totalCount, setTotalCount] = useState(0)
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [offSet, setOffSet] = useState(0);
    const [userList, setUserList] = useState()
    const [name, setName] = useState()
    const [source, setSource] = useState("")
    const [selfie, setSelfie] = useState('')
    const [icFront, setIcFront] = useState('')
    const [icBack, setIcBack] = useState('')
    const [visible, setVisible] = useState(false);
    const [viewSelfie, setViewSelfie] = useState(false);
    

    const { loading, error, data } = useQuery(
        AllUsers, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "no-cache",
        variables: { offSet, name, registrationSource: source },
    })


    const column = [
        {
            title: 'ID \n编号',
            dataIndex: 'id',
            key: 'id',
            fixed: 'left',
            width: 60

        },
        {
            title: 'Name \n姓名',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: 120,
            ellipsis: {
                showTitle: false,
            },
            render: (text) => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            ),
        },
        {
            title: 'Role \n角色',
            dataIndex: 'role',
            key: 'role',
            width: 120
        },
        {
            title: 'Email \n电子邮件',
            dataIndex: 'email',
            key: 'email',
            width: 250,
            ellipsis: {
                showTitle: false,
            },
            render: (text) => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            ),
        },
        {

            title: 'Mobile Number \n电话号码',
            dataIndex: 'phone',
            key: 'phone',
            width: 120
        },
        {
            title: 'Source \n来源',
            dataIndex: 'source',
            key: 'source',
            width: 120
        },
        {
            title: 'Reference Link \n参考链接',
            dataIndex: 'link',
            key: 'link',
            width: 200,
            ellipsis: {
                showTitle: false,
            },
            render: (text) => (
                <Tooltip placement="topLeft" title={text}>
                  <a href={text}>{text}</a>
                </Tooltip>
            ),
        },
        {
            title: 'Reference Title \n参考标题',
            dataIndex: 'title',
            key: 'title',
            width: 300,
            ellipsis: {
                showTitle: false,
            },
            render: (text) => ( 
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            ),
        },
        {
            title: 'Registration Date \n登记日期',
            dataIndex: 'date',
            key: 'date',
            render: (text, row) => row['date'] !== '' ? (Moment(row['date']).format('DD/MM/YYYY')) : '',
            width: 130
        },
        {
            title: 'Verified at \n验证于',
            dataIndex: 'verify_at',
            key: 'verify_at',
            width: 120,
            render: (text, row) => row['verify_at'] !== '' ? (Moment(row['verify_at']).format('DD/MM/YYYY')) : '',
        },
        {
            title: 'View \n看法',
            dataIndex: ['advertiser_id', 'advertiser'],
            key: 'action',
            render: (text, row) => row['advertiser'] !== null ? <Button type="text" className="table-action-button" onClick={() => { onClickView(row['advertiser_id']) }}>View 看法</Button> : '',
            width: 120
        },
        {
            title: 'Download \n下载',
            dataIndex: 'advertiser_id',
            key: 'action',
            render: (text, row) => row['advertiser'] !== null ? <Button type="text" className="table-action-button" onClick={() => { onClickDownload(text) }}>Download 下载</Button> : '',
            fixed: 'right',
            width: 120

        }
    ]

    useEffect(() => {
        const roles = localStorage.getItem(ROLES)
        if (roles.includes('super_admin')) {

        } else {
            history.push('/inbox')
        }

        if (loading === false && data !== undefined) {
            rerenderListView(data.allUsers.edges)
            setTotalCount(data.allUsers.totalCount)
        }
    }, [data, history, loading])

    function rerenderListView(data) {
        var list = []

        for (var i = 0; i < data.length; i++) {
            const { firstName, lastName } = data[i].node
            let name = firstName

            if(lastName){
                name = `${firstName} ${lastName}`
            }

            list.push({
                'id': data[i].node.regularId,
                'name': name,
                'role': data[i].node.roles,
                'email': data[i].node.email,
                'phone': data[i].node.phoneNumber,
                'source': data[i].node.registrationReference?.registrationSource,
                'link':  data[i].node.registrationReference?.referenceLink,
                'title': data[i].node.registrationReference?.referenceTitle,
                'date': data[i].node.dateJoined,
                'verify_at': data[i].node.dateJoined,
                'advertiser': data[i].node.advertiser,
                'advertiser_id': data[i].node.advertiser?.id,
            })
        }

        setUserList(list)

    }

    function onClickView(id) {
        console.log('id', id)
        client.query({
            query: ViewVerificationDocuments,
            variables: { id: id },
            fetchPolicy: "network-only"
        }).then((res) => {

            if (res.data.advertiser.icBack != null || res.data.advertiser.icFront == !null || res.data.advertiser.selfie == !null) {
                setSelfie(res.data.advertiser.selfie);
                setIcBack(res.data.advertiser.icBack);
                setIcFront(res.data.advertiser.icFront);
                setVisible(true)
                if (res.data.advertiser.icBack === null) {
                    message.error(`Back image of ic is not uploaded.`)
                }

            } else {
                message.error(`No Document has been uploaded. 
                没有文件上传`)
            }
        })

    }

    function onClickDownload(advertiserId) {
        if (advertiserId == null) {
            message.error(`No document found for this advertiser. 未找到此广告客户的文件`)
        } else {
            client.query({
                query: DownloadAdvertiserVerificationDocument,
                fetchPolicy: 'network-only',
                variables: { advertiserId }

            }).then((res) => {
                let element = document.createElement('a');
                element.setAttribute('href', res.data.downloadAdvertiserVerificationDocuments.url);
                element.setAttribute('target', "_blank");
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            })
        }
    }

    const onFinish = (values) => {
        setName(values.name)
        setCurrentPageNumber(1)
        setOffSet(0)
        setSource(values.regSource)
    }

    function onResetClicked() {
        form.resetFields();
        setName("")
        setOffSet(0)
        setCurrentPageNumber(1)
        setSource("")
    }


    return (
        <Layout>
            <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis) }} >
                <Image
                    width={200}
                    style={{ display: 'none' }}
                    src={selfie}
                    preview={{
                        visible,
                        src: selfie,
                        onVisibleChange: value => {
                            setVisible(value);
                        },
                    }}
                />
                <Image
                    width={200}
                    style={{ display: 'none' }}
                    src={icFront}
                    preview={{
                        visible,
                        src: icFront,
                        onVisibleChange: value => {
                            setVisible(value);
                        },
                    }}
                />
                <Image
                    width={200}
                    style={{ display: 'none' }}
                    src={icBack}
                    preview={{
                        visible,
                        src: icBack,
                        onVisibleChange: value => {
                            setVisible(value);
                        },
                    }}
                />
            </Image.PreviewGroup>

            <Space direction='vertical' className="commission_header">
                <Breadcrumb style={{ marginBottom: '16px' }}>
                    <Breadcrumb.Item>Homepage 首页</Breadcrumb.Item>
                    <Breadcrumb.Item>User List 代理列表</Breadcrumb.Item>
                </Breadcrumb>
                <Space size="large">
                    <h1>User List 代理列表</h1>
                    <span>{totalCount} users 用户</span>
                </Space>
            </Space>

            <Layout style={{ margin: '16px 32px' }}>
                <Form className="filter-form" name='basic' onFinish={onFinish} form={form} autoComplete="false">
                    <Row align="top" justify="space-between">
                        <Col span={8}>
                            <Row>
                                <Col span={3}>
                                    <p>Name <br />姓名</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="name">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row>
                                <Col span={6}>
                                    <p>Reg. Source</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="regSource">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8} style={{ textAlign: 'end' }}>
                            <Space direction="horizontal">
                                <Button onClick={() => onResetClicked()}>
                                    Reset 重设
                                </Button>
                                <Button className="search-button" htmlType="submit">
                                    Search 搜寻
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>

                <div style={{ background: 'white' }}>
                    <Table className="main-table" style={{ whiteSpace: "pre" }} scroll={{ x: 1500 }} size="small" columns={column} dataSource={userList} pagination={{
                        current: currentPageNumber,
                        total: totalCount,
                        showSizeChanger: false,
                        pageSize: 5,
                        onChange: (page, pageSize) => {
                            var off = (page - 1) * 5
                            setOffSet(off);
                            setCurrentPageNumber(page);
                        },
                    }} />
                </div>


            </Layout>
        </Layout>
    )

}

export default UserListPage;