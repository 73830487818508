import { Layout, Form, Space, Input, Button, Row, Col, Table, Modal, Select, message, Divider, ConfigProvider, DatePicker } from "antd";
import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import { useQuery, useMutation } from 'react-apollo';
import './agent.css'
import { CreateAgent, EditAgent, RetiredAgent } from '../../../server/mutation'
import { AgentSearch, AllAgentLead, DownloadAgentReport } from '../../../server/query'
import client from '../../../server/apollo_config'
import { ROLES } from '../../../server/constant'
import locale from 'antd/lib/locale/zh_CN';
import Moment from 'moment';
const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";

const AgentPage = () => {
    const { Option } = Select;
    const { confirm } = Modal;

    const [form] = Form.useForm();
    const [offSet, setOffSet] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [totalAgents, setTotalAgents] = useState(0);
    const [agentList, setAgentList] = useState([])
    const [agentLeadList, setAgentLeadList] = useState([])
    const [status, setStatus] = useState(false)
    const [agentID, setAgentID] = useState('')
    const [agentName, setAgentName] = useState('')
    const [createModalVisible, setCreateModalVisible] = useState(false)
    const [statusModal, setStatusModal] = useState('create')

    const [ID, setID] = useState('')


    const [variables, setVariables] = useState({ offSet, agentId: agentID, agentName: agentName, agentType: "agent" })
    const { loading, error, data, refetch } = useQuery(AgentSearch, {
        variables: variables
    })
    const [CreateNewAgent] = useMutation(CreateAgent, {
        onCompleted(data) {
            if (data.createAgent.ok == null) {
                message.error(data.createAgent.error);
            } else {
                message.success('Agent created.')
                setCreateModalVisible(false)
                refetch()
            }
        }
    })
    
    const [UpdateAgent] = useMutation(EditAgent, {
        onCompleted(data) {
            if (data.updateAgent.ok == null) {
                message.error(data.updateAgent.error);
            } else {
                message.success('Agent updated.')
                setCreateModalVisible(false)
                refetch()
            }
        }
    })
    const [RetiredAgentMutation] = useMutation(RetiredAgent, {
        onCompleted(data) {
            if (data.retireAgent.ok == null) {
                message.error(data.retireAgent.error);
            } else {
                message.success('Agent removed.')
                setCreateModalVisible(false)
                refetch()
            }
        }
    })

    const { loading: agentLeadsLoading, data: agentLeadsData } = useQuery(AllAgentLead)

    const history = useHistory()
    useEffect(() => {
        const roles = localStorage.getItem(ROLES)

        if  (roles.includes('super_admin') || roles.includes('operation') || roles.includes('finance') || roles.includes('agent_lead') || roles.includes('project_lead'))  {
            if (roles.includes('super_admin') || roles.includes('operation') || roles.includes('finance')) {
                setStatus(true);
            }    
        }
        else {
            history.push('/inbox')
        }

        if (loading === false && data != null) {
            rerenderListView(data.agentSearch.edges, 'agent');
            setTotalAgents(data.agentSearch.totalCount);
        }

        if (agentLeadsLoading === false && agentLeadsData != null) {
            rerenderListView(agentLeadsData.allAgentLeads.edges, 'lead agent')
        }

    }, [agentLeadsLoading, agentLeadsData, data, loading, history]);

    let { url } = useRouteMatch();

    const onchangeDate = (date, dateString) => {

        var expired_date = Moment.parseZone(dateString[1]).utc().format()
        var start_date = Moment.parseZone(dateString[0]).utc().format()

        if (date != null) {
            setVariables({
                offSet,
                agentId: agentID,
                agentName: agentName,
                agentType: "agent",
                startDate: start_date,
                endDate: expired_date
            })
        } else if (date == null) {
            setVariables({
                offSet,
                agentId: agentID,
                agentName: agentName,
                agentType: "agent",
            })
        }
    }

    const columns = [
        {
            title: 'ID \n编号',
            dataIndex: 'code',
            key: 'code',

        },
        {
            title: 'Name \n姓名',
            dataIndex: ['name', 'id'],
            key: 'name',
            render: (text, row) => <Link type="text" style={{ color: '#1890FF' }} to={`${url}/agent-detail/${row['id']}`}>{row['name']}</Link>,
        },
        {
            title: 'Mobile Number \n手机号码',
            dataIndex: 'phoneNumber',
        },
        {
            title: 'Agent Lead \n代理主管',
            dataIndex: ['agent_lead', 'agent_id'],
            key: 'agent_lead',

            render: (text, row) => <Link type="text" style={{ color: '#1890FF' }} to={`${url}/agent-lead-detail/${row['agent_id']}`}>{row['agent_lead']}</Link>,
        },
        {
            title: 'Advertiser \n广告商',
            dataIndex: 'dealer_amount',
            key: 'dealer_amount',
            render: text => <p>{text}</p>,

            sorter: (a, b) => a.car_dealer_amount - b.car_dealer_amount,
        },
        {
            title: "Sales \n业绩",
            dataIndex: "sales",
            key: "sales",
            render: (text) => <p>RM{text ?? 0}</p>,
            sorter: (a, b) => a.sales - b.sales,
            filterDropdown: (
                <div className="custom-filter-dropdown">
                    <div>
                        <RangePicker
                            onChange={onchangeDate}
                            format={dateFormat}
                        />
                    </div>
                </div>
            ),
        },
        {
            title: '',
            dataIndex: ['name', 'phoneNumber', 'id', 'email'],
            key: 'action',
            render: (text, row) => (
                <Space className="Table-action" size={2} split={<Divider type="vertical" style={{ borderColor: '#D8D8D8' }} />}>
                    <Button type="text" className="table-action-button" onClick={() => openModal(row['id'], row['name'], row['phoneNumber'], row['email'])}>Edit 编辑</Button>
                    <Button type="text" className="table-action-button" onClick={() => showDeleteConfirm(row['id'], row['name'])}>Remove 删除</Button>
                </Space>
            ),
        },
    ];

    const basicColumns =
        [
            {
                title: 'ID \n编号',
                dataIndex: 'code',
                key: 'code',

            },
            {
                title: 'Name \n姓名',
                dataIndex: ['name', 'id'],
                key: 'name',
                render: (text, row) => <Link type="text" style={{ color: '#1890FF' }} to={`${url}/agent-detail/${row['id']}`}>{row['name']}</Link>,
            },
            {
                title: 'Mobile Number \n手机号码',
                dataIndex: 'phoneNumber',
            },
            {
                title: 'Agent Lead \n代理主管',
                dataIndex: ['agent_lead', 'agent_id'],
                key: 'agent_lead',

                render: (text, row) => <Link type="text" style={{ color: '#1890FF' }} to={`agent-lead/agent-lead-detail/${row['agent_id']}`}>{row['agent_lead']}</Link>,
            },
            {
                title: 'Advertiser\n广告商',
                dataIndex: 'dealer_amount',
                key: 'dealer_amount',
                render: text => <p>{text}</p>,

                sorter: (a, b) => a.car_dealer_amount - b.car_dealer_amount,
            },
            {
                title: "Sales \n业绩",
                dataIndex: "sales",
                key: "sales",
                render: (text) => <p>RM{text ?? 0}</p>,
                sorter: (a, b) => a.sales - b.sales,
                filterDropdown: (
                    <div className="custom-filter-dropdown">
                        <div>
                            <RangePicker
                                onChange={onchangeDate}
                                format={dateFormat}
                            />
                        </div>
                    </div>
                ),
            },
        ];

    function rerenderListView(data, type) {
        var list = []

        if (type === 'agent') {
            for (var i = 0; i < data.length; i++) {
                list.push({
                    "id": data[i].node.id,
                    "code": data[i].node.secondaryId,
                    "name": data[i].node.user.name,
                    "agent_lead": data[i].node.parent?.user?.name,
                    "agent_id": data[i].node.parent?.id,
                    "dealer_amount": data[i].node.allAdvertisers.totalCount,
                    "sales": data[i].node.sales,
                    "email": data[i].node?.user?.email,
                    "phoneNumber": data[i].node?.user?.phoneNumber,
                    "date": data[i].node?.user?.dateJoined
                });
            }
            list.sort(function (a, b) {
                return new Date(b.date) - new Date(a.date);
            });
            setAgentList(list)
        }

        if (type === 'lead agent') {

            for (var idx = 0; idx < data.length; idx++) {
                list.push({
                    id: data[idx].node.id,
                    name: data[idx].node.user?.name,
                    date: data[idx].node.user?.dateJoined
                })
            }
            list.sort(function (a, b) {
                return new Date(b.date) - new Date(a.date);
            });
            setAgentLeadList(list)

        }
    }

    function onResetClicked() {
        setOffSet(0);
        setCurrentPageNumber(1);
        setAgentID('')
        setAgentName('')
        setVariables({
            offSet,
            agentId: agentID,
            agentName: agentName,
            agentType: "agent",
        })

    }

    const onChangeInput = (e, type) => {

        if (type === 'name') { setAgentName(e.target.value) }
        if (type === 'id') { setAgentID(e.target.value) }
    }

    const handleCancel = () => {
        setCreateModalVisible(false)
        setStatusModal('create')
        form.resetFields()
    }

    const onFinish = (values) => {
        if (statusModal === "create") {
            CreateNewAgent({
                variables: {
                    name: values.name,
                    phoneNumber: values.mobile,
                    email: values.email,
                    secondaryId: values.agentId,
                    password: values.password,
                    agentLead: values.agent_lead
                },
            });
        }
        if (statusModal === "edit") {
            UpdateAgent({ variables: { id: ID, name: values.name, phoneNumber: values.mobile } });
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    function openModal(id, name, mobile, email) {

        setCreateModalVisible(true)
        setStatusModal('edit')
        setID(id)
        form.setFieldsValue({
            name: name,
            mobile: parseInt(mobile),
            email: email
        })

    }

    function showDeleteConfirm(id, name) {

        confirm({
            title: `Are you sure to remove ${name} 是否要删除 ${name} ？`,

            okText: 'Yes 是',
            okType: 'danger',
            cancelText: 'No 否',
            onOk() {
                RetiredAgentMutation({ variables: { id: id } })
            },
            onCancel() {
            },
        });
    }


    async function downloadReportFunction() {

        client.query({
            query: DownloadAgentReport,
            fetchPolicy: "network-only"
        }).then((res) => {
            let element = document.createElement('a');
            element.setAttribute('href', res.data.downloadAgentReport.url);
            element.setAttribute('target', "_blank");
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        })
    }

    return (
        <Layout className="lead-agent-main">
            <Modal title={statusModal === 'create' ? "Create Agent 新增代理" : "Edit Agent 编辑代理"} visible={createModalVisible} onCancel={handleCancel} className="create-modal" footer={null}>
                <Form
                    name="basic"
                    form={form}
                    layout='vertical'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Name 姓名"
                        name="name"
                        rules={[{ required: true, message: '*必填' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Mobile Number 手机号码"
                        name="mobile"
                        rules={[
                            {
                                required: true,
                                message: '*必填'
                            },
                        ]}
                    >
                        <Input type="number" />
                    </Form.Item>
                    {
                        statusModal === 'create' ? <Form.Item
                            label="Email 电子邮件"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    type: 'email',
                                    message: '*必填'
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                            :
                            <Form.Item
                                label="Email 电子邮件"
                                name="email"

                            >
                                <Input disabled style={{
                                    color: "black",
                                    background: "white",
                                    cursor: "auto",
                                }} />
                            </Form.Item>
                    }
                    {statusModal === 'create' ? <Form.Item
                        label="Agent ID 代理编号"
                        name="agentId"
                        rules={[
                            {
                                required: true,
                                message: '*必填, 仅限数字和字符',
                                pattern: new RegExp("^[a-zA-Z0-9]*$"),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item> : null}
                    {statusModal === 'create' ? <Form.Item

                        label="Password 密码"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: '*必填',
                            },
                        ]}

                    >
                        <Input />
                    </Form.Item> : null}
                    {statusModal === 'create' ?
                        <Form.Item name="agent_lead" label="Agent Lead 代理主管" rules={[{ required: true, message: "*必填" }]}>
                            <Select
                                showSearch
                                style={{ width: 200 }}
                                placeholder="Select Agent Lead 选择代理主管"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {agentLeadList.map((agent) => (
                                    <Option value={agent.id}>{agent.name}</Option>
                                ))}
                            </Select>
                        </Form.Item> : null}


                    <div style={{ textAlign: 'end' }}>
                        <Space direction="horizontal">
                            <Form.Item>
                                <Button
                                    key="back"
                                    onClick={handleCancel}
                                    style={{ borderRadius: "4px" }}
                                >
                                    Back 返回
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="submit-button">
                                    {statusModal === 'create' ? "Add New 新增" : 'Edit 编辑'}
                                </Button>
                            </Form.Item>
                        </Space>
                    </div>

                </Form>

            </Modal>
            <Row className="agent-lead-header" gutter={[16]} >
                <Col span={8}>
                    <Row>
                        <Col span={5} style={{ alignSelf: 'self-end' }}>
                            <h1>Agent ID <br />代理编号:</h1>
                        </Col>
                        <Col span={16}>
                            <Input placeholder="Please Enter 请输入" type="number" value={agentID} onChange={(e) => onChangeInput(e, 'id')} />
                        </Col>
                    </Row>
                </Col>
                <Col span={8}>
                    <Row>
                        <Col span={3} style={{ alignSelf: 'self-end' }}>
                            <h1>Name <br />姓名:</h1>
                        </Col>
                        <Col span={16}>
                            <Input placeholder="Please Enter 请输入" value={agentName} onChange={(e) => onChangeInput(e, 'name')} />
                        </Col>
                    </Row>
                </Col>
                <Col span={8} align="end">
                    <Space direction="horizontal">
                        <Button style={{ borderRadius: '4px' }} onClick={onResetClicked}>Reset 重设</Button>
                        <Button style={{ background: '#C62828', color: 'white', borderRadius: '4px', borderColor: '#C62828' }} onClick={() => {
                            setCurrentPageNumber(1);
                            setOffSet(0);
                            setVariables({
                                offSet,
                                agentId: agentID,
                                agentName: agentName,
                                agentType: "agent",
                            })
                        }}>Search 搜寻</Button>
                    </Space>
                </Col>
            </Row>


            <Space direction="vertical" className="agent-lead-table">
                {status ?
                    <Row justify="space-between" style={{ paddingBottom: '22px' }}>
                        <h2>Agent 代理&emsp;<span>{totalAgents} agent 代理</span></h2>
                        <Space direction="horizontal">
                            <Button style={{ borderRadius: '4px' }} onClick={() => downloadReportFunction()}>Download 下载</Button>
                            <Button className='add-new-button' onClick={() => setCreateModalVisible(true)}>Add New 新增</Button>
                        </Space>
                    </Row> : null}

                <ConfigProvider locale={locale}>
                    <Table
                        dataSource={agentList}
                        style={{ whiteSpace: "pre" }}
                        columns={status ? columns : basicColumns}
                        key={agentList}
                        pagination={{
                            current: currentPageNumber, total: totalAgents, pageSize: 5, onChange: (page, pageSize) => {
                                var off = (page - 1) * 5
                                setOffSet(off);
                                setCurrentPageNumber(page);
                                setVariables({ offSet: off, agentId: agentID, agentName: agentName, agentType: "agent" })
                            }
                        }}
                    />
                </ConfigProvider>

            </Space>

        </Layout>
    )
}

export default AgentPage;