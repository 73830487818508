import { Button, Layout, message, Modal, Row, Space, Table, Input } from "antd"
import { useEffect, useState } from "react"
import { useQuery, useMutation } from 'react-apollo';
import { ROLES } from "../../../server/constant";
import { UpdateCommission } from "../../../server/mutation";
import { AllCommission } from "../../../server/query"
import {  useHistory } from "react-router-dom";;

export default function CommissionSettingPage(){
    const [totalCommissionSetting, setTotalCommissionSetting] = useState(0)
    const [commissionEdit, setCommissionEdit] = useState(false)
    const [commissionRole, setCommissionRole] = useState('')
    const [commissionId, setCommissionId] = useState('')
    const [commissionPercentage, setCommissionPercentage] = useState('')
    const [commissionOffSet, setCommissionOffSet] = useState(0)
    const [CommissionList, setCommissionList] = useState([])
    const [roles, setRoles] = useState('')

    const history = useHistory()

    const { loading: commissionLoading, data: commissionData, refetch: commissionRefetch } = useQuery(AllCommission, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "no-cache",
        variables: { commissionOffSet }
    })

    const listOfViewOnlyRoles = ['operation']

    const [EditCommissionSetting] = useMutation(UpdateCommission, {
        onCompleted(data) {
            if (data.updateCommission.ok == null) {
                message.error(data.updateCommission.error);
            } else if (data.updateCommission.ok === 'yes') {
                message.success('Updated successfully.')
                setCommissionEdit(false)
                commissionRefetch()
            }
        }
    });


    useEffect(() =>{
        const roles = localStorage.getItem(ROLES)
        setRoles(roles)

        const isAllowedRole = ['super_admin', 'project_lead', 'finance', 'operation'].includes(roles)

        if (isAllowedRole === false) {
            history.push('/inbox')
            return
        } 

        if (commissionLoading === false && commissionData !== null) {
            renderCommissionSetting(commissionData.allCommissions.edges)
            setTotalCommissionSetting(commissionData.allCommissions.totalCount)
        }
    }, [ history, commissionData, commissionLoading])


    const renderCommissionSetting = (data) => {
        const list = []

        for (let i = 0; i < data.length; i++) {

            list.push({
                'id': data[i].node.id,
                'role': data[i].node.role.name,
                'percentage': data[i].node.percentage
            })
        }

        setCommissionList(list)
    }

    const handleCancel = () => {
        setCommissionEdit(false)
    }

    const onChangeModalInput = (e,) => {
        setCommissionPercentage(e.target.value)
    }

    const commissionEditOnPressedOK = () => {
        EditCommissionSetting({
            variables: {
                id: commissionId,
                percentage: commissionPercentage
            }
        })

    }

    const viewOnlyCommissionColumn = [
        {
            title: 'Role \n角色',
            dataIndex: 'role',
            key: 'role',
        },
        {
            title: 'Percentage \n巴仙率',
            dataIndex: 'percentage',
            key: 'percentage',
        },
    ]

    const CommissionColumn = [
        {
            title: 'Role \n角色',
            dataIndex: 'role',
            key: 'role',
        },
        {
            title: 'Percentage \n巴仙率',
            dataIndex: 'percentage',
            key: 'percentage',
        },
        {
            title: '',
            dataIndex: ['role', 'id', 'percentage'],
            key: 'action',
            render: (text, row) => (
                listOfViewOnlyRoles.includes(roles) === false &&
                <Space size="middle" className="Table-action">
                    <Button type="text" style={{ color: '#C62828' }} onClick={() => { setCommissionEdit(true); setCommissionRole(row['role']); setCommissionId(row['id']); setCommissionPercentage(row['percentage']) }} >Edit 编辑</Button>
                </Space>
            ),
        },
    ]


    return(
        <Layout>
            <Layout className="promo-code-layout">
                <Row justify='space-between' style={{ marginBottom: '24px' }}>
                    <h1>Commission Setting 佣金设定 <span>{totalCommissionSetting} package 配套</span></h1>
                </Row>
                <Table dataSource={CommissionList} style={{ whiteSpace: "pre" }} columns={roles.includes('project_lead') ? viewOnlyCommissionColumn : CommissionColumn} />
            </Layout>
            <Modal title="Edit Commission Setting 编辑佣金设定" visible={commissionEdit} onOk={commissionEditOnPressedOK} onCancel={handleCancel} footer={[
                <Button key="back" onClick={handleCancel} style={{ borderRadius: '4px' }}>
                    Back 返回
                </Button>,
                <Button key="submit" type="primary" onClick={commissionEditOnPressedOK} className="submit-button">
                    Edit 编辑
                </Button>,
            ]}>
                <Layout>
                    <Space direction='vertical' style={{ background: 'white' }} size="large">
                        <div>
                            <h1>Role 角色</h1>
                            <Input disabled style={{ color: 'black', background: 'white', cursor: 'auto' }} value={commissionRole} />
                        </div>
                        <div>
                            <h1>Percentage 巴仙率</h1>
                            <Input value={commissionPercentage} onChange={(e) => onChangeModalInput(e)} />
                        </div>
                    </Space>
                </Layout>

            </Modal>
        </Layout>
    )
}