import { Layout, Space, Breadcrumb, Form, Input, Button, DatePicker, Pagination, Row, Col, Table, ConfigProvider } from "antd";
import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { CommissionReports, DownloadCommissionReport } from "../../../server/query";
import client from '../../../server/apollo_config'
import './commission-report.css'
import Moment from 'moment';
import { Auth } from 'aws-amplify';
import PermissionCheck from "../../../components/permission_check";
import { ROLES } from '../../../server/constant'
import { useHistory, useLocation } from "react-router-dom";
import locale from 'antd/lib/locale/zh_CN';

const { RangePicker } = DatePicker;

const CommissionReport = () => {
    const [form] = Form.useForm();
    const [reportList, setReportList] = useState([])
    const [offSet, setOffSet] = useState('0');
    const [totalCount, setTotalCount] = useState('0');
    const [agentName, setAgentName] = useState(null)
    const [agentLeadName, setAgentLeadName] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [invoiceNo, setInvoiceNo] = useState(null)
    const [roles, setRoles] = useState('')
    const [variables, setVariables] = useState({ offSet: 0 })
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const { loading, error, data, refetch } = useQuery(CommissionReports, { variables })

    const columns = [
        {
            title: 'Invoice Date \n收据日期',
            dataIndex: 'date',
            key: 'date',
            render: (text, row) => row['date'] !== '' ? (Moment(row['date']).format('DD/MM/YYYY')) : '',
        },
        {
            title: 'Invoice Number \n收据编号',
            dataIndex: 'no',
            key: 'no',
        },
        {
            title: 'Advertiser Name \n广告商姓名',
            dataIndex: 'advertiser_name',
            key: 'advertiser_name',
        },
        {
            title: 'Billing Name \n付款人姓名',
            dataIndex: 'billing_name',
            key: 'billing_name',
        },
        {
            title: 'Description \n内容',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Invoice Amount \n收据金额',
            dataIndex: 'invoice_amount',
            key: 'invoice_amount',
        },
        {
            title: 'Agent Name \n代理姓名',
            dataIndex: 'agent',
            key: 'agent',
        },
        {
            title: 'Agent ID \n代理编号',
            dataIndex: 'agent_code',
            key: 'agent_code',
        },
        {
            title: 'Agent Lead Name \n代理主管姓名',
            dataIndex: 'agent_lead',
            key: 'agent_lead',
        },
        {
            title: 'Agent Lead ID \n代理主管编号',
            dataIndex: 'agent_lead_code',
            key: 'agent_lead_code',
        },
        {
            title: 'Payment Method \n付款方式',
            dataIndex: 'payment_method',
            key: 'payment_method',
        },
        {
            title: 'Payment Reference \n付款参考',
            dataIndex: 'reference',
            key: 'reference',
        },
        {
            title: 'Commission \n佣金',
            dataIndex: 'commission',
            key: 'commission',
            fixed: 'right',
            width: 150
        },


    ]

    const location = useLocation()
    const history = useHistory()

    const listOfViewOnlyRoles = ['operation', 'project_lead']

    useEffect(() => {
        const roles = localStorage.getItem(ROLES)
        setRoles(roles)

        if(['super_admin', 'project_lead', 'finance', 'operation'].includes(roles) === false){
            history.push('/inbox')
        }
        if (loading === false && data !== undefined) {

            rerenderListView(data.commissionReport.edges)
            setTotalCount(data.commissionReport.totalCount)
        }
    }, [data, history, loading, location])

    function rerenderListView(data) {
        var list = []
        for (var i = 0; i < data.length; i++) {
            list.push({
                'date': data[i].node.invoiceDate,
                'no': data[i].node.invoiceNo,
                'advertiser_name': data[i].node.advertiser?.legalName,
                'billing_name': data[i].node.billing?.name,
                'description': data[i].node.description,
                'invoice_amount': data[i].node.invoiceAmount,
                'commission': data[i].node.commission,
                'agent_code': data[i].node.agent?.referralCode,
                'agent': data[i].node.agent?.user?.name,
                'agent_lead': data[i].node.agentLead?.user?.name,
                'agent_lead_code': data[i].node.agentLead?.referralCode,
                'payment_method': data[i].node.paymentMethod,
                'reference': data[i].node.payment?.id
            })
        }

        setReportList(list)
    }

    async function onFinish(values) {
        console.log('Success:', values);
        if (values.agent !== undefined) setAgentName(values.agent)
        if (values.agent_lead !== undefined) setAgentLeadName(values.agent_lead)
        if (values.date !== undefined) setStartDate(values.date[0])
        if (values.date !== undefined) setEndDate(values.date[1])
        if (values.invoice !== undefined) setInvoiceNo(values.invoice)
        if (values.date !== undefined) {
            setVariables({
                offSet: 0,
                agentName: values.agent,
                agentLeadName: values.agent_lead,
                invoiceNo: values.invoice,
                startDate: values.date[0],
                endDate: values.date[1]
            })
        } else {
            setVariables({
                offSet: 0,
                agentName: values.agent,
                agentLeadName: values.agent_lead,
                invoiceNo: values.invoice
            })

        }

    };

    function onResetClicked() {
        form.resetFields();
        setAgentName(null)
        setAgentLeadName(null)
        setStartDate(null)
        setEndDate(null)
        setInvoiceNo(null)
        setVariables({ offSet: 0 })
    }

    async function downloadReportFunction() {
        console.log(agentName)
        console.log(agentLeadName)
        console.log(startDate)
        console.log(endDate)
        console.log(invoiceNo)
        client.query({
            query: DownloadCommissionReport,
            variables: { agentName: agentName, agentLeadName: agentLeadName, startDate: startDate, endDate: endDate, invoiceNo: invoiceNo },
            fetchPolicy: "network-only"
        }).then((res) => {
            console.log(res)
            let element = document.createElement('a');
            element.setAttribute('href', res.data.downloadCommissionReport.url);
            element.setAttribute('target', "_blank");
            // element.setAttribute('download', 'output.xlsx');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }).catch((error) => {
            console.log(error)
        })
    }

    function onPaginationClicked(page) {
        setCurrentPageNumber(page);
        // setVariables({
        //     offSet: ((page - 1) * 5),
        //     agentName,
        //     agentLeadName,
        //     invoiceNo
        // })
        console.log('startDate', startDate)
        console.log('endDate', endDate)

        if (startDate !== "") {
            setVariables({
                offSet: ((page - 1) * 5),
                agentName,
                agentLeadName,
                invoiceNo,
                startDate,
                endDate,
            })
        } else {
            setVariables({
                offSet: ((page - 1) * 5),
                agentName,
                agentLeadName,
                invoiceNo,
            })

        }
    }


    return (
        <Layout>
            <Space direction='vertical' className="commission_header">
                <Breadcrumb style={{ marginBottom: '16px' }}>
                    <Breadcrumb.Item>Homepage 首页</Breadcrumb.Item>
                    <Breadcrumb.Item>Commission Report 佣金报告</Breadcrumb.Item>
                </Breadcrumb>
                <h1>Commission Report 佣金报告</h1>
            </Space>
            <Layout style={{ margin: '16px 32px' }}>

                <Form className="filter-form" name="basic"
                    onFinish={onFinish}
                    form={form}
                    autoComplete="off">
                    <Row align="top" justify='space-between'>
                        <Col span={8}>
                            <Row>
                                <Col span={6}>
                                    <p>Date <br />日期</p>
                                </Col>
                                <Col span={16}>
                                    <ConfigProvider locale={locale}>
                                        <Form.Item name="date">
                                            <RangePicker />
                                        </Form.Item>
                                    </ConfigProvider>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row>
                                <Col span={8}>
                                    <p>Invoice Number <br />收据编号</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="invoice">
                                        <Input placeholder="Fill in number 填写编号" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8} style={{ textAlign: 'end' }}>
                            <Space direction="horizontal">
                                {
                                    listOfViewOnlyRoles.includes(roles) ? null : <Button onClick={() => downloadReportFunction()}>
                                        Export 汇出
                                    </Button>
                                }

                                <Button onClick={() => onResetClicked()}>
                                    Reset 重设
                                </Button>
                                <Button className="search-button" htmlType="submit">
                                    Search 搜寻
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                    <Row align="top" >
                        <Col span={8}>
                            <Row>
                                <Col span={6}>
                                    <p>Agent Name <br />代理姓名</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="agent">
                                        <Input placeholder="Fill by Agent Name 筛选代理" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row>
                                <Col span={8}>
                                    <p>Agent Lead Name <br />代理主管姓名</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="agent_lead">
                                        <Input placeholder="Fill by Agent Lead Name 筛选代理主管" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
                <div style={{ background: 'white' }}>
                    <Table className="main-table" style={{ whiteSpace: "pre" }} columns={columns} dataSource={reportList} scroll={{ x: 2800, y: 300 }}
                        pagination={{
                            showSizeChanger: false,
                            current: currentPageNumber, total: totalCount, pageSize: 10, onChange: (page, pageSize) => {
                                onPaginationClicked(page)
                            }
                        }}
                    />
                </div>
            </Layout>
        </Layout>
    )
}

export default CommissionReport;