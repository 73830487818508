import { Layout, Space, Breadcrumb, Form, Button, DatePicker, Row, Col, Table, ConfigProvider } from "antd";
import React, { useState, useEffect } from 'react';
import { DeferredRevenue, DownloadDeferredRevenueReport } from "../../../server/query";
import client from '../../../server/apollo_config'
import { useQuery } from 'react-apollo';
import { useHistory } from "react-router-dom";
import { ROLES } from '../../../server/constant'
import './deferred-report.css'
import Moment from 'moment';
import locale from 'antd/lib/locale/zh_CN';

const DeferredRevenueReport = () => {
    const [form] = Form.useForm();
    const [reportList, setReportList] = useState([])

    const [selectDate, setSelectDate] = useState(null)
    const [roles, setRoles] = useState('')
    const [totalCount, setTotalCount] = useState(0)
    const [offSet, setOffSet] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [variables, setVariables] = useState({ offSet })
    const history = useHistory()
    const { loading, error, data, refetch } = useQuery(DeferredRevenue, { variables })
    const columns = [
        {
            title: 'Published Date \n刊登日期',
            dataIndex: 'published_date',
            key: 'published_date',
            render: (text) =>(
                <div style={{width:'150px'}}>
                    {text ? Moment(text).format('DD/MM/YYYY') : "-"}
                </div>
            )
        },
        {
            title: 'Invoice Date \n收据日期',
            dataIndex: 'invoice_date',
            key: 'invoice_date',
            render: text => Moment(text).format('DD/MM/YYYY')
        },
        {
            title: 'Invoice Number \n收据编号',
            dataIndex: 'invoice_no',
            key: 'invoice_no',
        },
        {
            title: 'Billing Name \n付款人姓名',
            dataIndex: 'billing_name',
            key: 'billing_name',
        },
        {
            title: 'Description \n内容',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Platform \n平台',
            dataIndex: 'platform',
            key: 'platform',
        },
        {
            title: 'Duration \n时长',
            dataIndex: 'duration',
            key: 'duration',
        },
        {
            title: 'Invoice Amount \n收据金额',
            dataIndex: 'invoice_amount',
            key: 'invoice_amount',
        },
        {
            title: 'Deferred Revenue \n递延收入',
            dataIndex: 'deferred_revenue',
            key: 'deferred_revenue',
        },
        {
            title: 'Agent Name \n代理姓名',
            dataIndex: 'agent',
            key: 'agent',
        },
        {
            title: 'Agent Code \n代理编号',
            dataIndex: 'agent_code',
            key: 'agent_code',
        },
        {
            title: 'Agent Lead Name \n代理主管姓名',
            dataIndex: 'agent_lead',
            key: 'agent_lead',
        },
        {
            title: 'Agent Lead Code \n代理主管编号',
            dataIndex: 'agent_lead_code',
            key: 'agent_lead_code',
        },
        {
            title: 'Revenue Recognized \n收入确认',
            dataIndex: 'revenue_recognized',
            key: 'revenue_recognized',
            fixed: 'right',
            width: 150
        },

    ]

    const listOfViewOnlyRoles = ['operation', 'project_lead']

    useEffect(() => {
        const roles = localStorage.getItem(ROLES)
        setRoles(roles)

        const isAllowedRole = ['super_admin', 'project_lead', 'finance', 'operation'].includes(roles)

        if (isAllowedRole === false) {
            history.push('/inbox')
            return
        } 
        if (loading === false && data !== undefined) {

            rerenderListView(data.deferredRevenueReport.edges)
            setTotalCount(data.deferredRevenueReport.totalCount)
        }
    }, [data, loading, history])

    function rerenderListView(data) {
        var list = []
        for (var i = 0; i < data.length; i++) {
            list.push({
                'invoice_date': data[i].node.invoiceDate,
                'invoice_no': data[i].node.invoiceNo,
                'billing_name': (data[i].node.billing != null ? data[i].node.billing.name : ''),
                'description': data[i].node.description,
                'platform': data[i].node.platform,
                'duration': data[i].node.duration,
                'published_date': data[i].node?.listing?.firstPublishedAt,
                'invoice_amount': data[i].node.invoiceAmount,
                'deferred_revenue': data[i].node.deferredRevenue,
                'agent_code': (data[i].node.agent !== null ? data[i].node.agent.referralCode : ''),
                'agent': (data[i].node.agent !== null ? data[i].node.agent.user.name : ''),
                'agent_lead': (data[i].node.agentLead !== null ? data[i].node.agentLead.user.name : ''),
                'agent_lead_code': (data[i].node.agentLead !== null ? data[i].node.agentLead.referralCode : ''),
                'revenue_recognized': data[i].node.revenue
            })
        }

        setReportList(list)
    }

    async function downloadReportFunction() {
        console.log(selectDate)
        client.query({
            query: DownloadDeferredRevenueReport,
            variables: { selectDate },
            fetchPolicy: "network-only"
        }).then((res) => {
            let element = document.createElement('a');
            element.setAttribute('href', res.data.downloadDeferredRevenueReport.url);
            element.setAttribute('target', "_blank");
            // element.setAttribute('download', 'output.xlsx');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        })
    }

    const onFinish = (values) => {
        if (values.date !== undefined) {
            setVariables({
                offSet: 0,
                selectDate: values.date
            });
            setSelectDate(values.date);
        }
    };

    function onResetClicked() {
        form.resetFields();

        setSelectDate('')

        setVariables({ offSet: 0 })
    }

    function onPaginationClicked(page) {


        if (selectDate !== "") {
            setVariables({
                offSet: ((page - 1) * 5),
                selectDate
            })
        } else {
            setVariables({
                offSet: ((page - 1) * 5),
                
            })

        }
    }

    return (
        <Layout>
            <Space direction='vertical' className="header">
                <Breadcrumb style={{ marginBottom: '16px' }}>
                    <Breadcrumb.Item>Homepage 首页</Breadcrumb.Item>
                    <Breadcrumb.Item>Deferred Revenue Report 递延收入报告</Breadcrumb.Item>
                </Breadcrumb>
                <h1>Deferred Revenue Report 递延收入报告</h1>
            </Space>
            <Layout style={{ margin: '16px 32px' }}>

                <Form className="filter-form" name="basic"
                    onFinish={onFinish}
                    form={form}
                    autoComplete="off">
                    <Row align="top" justify='space-between'>
                        <Col span={8}>
                            <Row>
                                <Col span={6}>
                                    <p>Date <br />日期</p>
                                </Col>
                                <Col span={16}>
                                    <ConfigProvider locale={locale}>
                                        <Form.Item name="date">
                                            <DatePicker />
                                        </Form.Item>
                                    </ConfigProvider>

                                </Col>
                            </Row>
                        </Col>

                        <Col span={8} style={{ textAlign: 'end' }}>
                            <Space direction="horizontal">
                                {
                                    listOfViewOnlyRoles.includes(roles) ? null : <Button onClick={() => downloadReportFunction()}>
                                        Export 汇出
                                    </Button>
                                }

                                <Button onClick={() => onResetClicked()}>
                                    Reset 重设
                                </Button>
                                <Button className="search-button" htmlType="submit">
                                    Search 搜寻
                                </Button>
                            </Space>
                        </Col>
                    </Row>

                </Form>

                <div style={{ background: 'white' }}>
                    <Table className="main-table" style={{ whiteSpace: "pre" }} columns={columns} dataSource={reportList} scroll={{ x: 2000, y: 300 }}
                        pagination={{
                            showSizeChanger: false,
                            current: currentPageNumber, total: totalCount, pageSize: 5, onChange: (page, pageSize) => {
                                setCurrentPageNumber(page)
                                onPaginationClicked(page)
                            }
                        }}

                    />

                </div>
            </Layout>
        </Layout>
    )
}

export default DeferredRevenueReport;