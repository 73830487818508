// import configData from '../config.json'
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { setContext } from 'apollo-link-context';
import { Auth } from 'aws-amplify';
// import { createUploadLink } from 'apollo-upload-client';

const authLink = setContext(async (_, { headers }) => {
  const session = await Auth.currentSession();
  var token = session.getAccessToken().getJwtToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_PUBLIC_GRAPHENE_URL
})

const saleorHttpLink = new HttpLink({
  uri: process.env.REACT_APP_SALEOR_GRAPHENE_URL
})


const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
  // link: httpLink
});
export default client

export const saleorClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: saleorHttpLink,
})

