import { Layout, Space, Breadcrumb, Form, Input, Button, DatePicker, Pagination, Row, Col, Table, ConfigProvider, Modal, message } from "antd";
import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { CommissionReports, DownloadCommissionReport, InvoiceListingReport, DownloadInvoiceListingReport, DownloadInvoiceDocument, DownloadInvoiceDocuments } from "../../../server/query";
import client from '../../../server/apollo_config'
import './invoice-listing.css'
import Moment from 'moment';
import { Auth } from 'aws-amplify';
import PermissionCheck from "../../../components/permission_check";
import { ROLES } from '../../../server/constant'
import { useHistory, useLocation } from "react-router-dom";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import locale from 'antd/lib/locale/zh_CN';

const { RangePicker } = DatePicker;

const InvoiceListing = () => {
    const [form] = Form.useForm();
    const [reportList, setReportList] = useState([])
    const [offSet, setOffSet] = useState('0');
    const [totalCount, setTotalCount] = useState('0');
    // const [agentName, setAgentName] = useState(null)
    // const [agentLeadName, setAgentLeadName] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    // const [invoiceNo, setInvoiceNo] = useState(null)
    const [roles, setRoles] = useState('')
    const [variables, setVariables] = useState({ offSet: 0 })
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [pdfFile, setPdfFile] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [disabled, setDisabled] = useState(true);
    const [listOfSelectedRows, setSelectedRows] = useState([])
    const { loading, error, data, refetch } = useQuery(InvoiceListingReport, { variables })

    const columns = [
        {
            title: 'Invoice Date \n收据日期',
            dataIndex: 'date',
            key: 'date',
            render: (text, row) => row['date'] !== '' ? (Moment(row['date']).format('DD/MM/YYYY')) : '',
        },
        {
            title: 'Invoice Number \n收据编号',
            dataIndex: 'no',
            key: 'no',
        },
        {
            title: 'Advertiser Name \n广告商姓名',
            dataIndex: 'advertiser_name',
            key: 'advertiser_name',
        },
        {
            title: 'Billing Name \n付款人姓名',
            dataIndex: 'billing_name',
            key: 'billing_name',
        },
        {
            title: 'Invoice Amount \n收据金额',
            dataIndex: 'invoice_amount',
            key: 'invoice_amount',
        }, 
        {
            title: 'Tax Amount \n',
            dataIndex: 'tax',
            key: 'tax',
        }, 
        {
            title: 'Invoice Amount After Tax \n',
            dataIndex: 'invoice_amount_after_tax',
            key: 'invoice_amount_after_tax',
        },
        {
            title: 'Agent Code \n代理代码',
            dataIndex: 'agent_code',
            key: 'agent_code',
        },
        {
            title: 'Agent Name \n代理姓名',
            dataIndex: 'agent',
            key: 'agent',
        },
        {
            title: 'Agent Lead Name \n代理主管姓名',
            dataIndex: 'agent_lead',
            key: 'agent_lead',
        },
        {
            title: 'View \n看法',
            dataIndex: 'order_id',
            key: 'action',
            render: (text) => (text ? <Button type="text" className="table-action-button" onClick={() => { onClickView(text) }}>View 看法</Button> : null),
            fixed: 'right',
            width: 150,
        },
        {
            title: 'Download \n下载',
            dataIndex: 'order_id',
            key: 'action',
            render: (text) => (text ? <Button type="text" className="table-action-button" onClick={() => { onClickDownload(text) }}>Download 下载</Button> : null),
            fixed: 'right',
            width: 150,
        }

    ]

    function onClickDownload(orderId) {
        if (orderId == null) {
            message.error(`No order id found for this transaction. 未找到此交易的订单 ID `)
        } else {
            client.query({
                query: DownloadInvoiceDocuments,
                fetchPolicy: 'network-only',
                variables: { orderIds: [orderId] }

            }).then((res) => {
                console.log("test")
                console.log(res.data.downloadInvoiceDocuments.url)
                let element = document.createElement('a');
                element.setAttribute('href', res.data.downloadInvoiceDocuments.url);
                element.setAttribute('target', "_blank");
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            })
        }
    }

    function onClickView(orderId) {
        if (orderId == null) {
            message.error(`No order id found for this transaction. 未找到此交易的订单 ID `)
        } else {
            client.query({
                query: DownloadInvoiceDocument,
                fetchPolicy: 'network-only',
                variables: { orderId }

            }).then((res) => {
                setIsModalVisible(true)
                setPdfFile(res.data.downloadInvoiceDocument.url)
            })
        }
    }

    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        const roles = localStorage.getItem(ROLES)
        setRoles(roles)
        if (roles.includes('super_admin') || roles.includes('project_lead') || roles.includes('finance')) {
        } else {
            history.push('/inbox')
        }
        if (loading === false && data !== undefined) {
            rerenderListView(data.invoiceListingReport.edges)
            setTotalCount(data.invoiceListingReport.totalCount)
        }
    }, [data, history, loading, location])

    function rerenderListView(data) {
        var list = []
        for (var i = 0; i < data.length; i++) {
            list.push({
                'key': data[i].node.id,
                'date': data[i].node.dateCreated,
                'no': data[i].node.invoiceNo,
                'advertiser_name': data[i].node.advertiser?.legalName,
                'billing_name': data[i].node.billing?.name,
                'invoice_amount': data[i].node.preTaxedAmount,
                'tax': data[i].node.taxAmount,
                'invoice_amount_after_tax': data[i].node.paymentAmount,
                'agent_code': data[i].node.agent?.referralCode,
                'agent': data[i].node.agent?.user?.name,
                'agent_lead': data[i].node.agent?.parent?.user?.name,
                'order_id': data[i].node?.orderId,
            })
        }

        setReportList(list)
    }

    async function onFinish(values) {
        // if (values.agent !== undefined) setAgentName(values.agent)
        // if (values.agent_lead !== undefined) setAgentLeadName(values.agent_lead)
        if (values.date){
            setStartDate(values.date[0])
            setEndDate(values.date[1])
        } 
        // if (values.invoice !== undefined) setInvoiceNo(values.invoice)
        if (values.date) {
            setVariables({
                offSet: 0,
                // agentName: values.agent,
                // agentLeadName: values.agent_lead,
                // invoiceNo: values.invoice,
                startDate: values.date[0],
                endDate: values.date[1]
            })
        } else {
            setVariables({
                offSet: 0,
                // agentName: values.agent,
                // agentLeadName: values.agent_lead,
                // invoiceNo: values.invoice
            })

        }

        setCurrentPageNumber(1)
    };

    function onResetClicked() {
        form.resetFields();
        // setAgentName(null)
        // setAgentLeadName(null)
        setStartDate(null)
        setEndDate(null)
        // setInvoiceNo(null)
        setVariables({ offSet: 0 })
        setCurrentPageNumber(1)
    }

    async function downloadReportFunction() {
        client.query({
            query: DownloadInvoiceListingReport,
            variables: { startDate: startDate, endDate: endDate },
            // variables: { agentName: agentName, agentLeadName: agentLeadName, startDate: startDate, endDate: endDate, invoiceNo: invoiceNo },
            fetchPolicy: "network-only"
        }).then((res) => {
            console.log(res)
            let element = document.createElement('a');
            element.setAttribute('href', res.data.downloadInvoiceListingReport.url);
            element.setAttribute('target', "_blank");
            // element.setAttribute('download', 'output.xlsx');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }).catch((error) => {
            console.log(error)
        })
    }

    function onPaginationClicked(page) {
        setCurrentPageNumber(page);
        // setVariables({
        //     offSet: ((page - 1) * 5),
        //     agentName,
        //     agentLeadName,
        //     invoiceNo
        // })
        console.log('startDate', startDate)
        console.log('endDate', endDate)

        if (startDate !== null) {
            setVariables({
                offSet: ((page - 1) * 5),
                // agentName,
                // agentLeadName,
                // invoiceNo,
                startDate,
                endDate,
            })
        } else {
            setVariables({
                offSet: ((page - 1) * 5),
                // agentName,
                // agentLeadName,
                // invoiceNo,
            })
            console.log((page - 1) * 5)
        }
    }

    function onItemClick({ pageNumber: itemPageNumber }) {
        setPageNumber(itemPageNumber);

    }

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const rowSelection = {
        selectedRowKeys: listOfSelectedRows.map(selectedRow => { return selectedRow.key }),
        preserveSelectedRowKeys: true,
        type: 'checkbox',
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows)
            // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            if(selectedRows.length <= 50){
                setSelectedRows(selectedRows)
            }
            else{
                message.info("You can only select 50 documents!")
            }
            
            if (selectedRows.length > 0) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        },
      };

      function onClickDownloadZip() {
        client.query({
            query: DownloadInvoiceDocuments,
            fetchPolicy: 'network-only',
            variables: { orderIds: listOfSelectedRows.filter(selectedRow => selectedRow.order_id !== null ).map(({order_id}) => order_id) }

        }).then((res) => {
            console.log("testing")
            console.log(res)
            if(res.data.downloadInvoiceDocuments == null){
                message.error("Unable to generate invoice files, please ensure Invoice Number is valid!")
                return
            }
            let element = document.createElement('a');
            element.setAttribute('href', res.data.downloadInvoiceDocuments.url);
            element.setAttribute('target', "_blank");
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }).catch((res) =>{
            console.log(res)
            message.error("Error generating invoice documents!")
            return
        })
    }

    return (
        <Layout>
            <Modal title="" visible={isModalVisible} width="900px"

                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel} style={{ borderRadius: '4px' }}>
                        Back 返回
                    </Button>,

                ]}>

                <Document file={pdfFile}  onItemClick={onItemClick}>
                    <Page pageNumber={pageNumber || 1} />
                </Document>

            </Modal>

            <Space direction='vertical' className="commission_header">
                <Breadcrumb style={{ marginBottom: '16px' }}>
                    <Breadcrumb.Item>Homepage 首页</Breadcrumb.Item>
                    <Breadcrumb.Item>Invoice Listing</Breadcrumb.Item>
                </Breadcrumb>
                <h1>Invoice Listing</h1>
            </Space>
            <Layout style={{ margin: '16px 32px' }}>
                <Form className="filter-form" name="basic"
                    onFinish={onFinish}
                    form={form}
                    autoComplete="off">
                    <Row align="top" justify='space-between'>
                        <Col span={8}>
                            <Row>
                                <Col span={6}>
                                    <p>Date <br />日期</p>
                                </Col>
                                <Col span={16}>
                                    <ConfigProvider locale={locale}>
                                        <Form.Item name="date">
                                            <RangePicker />
                                        </Form.Item>
                                    </ConfigProvider>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8} style={{ textAlign: 'end' }}>
                            <Space direction="horizontal">
                                {
                                    roles.includes('project_lead') ? null : <Button onClick={() => downloadReportFunction()}>
                                        Export 汇出
                                    </Button>
                                }

                                <Button onClick={() => onResetClicked()}>
                                    Reset 重设
                                </Button>
                                <Button className="search-button" htmlType="submit">
                                    Search 搜寻
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
                <div style={{ background: 'white' }}>
                    <Row direction='horizontal' style={{ justifyContent: 'space-between', padding: '28px 28px 0 32px'}}>
                        <h1>Classified Ads 汽车广告</h1>
                        <Space size={12}>
                            <Button disabled={disabled} onClick={()=> onClickDownloadZip()}>Download 下载</Button>
                        </Space>
                    </Row>
                    <Table className="main-table" rowSelection={{ ...rowSelection, }} style={{ whiteSpace: "pre" }} columns={columns} dataSource={reportList} scroll={{ x: 2800 }}
                        pagination={{
                            showSizeChanger: false,
                            current: currentPageNumber, total: totalCount, pageSize: 5, onChange: (page, pageSize) => {
                                onPaginationClicked(page)
                            }
                        }}
                    />
                </div>
            </Layout>
        </Layout>
    )
}

export default InvoiceListing;