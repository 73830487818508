import React from 'react';
import { Col } from 'antd';
import ChartRenderer from '../components/ChartRenderer';
import Dashboard from '../components/Dashboard';
import DashboardItem from '../components/DashboardItem';
const DashboardItems = [
  {
    id: 0,
    name: 'New Chart',
    vizState: {
      query: {
        measures: ['Orders.totalAmount'],
        timeDimensions: [],
        order: {
          'Orders.totalAmount': 'desc',
        },
        filters: [],
        dimensions: ['Orders.status'],
        limit: 5000,
      },
      chartType: 'line',
    },
  },
  {
    id: 1,
    name: 'New Chart',
    vizState: {
      query: {
        measures: ['Orders.totalAmount'],
        timeDimensions: [],
        order: {
          'Orders.totalAmount': 'desc',
        },
        filters: [],
        dimensions: ['Orders.status'],
        limit: 5000,
      },
      chartType: 'number',
    },
  },
  {
    id: 2,
    name: 'New Chart',
    vizState: {
      query: {
        measures: ['Orders.totalAmount'],
        timeDimensions: [],
        order: {
          'Orders.totalAmount': 'desc',
        },
        filters: [],
        dimensions: ['Orders.status'],
        limit: 5000,
      },
      chartType: 'bar',
    },
  },
  {
    id: 3,
    name: 'New Chart',
    vizState: {
      query: {
        measures: ['Orders.totalAmount'],
        timeDimensions: [],
        order: {
          'Orders.totalAmount': 'desc',
        },
        filters: [],
        dimensions: ['Orders.status'],
        limit: 5000,
      },
      chartType: 'table',
    },
  },
  {
    id: 4,
    name: 'New Chart',
    vizState: {
      query: {
        measures: ['Orders.totalAmount'],
        timeDimensions: [],
        order: {
          'Orders.totalAmount': 'desc',
        },
        filters: [],
        dimensions: ['Orders.status'],
        limit: 5000,
      },
      chartType: 'area',
    },
  },
  {
    id: 5,
    name: 'New Chart',
    vizState: {
      query: {
        measures: ['Orders.totalAmount'],
        timeDimensions: [],
        order: {
          'Orders.totalAmount': 'desc',
        },
        filters: [],
        dimensions: ['Orders.status'],
        limit: 5000,
      },
      chartType: 'line',
    },
  },
  {
    id: 6,
    name: 'New Chart',
    vizState: {
      query: {
        measures: ['CmsPayment.paymentAmountAverage'],
        timeDimensions: [
          {
            dimension: 'CmsPayment.dateCreated',
          },
        ],
        order: {},
      },
      chartType: 'number',
    },
  },
  {
    id: 7,
    name: 'New Chart',
    vizState: {
      query: {
        measures: ['CmsPayment.paymentAmountAverage'],
        timeDimensions: [
          {
            dimension: 'CmsPayment.dateCreated',
            dateRange: 'Last 7 days',
          },
        ],
        order: {},
      },
      chartType: 'number',
    },
  },
  {
    id: 8,
    name: 'New Chart',
    vizState: {
      query: {
        measures: ['CmsPayment.paymentAmountAverage'],
        timeDimensions: [
          {
            dimension: 'CmsPayment.dateCreated',
            dateRange: 'Last 7 days',
          },
        ],
        order: [['CmsPayment.paymentAmountAverage', 'desc']],
      },
      chartType: 'number',
    },
  },
  {
    id: 9,
    name: 'New Chart',
    vizState: {
      query: {
        measures: ['CmsView.count'],
        timeDimensions: [
          {
            dimension: 'WagtailcorePage.goLiveAt',
            granularity: 'day',
          },
        ],
        order: [['CmsView.count', 'desc']],
        filters: [],
        limit: 5000,
      },
      chartType: 'area',
    },
  },
];

const DashboardPage = () => {
  const dashboardItem = (item) => (
    <Col
      span={24}
      lg={6}
      key={item.id}
      style={{
        marginBottom: '24px',
      }}
    >
      <DashboardItem>
        <ChartRenderer vizState={item.vizState} />
      </DashboardItem>
    </Col>
  );

  const Empty = () => (
    <div
      style={{
        textAlign: 'center',
        padding: 12,
      }}
    >
      <h2>
        There are no charts on this dashboard. Use Playground Build to add one.
      </h2>
    </div>
  );

  return DashboardItems.length ? (
    <Dashboard dashboardItems={DashboardItems}>
      {DashboardItems.map(dashboardItem)}
    </Dashboard>
  ) : (
    <Empty />
  );
};

export default DashboardPage;
