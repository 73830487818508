import { Layout, Divider, Space, Row, Button, Col, Avatar, Input, Upload, message, Spin } from "antd";
import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { MyAgentProfile, userProfile } from "../../../server/query";
import { UserOutlined, ExportOutlined } from '@ant-design/icons';
import './basic-setting.css'
import { UserUpdate } from "../../../server/mutation";
import FileBase64 from 'react-file-base64';
import { CopyToClipboard } from 'react-copy-to-clipboard';


const BasicSettingPage = () => {
    const { loading, error, data, refetch } = useQuery(userProfile)
    const { loading: agentProfileLoading, data: agentProfileData } = useQuery(MyAgentProfile)
    const [roles, setRoles] = useState([]);
    const [editUser] = useMutation(UserUpdate, {
        onCompleted(data) {
            if (data.updateUser.user == null) {
                message.error('Update user error.')
            } else {
                message.success('User updated successfully.')
                refetch()
            }

        }
    })
    const [mobile, setMobile] = useState('')
    const [fileList, setFileList] = useState([])
    const [referralCode, setReferralCode] = useState('')
    const [referralLink, setReferralLink] = useState('')
    const [secondaryID, setSecondaryId] = useState('')
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file! 你只能上传JPG或PNG的图像格式！');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB! 图像必须小于2MB！');
        }
        return isJpgOrPng && isLt2M && false;

    }

    useEffect(() => {
        if (loading === false) {
            setMobile(data.myUserProfile.phoneNumber)
            setRoles(data.myUserProfile.roles)
        }

        if (agentProfileLoading === false && agentProfileData?.myAgentProfile != null) {
            setReferralCode(agentProfileData?.myAgentProfile?.referralCode)
            setReferralLink(agentProfileData?.myAgentProfile?.referralLink)
            setSecondaryId(agentProfileData?.myAgentProfile?.secondaryId)
            console.log("myAgentProfile: ", agentProfileData?.myAgentProfile)
        }

    }, [agentProfileData, agentProfileLoading, data, loading]);


    function getFiles(files) {
        setFileList(files)
    }


    function submitButton() {
        console.log('fileList', fileList)


        editUser({ variables: { phoneNumber: mobile, } })

    }



    return (
        <Layout className='basic-setting-page'>
            <h1>Basic Setting 基本设定</h1>
            <Divider />
            {data != null ? <Row>
                <Col span={12}>
                    <Space direction="vertical" size='large'>
                        <Space direction='vertical' size='small'>
                            <h2>Name 姓名</h2>
                            <h3>{data.myUserProfile.name}</h3>
                        </Space>

                        <Space direction='vertical' size='small' style={{ width: '40vh' }} >
                            <h2>Mobile Number 手机号码</h2>
                            <Input value={mobile} onChange={(e) => setMobile(e.target.value)} />
                        </Space>
                        {/* <Space direction='vertical' size='small' style={{ width: '40vh' }}>
                            <h2>Region</h2>
                            <Input value='Selangor' />
                        </Space> */}

                        <Space direction='vertical' size='small'>
                            <h2>{roles.includes('agent_lead') ? "Agent Lead ID 代理主管编号" : roles.includes('agent') ? "Agent ID 代理编号" : roles.includes("operation") ? "Operation 系统管理员编号": roles.includes("finance") ? "Finance ID 财务管理员编号" : roles.includes("project_lead") ? "Project Lead ID 项目负责人编号" :null}</h2>
                            <h3>{secondaryID ? secondaryID : data.myUserProfile.regularId}</h3>
                            {/* <h3>{roles.includes('agent_lead') ? agentProfileData.myAgentProfile.id : data.myUserProfile.regularId}</h3> */}
                        </Space>
                        {referralLink === '' ? null : <Space direction='vertical' size='small'>
                            <h2>Agent Code Link 推荐识别代码链接</h2>
                            <h3>{referralLink} &nbsp;&nbsp;<CopyToClipboard text={referralLink}>
                                <ExportOutlined className="export-button" />
                            </CopyToClipboard></h3>
                        </Space>}
                        {referralCode === '' ? null : <Space direction='vertical' size='small'>
                            <h2>Agent Code 推荐识别代码</h2>
                            <h3>{referralCode} &nbsp;&nbsp;<CopyToClipboard text={referralCode}>
                                <ExportOutlined className="export-button" />
                            </CopyToClipboard></h3>
                        </Space>}


                        <Space direction='vertical' size='small'>
                            <h2>Email 电子邮件</h2>
                            <h3>{data.myUserProfile.email}</h3>
                        </Space>
                        <Button type="primary" className="submit-button" onClick={submitButton}>Save 储存</Button>
                    </Space>

                </Col>
                {/* <Col span={12} style={{ paddingTop: '40px' }}>
                    <Space direction='vertical' size="middle" style={{ alignSelf: "center" }}>
                        <Avatar size={144} icon={<UserOutlined />} />
                        <Upload
                            name="file"
                            maxCount={1}
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            onChange={(info) => {
                                console.log('file', info)
                                setFileList(info.fileList)
                                if (info.file.status === 'done') {
                                    console.log('file', info)


                                }
                            }}
                        >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                        <FileBase64
                            multiple={false}
                            onDone={getFiles.bind(this)} />
                    </Space>

                </Col> */}
            </Row>

                : <Spin size="large" />}


        </Layout>
    )

}

export default BasicSettingPage;