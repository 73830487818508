import { Layout, Space, Row, Button, Table, Modal, Input, message, Divider, Pagination } from "antd";
import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { CreateSuperAdmin, RetireInternalUser, UpdateSuperAdmin } from "../../../server/mutation";
import { SuperAdmins } from "../../../server/query";
import { ROLES } from '../../../server/constant'
import { useHistory } from "react-router-dom";
import './setting.css'

const SettingPage = () => {
    const [membersList, setMembersList] = useState([])
    const [memberTotal, setMemberTotal] = useState([])
    const [statusModal, setStatusModal] = useState('create')
    const [name, setName] = useState('')
    const [mobile, setMobile] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [ID, setID] = useState('')
    const [createModalVisible, setCreateModalVisible] = useState(false)


    const [AddSuperAdmin] = useMutation(CreateSuperAdmin, {
        onCompleted(data) {
            if (data.createSuperAdmin.ok == null) {
                message.error(data.createSuperAdmin.error);
            } else if (data.createSuperAdmin.ok === 'yes') {
                mutationSuccess('Super admin created.')
            }
        }

    })
    const [EditSuperAdmin] = useMutation(UpdateSuperAdmin, {
        onCompleted(data) {
            if (data.updateSuperAdmin.ok == null) {
                message.error(data.updateSuperAdmin.error);
            } else if (data.updateSuperAdmin.ok === 'yes') {
                mutationSuccess('Account updated')
            }
        }
    })
    const [DeleteSuperAdmin] = useMutation(RetireInternalUser, {
        onCompleted(data) {
            if (data.retireInternalUser.ok == null) {
                message.error(data.retireInternalUser.error);
            } else if (data.retireInternalUser.ok === 'yes') {
                mutationSuccess('Account deleted successfully.')
            }
        }

    })
    const { loading, error, data, refetch } = useQuery(SuperAdmins, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "no-cache",

    })
    const column = [
        {
            title: 'Name \n姓名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Mobile Number \n电话号码',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: '',
            dataIndex: ['name', 'phoneNumber', 'id', 'email'],
            key: 'action',
            render: (text, row) => (
                <Space className="Table-action" size={2} split={<Divider type="vertical" style={{ borderColor: '#D8D8D8' }} />}>
                    <Button type="text" style={{ color: '#C62828' }} onClick={() => { setCreateModalVisible(true); setName(row['name']); setID(row['id']); setMobile(row['phoneNumber']); setEmail(row['email']); setStatusModal('edit') }}>Edit 编辑</Button>
                    <Button type="text" style={{ color: '#C62828' }} onClick={() => showDeleteConfirm(row['id'], row['name'])}>Remove 删除</Button>
                </Space>
            ),
        },
    ]

    function mutationSuccess(text) {
        message.success(text);
        resetForm()
        refetch()
    }

    function resetForm() {
        setName('')
        setMobile('')
        setEmail('')
        setPassword('')
        setStatusModal('create')
        setCreateModalVisible(false)

    }

    const history = useHistory()

    useEffect(() => {
        const roles = localStorage.getItem(ROLES)


        if (roles.includes('super_admin') || roles.includes('project_lead') || roles.includes('operation')) {

        } else {
            history.push('/inbox')
        }

        if (loading === false && data != null) {
            rerenderListView(data.allSuperAdmins.edges)
            setMemberTotal(data.allSuperAdmins.totalCount)
        }
    }, [data, history, loading]);

    function rerenderListView(data) {
        var list = []
        for (var i = 0; i < data.length; i++) {
            list.push({
                'id': data[i].node.id,
                'name': data[i].node.name,
                'phoneNumber': data[i].node.phoneNumber,
                'email': data[i].node.email,
                'date': data[i].node.dateJoined,

            })
        }
        list.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
        });
        setMembersList(list)
    }

    const onPressedCreateButton = () => {
        console.log(name, mobile, email)
        if (statusModal === 'create') {
            AddSuperAdmin({ variables: { name: name, phoneNumber: mobile, email: email, password: password } })
        }
        if (statusModal === 'edit') {
            EditSuperAdmin({ variables: { id: ID, name: name, phoneNumber: mobile } })
        }
    }

    const onPressedCancelButton = () => {
        resetForm()
    }

    const onChangeModalInput = (e, type) => {
        if (type === 'name') { setName(e.target.value) }
        if (type === 'mobile') { setMobile(e.target.value) }
        if (type === 'email') { setEmail(e.target.value) }
        if (type === 'password') { setPassword(e.target.value) }
    }

    const { confirm } = Modal;
    function showDeleteConfirm(id, name) {

        confirm({
            title: `Are you sure to remove ${name}? 是否要删除 ${name} ？`,

            okText: 'Yes 是',
            okType: 'danger',
            cancelText: 'No 否',
            onOk() {
                DeleteSuperAdmin({ variables: { id: id } })
            },
            onCancel() {
            },
        });
    }


    return (
        <Layout className="finance-team">
            <Modal title={statusModal === 'create' ? "新增总管理员" : "编辑总管理员"} visible={createModalVisible} onOk={onPressedCreateButton} onCancel={onPressedCancelButton} className="create-modal" footer={[
                <Button key="back" onClick={onPressedCancelButton} style={{ borderRadius: '4px' }}>
                    Back 返回
                </Button>,
                <Button key="submit" type="primary" onClick={onPressedCreateButton} className="submit-button">
                    {statusModal === 'create' ? "Add New 新增" : 'Edit 编辑'}
                </Button>,
            ]}>
                <Layout>
                    <Space direction='vertical' style={{ background: 'white' }} size="large">
                        <div>
                            <h1>Name 姓名</h1>
                            <Input placeholder="Name 姓名" onChange={(e) => onChangeModalInput(e, 'name')} value={name} />
                        </div>
                        <div>
                            <h1>Mobile Number 手机号码</h1>
                            <Input placeholder="Mobile Number 手机号码" type="number" onChange={(e) => onChangeModalInput(e, 'mobile')} value={mobile} />
                        </div>
                        {statusModal === 'create' ? <div>
                            <h1>Email 电子邮件</h1>
                            <Input placeholder="Email 电子邮件" onChange={(e) => onChangeModalInput(e, 'email')} value={email} />
                        </div>
                            : <div>
                                <h1>Email 电子邮件</h1>
                                <Input placeholder="Email 电子邮件" disabled style={{ color: 'black', background: 'white', cursor: 'auto' }} onChange={(e) => onChangeModalInput(e, 'email')} value={email} />
                            </div>}

                        {statusModal === 'create' ? <div>
                            <h1>Password 密码</h1>
                            <Input placeholder="Password 密码" onChange={(e) => onChangeModalInput(e, 'password')} value={password} />
                        </div> : null}

                    </Space>
                </Layout>
            </Modal>
            <Row justify="space-between" align='top'>
                <Space direction='horizontal' align='baseline'>
                    <h1>Super Admin 总管理员</h1>
                    <h2> {memberTotal} members 成员</h2>
                </Space>
                <Button style={{minWidth: '120px'}} className="add-button" onClick={() => setCreateModalVisible(true)}>Add New 新增</Button>
            </Row>

            <Table dataSource={membersList} style={{whiteSpace: "pre"}} columns={column} key={membersList} />
        </Layout>
    )
}

export default SettingPage;