import { Layout, Divider, Space, message, Input, Button, Modal, Row, Col, Form, Pagination, Select, ConfigProvider } from "antd";
import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import client from "../../server/apollo_config";
import { ResetPassword, ChangePassword } from "../../server/mutation";
import { ResetPasswordVerificationCode, userProfile } from "../../server/query";



const ChangePasswordPage = () => {
    const [form] = Form.useForm();
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [secondPass, setSecondPass] = useState(false)
    const [verificationCode, setVerificationCode] = useState('')
    const [username, setUsername] = useState('')
    const { loading, error, data } = useQuery(userProfile)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [resetPassword] = useMutation(ResetPassword, {
        onCompleted(data) {
            setIsModalVisible(false);
            message.success('Password has been reset. Please login to continue.').then((res) => {
                localStorage.clear()
                window.location.reload(true);
            })

        },
        onError(error) {

            // if (err.includes('Invalid verification code provided') === true) {
            //     console.log('true')
            //     message.error('Invalid verification code provided')
            // }
        }

    })

    const [changePassword] = useMutation(ChangePassword, {
        onCompleted(data) {
            // setIsModalVisible(false);
            message.success('Password has been changed. Please login to continue.').then((res) => {
                localStorage.clear()
                window.location.reload(true);
            })

        },
        onError(error) {

            var err = JSON.stringify(error)
            console.log(err)


            
            var err = JSON.stringify(error)
            console.log("error")

            if (err.includes('Incorrect username or password') === true) {
                console.log('true')
                message.error('Incorrect username or password')
            }
        }

    })

    function onMessageClose() {

    }

    useEffect(() => {
        if (loading === false) {
            setUsername(data.myUserProfile['email'])

        }
    }, [loading, data])

    const onFinish = (values) => {
        let oldPassword = values.oldPassword;
        let newPassword = values.newPassword;
        setOldPassword(oldPassword);
        setNewPassword(newPassword);
        changePassword({ variables: { oldPassword, newPassword } })
    }


    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleOk = () => {
        console.log("hi")
        // changePassword({ variables: { oldPassword, newPassword } })
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    return (
        <Layout className='basic-setting-page'>

            <Modal title="Verification Code" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={[
                <Button key="back" onClick={handleCancel} style={{ borderRadius: '4px' }}>
                    Back 返回
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk} className="submit-button">
                    Submit 提交
                </Button>,
            ]}>

                <Space direction='vertical' size='large'>
                    <h1>A confirmation code has been sent to the registered email. Please enter the code to continue</h1>
                    <Space direction='vertical' size='small' style={{ width: '40vh' }} >
                        <h2>Verification Code 验证码</h2>
                        <Input onChange={(e) => setVerificationCode(e.target.value)} />
                    </Space>
                </Space>

            </Modal>
            <h1>Change Password 更改密码</h1>
            <Divider />

            <Form
                name="basic"
                form={form}
                layout='vertical'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Col span={10}>
                    <Space direction='vertical' size="small" style={{ width: '300px' }}>
                    <Form.Item
                            name="oldPassword"
                            label="Original Password 原有密码"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                }, {
                                    // type: 'regexp',
                                    message: "Password not match pattern",
                                    pattern: new RegExp(
                                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
                                    ),

                                }
                            ]}
                            hasFeedback
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            name="newPassword"
                            label="New Password 新密码"
                            extra="Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character. 密码长度至少为8个字符（推荐12个以上），需包含大小写英文字母、数字、特殊符号"

                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                }, {
                                    // type: 'regexp',
                                    message: "Password not match pattern",
                                    pattern: new RegExp(
                                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
                                    ),

                                }
                            ]}
                            hasFeedback
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            name="confirm"
                            label="Confirm New Password 确认新密码"
                            dependencies={['newPassword']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="submit-button">
                                Confirm 确认
                            </Button>
                        </Form.Item>
                    </Space>
                </Col>
            </Form>
        </Layout>
    )

}



export default ChangePasswordPage;