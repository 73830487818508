import { Layout, Space, Breadcrumb, Form, Input, Button, DatePicker, Row, Col, Table, Pagination, ConfigProvider } from "antd";
import React, { useState, useEffect } from 'react';
import { DownloadRevenueReport, RevenueReport } from "../../../server/query";
import client from '../../../server/apollo_config'
import { useQuery } from 'react-apollo';
import { useHistory, useLocation } from "react-router-dom";
import { ROLES } from '../../../server/constant'
import PermissionCheck from "../../../components/permission_check";
import './revenue-report.css'
import Moment from 'moment';
import locale from 'antd/lib/locale/zh_CN';

const { RangePicker } = DatePicker;

const RevenueReportPage = () => {
    const [form] = Form.useForm();
    const [reportList, setReportList] = useState([])
    const [variables, setVariables] = useState({ offSet: 0 })
    const [totalCount, setTotalCount] = useState(0)
    const [roles, setRoles] = useState('')
    const [agentName, setAgentName] = useState(null)
    const [agentLeadName, setAgentLeadName] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [billingName, setBillingName] = useState(null)
    const { loading, data } = useQuery(RevenueReport, { variables })
    const columns = [
        {
            title: 'Post ID \n贴文编号',
            dataIndex: 'post_id',
            key: 'post_id',
        },
        {
            title: 'Published Date \n刊登日期',
            dataIndex: 'published_date',
            key: 'published_date',
            render: (text, row) => row['published_date'] !== '' ? (Moment(row['published_date']).format('DD/MM/YYYY')) : ''
        },
        {
            title: 'Invoice Date \n收据日期',
            dataIndex: 'transaction_date',
            key: 'transaction_date',
            render: (text, row) => row['transaction_date'] !== '' ? (Moment(row['transaction_date']).format('DD/MM/YYYY')) : ''
        },
        {
            title: 'Invoice Number \n收据编号',
            dataIndex: 'invoice_no',
            key: 'invoice_no',
        },
        {
            title: 'Platform \n平台',
            dataIndex: 'platform',
            key: 'platform',
        },
        {
            title: 'Description \n内容',
            dataIndex: 'description',
            key: 'description',
        },

        {
            title: 'Promo Code \n促销代码',
            dataIndex: 'promo_code',
            key: 'promo_code',
        },
        {
            title: 'Billing Name \n付款人姓名',
            dataIndex: 'billing_name',
            key: 'billing_name',
        },

        {
            title: 'Amount Per Invoice \n每张收据金额',
            dataIndex: 'amount_per_invoice',
            key: 'amount_per_invoice',
        },
        {
            title: 'Agent Name \n代理姓名',
            dataIndex: 'agent',
            key: 'agent',
        },
        {
            title: 'Agent ID \n代理编号',
            dataIndex: 'agent_code',
            key: 'agent_code',
        },
        {
            title: 'Agent Lead Name \n代理主管姓名',
            dataIndex: 'agent_lead',
            key: 'agent_lead',
        },
        {
            title: 'Agent Lead ID \n代理主管编号',
            dataIndex: 'agent_lead_code',
            key: 'agent_lead_code',
        },
        {
            title: 'Revenue Recognized \n收入确认',
            dataIndex: 'revenue_recognized',
            key: 'revenue_recognized',
            fixed: 'right',
            width: 150
        },

    ]

    const location = useLocation();
    const listOfViewOnlyRoles = ['operation', 'project_lead']

    const history = useHistory()
    useEffect(() => {
        const roles = localStorage.getItem(ROLES)
        setRoles(roles)

        const isAllowedRole = ['super_admin', 'project_lead', 'finance', 'operation'].includes(roles)

        if (isAllowedRole === false) {
            history.push('/inbox')
            return
        } 


        if (loading === false && data !== undefined) {
            rerenderListView(data.revenueReport.edges)
            setTotalCount(data.revenueReport.totalCount)
        }
    }, [data, loading]);

    function rerenderListView(data) {
        var list = []

        for (var i = 0; i < data.length; i++) {
            console.log(data[i].node.listing)
            console.log(data[i].node.listing?.firstPublishedAt)
            list.push({
                'post_id': data[i].node.listing?.postId,
                'published_date': data[i].node.listing?.firstPublishedAt,
                'transaction_date': data[i].node.invoiceDate,
                'invoice_no': data[i].node.invoiceNo,
                'platform': data[i].node.platform,
                'description': data[i].node.description,
                'promo_code': data[i].node.promoCode,
                'billing_name': data[i].node.billing?.name,
                'amount_per_invoice': data[i].node.packagePrice,
                'agent_code': data[i].node.agent?.referralCode,
                'agent': data[i].node.agent?.user?.name,
                'agent_lead': data[i].node.agentLead?.user?.name,
                'agent_lead_code': data[i].node.agentLead?.referralCode,
                'revenue_recognized': data[i].node.revenue
            })
        }

        console.log(list)

        setReportList(list)
    }

    async function downloadReportFunction() {
        client.query({
            query: DownloadRevenueReport,
            variables: { agentName: agentName, agentLeadName: agentLeadName, startDate: startDate, endDate: endDate, billingName: billingName },
            fetchPolicy: "network-only"
        }).then((res) => {
            let element = document.createElement('a');
            element.setAttribute('href', res.data.downloadRevenueReport.url);
            element.setAttribute('target', "_blank");
            // element.setAttribute('download', 'output.xlsx');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        })
    }

    const onFinish = (values) => {
        console.log('Success:', values);
        if (values.agent !== undefined) setAgentName(values.agent)
        if (values.agent_lead !== undefined) setAgentLeadName(values.agent_lead)
        if (values.date !== undefined) setStartDate(values.date[0])
        if (values.date !== undefined) setEndDate(values.date[1])
        if (values.billing_name !== undefined) setBillingName(values.billing_name)

        if (values.date !== undefined) {
            setVariables({
                agentName: values.agent,
                agentLeadName: values.agent_lead,
                startDate: values.date[0],
                endDate: values.date[1],
                billingName: values.billing_name
            })
        } else {
            setVariables({
                agentName: values.agent,
                agentLeadName: values.agent_lead,
                billingName: values.billing_name
            })
        }


    };

    function onResetClicked() {
        form.resetFields();
        setVariables({ offSet: 0 })
        setAgentName(null)
        setAgentLeadName(null)
        setStartDate(null)
        setEndDate(null)
        setBillingName(null)

    }

    function onPaginationClicked(page) {
        setCurrentPageNumber(page);
        if (startDate !== "") {
            setVariables({
                offSet: ((page - 1) * 5),
                agentName,
                agentLeadName,
                billingName,
                startDate,
                endDate,
            })
        } else {
            setVariables({
                offSet: ((page - 1) * 5),
                agentName,
                agentLeadName,
                billingName,
            })

        }
    }

    return (
        <Layout>
            <Space direction='vertical' className="header">
                <Breadcrumb style={{ marginBottom: '16px' }}>
                    <Breadcrumb.Item>Homepage 首页</Breadcrumb.Item>
                    <Breadcrumb.Item>Revenue Report 营收报告 </Breadcrumb.Item>
                </Breadcrumb>
                <h1>Revenue Report 营收报告 </h1>
            </Space>
            <Layout style={{ margin: '16px 32px' }}>

                <Form className="filter-form" name="basic"
                    onFinish={onFinish}
                    form={form}
                    autoComplete="off">
                    <Row align="top" justify='space-between'>
                        <Col span={8}>
                            <Row>
                                <Col span={6}>
                                    <p>Date <br />日期</p>
                                </Col>
                                <Col span={16}>
                                    <ConfigProvider locale={locale}>
                                        <Form.Item name="date">
                                            <RangePicker />
                                        </Form.Item>
                                    </ConfigProvider>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row>
                                <Col span={8}>
                                    <p>Billing Name <br />付款人姓名</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="billing_name">
                                        <Input placeholder="Fill by Billing Name" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8} style={{ textAlign: 'end' }}>
                            <Space direction="horizontal">
                                {
                                    listOfViewOnlyRoles.includes(roles) ? null : <Button onClick={() => downloadReportFunction()}>
                                        Export 汇出
                                    </Button>
                                }

                                <Button onClick={() => onResetClicked()}>
                                    Reset 重设
                                </Button>
                                <Button className="search-button" htmlType="submit">
                                    Search 搜寻
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                    <Row align="top" >
                        <Col span={8}>
                            <Row>
                                <Col span={6}>
                                    <p>Agent Name <br />代理姓名</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="agent">
                                        <Input placeholder="Fill by Agent Name 筛选代理" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row>
                                <Col span={8}>
                                    <p>Agent Lead Name <br />代理主管姓名</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="agent_lead">
                                        <Input placeholder="Filter by Agent Lead Name 筛选代理主管" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>


                <div style={{ background: 'white' }}>
                    <Table className="main-table" style={{ whiteSpace: "pre" }} columns={columns} dataSource={reportList} scroll={{ x: 2000, y: 300 }}
                        pagination={{
                            showSizeChanger: false,
                            current: currentPageNumber, total: totalCount, pageSize: 5, onChange: (page, pageSize) => {
                                onPaginationClicked(page)
                            }
                        }}
                    />
                </div>
            </Layout>
        </Layout>
    )
}

export default RevenueReportPage;