import { Layout, Row, Input, Radio, Space, Table, Button, Badge, Modal, Form, message, Col, Upload, Image as ImageComponent } from "antd";
import React, { useState, useEffect } from 'react';
import { CopyOutlined, ShareAltOutlined, UploadOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useQuery, useMutation } from 'react-apollo';
import PermissionCheck from "../../../components/permission_check";
import { ROLES } from '../../../server/constant'
import { useHistory, useLocation } from "react-router-dom";
import client from '../../../server/apollo_config'
import './facebook-pin.css'
import { AdsPackages, AllFbPins, DownloadFacebookPinPostReport } from "../../../server/query";
import { UpdateAddOnPin, UploadFacebookPinPhoto, RemoveFacebookPinPhoto } from "../../../server/mutation";
import { Auth } from 'aws-amplify';
import Moment from 'react-moment';
import moment from 'moment';
import S3 from 'aws-s3';
import { v4 as uuidv4 } from 'uuid';

const FacebookPinPage = () => {
    const [form] = Form.useForm();
    const { Search } = Input;
    const { confirm } = Modal;
    const [facebookFilter, setFacebookFilter] = useState('all')
    const [adsList, getAdsList] = useState()
    const [roles, setRoles] = useState('')
    const [id, setId] = useState('')
    const [totalCount, setTotalCount] = useState(0)
    const [offSet, setOffSet] = useState(0);
    const [title, setTitle] = useState("");
    const [postId, setPostId] = useState("");
    const [packageId, setPackageId] = useState("");
    const [advertiser, setAdvertiser] = useState("");
    const [agent, setAgent] = useState("");
    const [visible, setVisible] = useState(false);
    // const [imageVisible, setImageVisible] = useState(false);
    const [imageProof, setImageProof] = useState(null);
    const [variables, setVariables] = useState({ offSet, title, packageId, postId, advertiser, agent })
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const { loading, error, data, refetch } = useQuery(AllFbPins, { variables })
    const [UpdatePin] = useMutation(UpdateAddOnPin, {
        onCompleted(data) {
            if (data.updateAddOnPin == null) {
                message.error(data.updateAddOnPin.error)
            } else {
                message.success('Pin updated.')

                refetch()
            }
        }
    })

    const [fileMap, setFileMap] = useState({})
    const [UploadFBImage] = useMutation(UploadFacebookPinPhoto)
    const [RemoveFBImage] = useMutation(RemoveFacebookPinPhoto)

    const location = useLocation()
    const history = useHistory()

    useEffect(() => {

        const roles = localStorage.getItem(ROLES)
        setRoles(roles)

        if (roles.includes('super_admin') || roles.includes('project_lead') || roles.includes('operation')) {

        } else {
            history.push('/inbox')
        }
        if (loading === false && data !== undefined) {
            rerenderListView(data.allFbPins.edges)
            setTotalCount(data.allFbPins.totalCount)
        }
    }, [data, history, loading, location])

    function rerenderListView(data) {
        var list = []
        for (let i = 0; i < data.length; i++) {
            list.push({
                'title': data[i].node.listing?.title,
                'link': data[i].node.fbPostLink,
                'addOn': data[i].node.listing?.addOns,
                'adsPackageId': data[i].node?.listing?.adsPackage?.regularId,
                'adsPackage': data[i].node.listing?.adsPackage?.name,
                'regional': data[i].node.region?.name,
                'pending': data[i].node.pending,
                'active': data[i].node.active,
                'expired': data[i].node.expired,
                'owner': data[i].node.listing?.owner,
                'date': data[i].node.dateStarted,
                'regular_id': data[i].node?.listing?.postId,
                'id': data[i].node.id,
                'agent': data[i].node.listing?.agent?.user?.name,
                'agentLead': data[i].node.listing?.agent?.parent?.user?.name,
                'image': data[i].node.image,
            })

            setFileMap(prevObj =>{
                const row = list[i]
                const { id, image } = row
                const uploadFileList = []
                if(image != null){
                    const { width, height, file, id: uid, originalUrl: url } = image
                    const nameWithExt = file.split("/")
                    const nameWithoutExt = nameWithExt[1].split(".")
                    const name = nameWithoutExt[0]
    
                    uploadFileList.push({
                        uid,
                        width,
                        height,
                        name,
                        status: "done",
                        url
                    })
                }
    
                return {
                    ...prevObj,
                    [id]: uploadFileList
                }
            })
        }

        list.sort(function (a, b) {
            // return new Date(b.date) - new Date(a.date);
            return b.regular_id - a.regular_id;
        });
        getAdsList(list)
    }

    async function downloadReportFunction() {
        client.query({
            query: DownloadFacebookPinPostReport,
            variables: { postId: postId, packageId: packageId, advertiser: advertiser, agent: agent },
            fetchPolicy: "network-only"
        }).then((res) => {
            console.log(res)
            let element = document.createElement('a');
            element.setAttribute('href', res.data.downloadFacebookPinPostReport.url);
            element.setAttribute('target', "_blank");
            // element.setAttribute('download', 'output.xlsx');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }).catch((error) => {
            console.log(error)
        })
    }

    const customRequest = ({ file, onSuccess, onError }, row) =>{

        // less than 10kb or more than 10mb
        if (file.size < 10240 || file.size > 10485760) {
            message.error("抱歉！图片大小只限于10KB至10MB");
            return ;
        }

        const config = {
            bucketName: process.env.REACT_APP_PUBLIC_S3_NAME,
            region: process.env.REACT_APP_PUBLIC_S3_REGION,
            dirName: `media/original_images`,
            accessKeyId: process.env.REACT_APP_PUBLIC_S3_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_PUBLIC_S3_SECRET_KEY,
        }
        const S3Client = new S3(config)
        const uuid = uuidv4() //as filename
        S3Client.uploadFile(file, uuid)
        .then(data => {
            const img = new Image()
            img.onload = () =>{
                UploadFBImage({ variables: { 
                    id: row.id, 
                    imageObject: {
                        url: data.location, 
                        fileName: uuid, 
                        width: img.width, 
                        height: img.height,
                    } 
                }})
                .then((res) =>{
                    const { ok, error } = res.data.updateAddOnPin

                    if(error){
                        message.error(error)
                        onError(file)
                        return
                    }
                    if(ok){
                        setFileMap(prevObj =>{
                            return{
                                ...prevObj,
                                [row.id]:[{
                                    uid: file.uid,
                                    width: img.width, 
                                    height: img.height,
                                    name: uuid,
                                    status: "done",
                                    url: data.location
                                }]
                            }
                        })
                        onSuccess(file)
                    }
                    onSuccess(file)
                    refetch();
                })
                .catch((res)=>{
                    onError(file)
                    message.error(res)
                    console.log("Failed! ", res)
                })
            }

            img.src = data.location
        })
        .catch(err => console.error(err))
    }

    const onRemove = (file, row) =>{
        RemoveFBImage({ variables: { 
            id: row.id, 
        }})
        .then((res) =>{
            const { ok, error } = res.data.updateAddOnPin

            if(error){
                message.error(error)
                return
            }
            if(ok){
                setFileMap(prevObj =>{
                    return{
                        ...prevObj,
                        [row.id]:[]
                    }
                })
            }
        })
        .catch((res)=>{
            message.error(res)
            console.log("Failed! ", res)
        })
        // const config = {
        //     bucketName: process.env.REACT_APP_PUBLIC_S3_NAME,
        //     region: process.env.REACT_APP_PUBLIC_S3_REGION,
        //     dirName: `media/original_images`,
        //     accessKeyId: process.env.REACT_APP_PUBLIC_S3_ACCESS_KEY,
        //     secretAccessKey: process.env.REACT_APP_PUBLIC_S3_SECRET_KEY,
        // }
        // const S3Client = new S3(config)
        // S3Client.deleteFile(`${file.name}.png`)
        // .then(() => {
        //     setFileMap(prevObj =>{
        //         delete prevObj[row.id]
        //         return {...prevObj}
        //     })
        // })
        // .catch(err => console.error(err))
    }

    const showUserName = (firstName, lastName) =>{
        if(firstName){
            if(lastName){
                return `${firstName} ${lastName}`
            }

            return `${firstName}`
        }

        return ""
    }


    const columns = [
        {
            title: "ID \n编号",
            dataIndex: 'regular_id',
            key: 'regular_id',
        },
        {
            title: 'Title \n标题',
            dataIndex: ['title', 'link', 'regional', 'pending'],
            key: 'title',
            render: (text, row) => <Space direction='vertical' size={0}>
                <h2>{row['title']}<span>&nbsp;(Regional:&nbsp;{row['regional']})</span>&nbsp;&nbsp;
                    {row['pending'] === true ? null : <CopyToClipboard text={row['link']}>
                        <CopyOutlined className="export-button" />
                    </CopyToClipboard>}
                </h2>
                {row['pending'] === true ? null : <h3>{row['link']}</h3>}

            </Space>,
        },
        {
            title: 'Advertiser \n广告商',
            dataIndex: 'owner',
            key: 'owner',
            render: (text) => {
                const { firstName, lastName } = text
                return(
                    <Space direction='vertical' size={0}>
                        <h4>{showUserName(firstName, lastName)}</h4>
                    </Space>
                )
            },
        },
        {
            title: "Agent \n代理",
            dataIndex: 'agent',
            key: 'agent',
            render: (text) => <div> {text != null ? <div>{text}</div> : <div>-</div>}</div>
        },
        {
            title: 'Agent Lead \n代理主管',
            dataIndex: 'agentLead',
            key: "agentLead",
        },
        {
            title: "Start Date \n开始日期",
            dataIndex: 'date',
            key: 'date',
            render: text =>
                <div>   {text
                    ? <Moment format="YYYY-MM-DD">
                        {text}
                    </Moment>
                    : <span>-</span>}</div>

            ,

        },
        {
            title: "Start Time \n开始时间",
            dataIndex: 'date',
            key: 'date',
            render: text =>
                <div>   {text
                    ? <Moment format="LT">
                        {text}
                    </Moment>
                    : <span>-</span>}</div>

            ,

        },
        {
            title: "End Date \n结束日期",
            dataIndex: 'date',
            key: 'date',
            render: text =>
                <div>   {text
                    ? <Moment add={{ days: 3, }} format="DD/MM/YYYY" style={{ color: 'rgba(0, 0, 0, 0.45)' }}>{text}</Moment>
                    : <span>-</span>}</div>
            ,

        },
        {
            title: 'Status \n状态',
            dataIndex: ['active', 'pending', 'expired'],
            key: 'status',
            render: (text, row) => statusWidget(row['pending'], row['active'], row['expired']),
        },
        {
            title: 'Add-Ons \n附加项目',
            dataIndex: 'addOn',
            key: 'addOn',
            render: (text, row) => <div> {text != null ? <div>{formatAddOns(text, row)}</div> : <div>-</div>}</div>,
            width: '200px',
        },
        {
            title: 'Ads Package ID \n广告配套编号',
            dataIndex: 'adsPackageId',
            key: 'adsPackageId',
        },
        {
            title: 'Package \n配套',
            dataIndex: 'adsPackage',
            key: 'adsPackage',
            render: (text) => <div> {text != null ? <div>{text}</div> : <div>-</div>}</div>
        },
        {
            title: '',
            dataIndex: ['pending', 'expired', 'id', 'title'],
            key: 'action',
            render: (text, row) => (
                <Space size="middle" className="Table-action">
                    {row['expired'] === true ? null : <div> {row['pending'] === true ? <Button type="text" style={{ color: '#C62828' }} onClick={() => showDeleteConfirm(row['id'], row['title'])}>Pin 置顶</Button> : null}</div>}
                </Space>
            ),
        },
        // {
        //     title: '',
        //     dataIndex: 'image',
        //     key: 'image',
        //     render: (text, row) => (
        //         <Space size="middle" className="Table-action">
        //             {text?.originalUrl ? <Button onClick={() => {
        //                 setImageProof(text.originalUrl);
        //                 setVisible(true);
        //                 // setImageVisible(true);
        //             }}>View 看法</Button> : null}
        //         </Space>
        //     ),
        // },
        {
            title: '',
            dataIndex: ['pending', 'expired', 'id', 'title'],
            key: 'upload',
            render: (text, row) => (
                <div style={{ width:"180px"}}>
                    <Upload
                        accept='.jpg,.jpeg,.png'
                        fileList={fileMap[row.id]}
                        customRequest={(props) =>customRequest({ ...props }, row)}
                        onRemove={(file) =>onRemove(file, row)}
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Upload / 上传</Button>
                    </Upload>
                </div>
            ),
        },
    ];

    const viewOnlyColumns = [
        {
            title: "ID \n编号",
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Title \n标题',
            dataIndex: ['title', 'link', 'regional', 'pending'],
            key: 'title',
            render: (text, row) => <Space direction='vertical' size={0}>
                <h2>{row['title']}<span>&nbsp;(Regional:&nbsp;{row['regional']})</span>&nbsp;&nbsp;
                    {row['pending'] === true ? null : <CopyToClipboard text={row['link']}>
                        <ShareAltOutlined className="export-button" />
                    </CopyToClipboard>}
                </h2>
                {row['pending'] === true ? null : <h3>{row['link']}</h3>}

            </Space>,
        },
        {
            title: 'Advertiser \n广告商',
            dataIndex: 'owner',
            key: 'owner',
            render: text => <Space direction='vertical' size={0}>
                <h4>Advertiser 广告商</h4>
                <h4>{text}</h4>
            </Space>,
        },
        {
            title: "Agent \n代理",
            dataIndex: 'agent',
            key: 'agent',
            render: (text) => <div> {text != null ? <div>{text}</div> : <div>-</div>}</div>
        },
        {
            title: "Date \n日期",
            dataIndex: 'date',
            key: 'date',
            render: text => <Space direction='vertical' size={0}>
                <h4>Start Date 开始时间</h4>
                <h4>{moment(text).format('DD/MM/YYYY')}</h4>
            </Space>,
        },
        {
            title: 'Status \n状态',
            dataIndex: ['active', 'pending', 'expired'],
            key: 'status',
            render: (text, row) => statusWidget(row['pending'], row['active'], row['expired']),
        },
        {
            title: 'Add-Ons 附加项目',
            dataIndex: 'addOn',
            key: 'addOn',
            render: (text, row) => <div> {text != null ? <div>{formatAddOns(text, row)}</div> : <div>-</div>}</div>
        },
        {
            title: 'Ads Package \n广告配套',
            dataIndex: 'adsPackage',
            key: 'adsPackage',
            render: (text) => <div> {text != null ? <div>{text}</div> : <div>-</div>}</div>
        },

    ];

    function statusWidget(pending, active, expired) {

        var color;
        var text;
        if (pending === true) {
            color = "#C62828"
            text = 'Pending 待定'

        }
        if (active === true) {
            color = "#52C41A"
            text = "Pinned 已置顶"
        }

        if (expired === true) {
            color = "#000000"
            text = "Expired 逾期"
        }
        return <Badge color={color} text={text} style={{ textTransform: "capitalize" }} />
    }


    const handleSizeChange = e => {
        setFacebookFilter(e.target.value)
        const filter = e.target.value
        if (filter === 'pending') {
            setVariables({ pending: true, offSet, title, postId, packageId, agent, advertiser })
        }
        if (filter === 'listing') {
            setVariables({ active: true, offSet, title, postId, packageId, agent, advertiser })
        }
        if (filter === 'expired') {
            setVariables({ expired: true, offSet, title, postId, packageId, agent, advertiser })
        }
        if (filter === 'all') {
            setVariables({ offSet, title, postId, packageId, agent, advertiser })
        }
    };

    const onSearch = (value) => {
        var search = value
        const result = adsList.filter(item => item.title?.includes(search))
        getAdsList(result)

        if (search === '') { rerenderListView(data.allFbPins.edges) }
    }

    function showDeleteConfirm(id, title) {

        confirm({
            title: `Are you sure to complete the facebook pin of ${title}? 是否确认要将 ${title} 置顶于粉丝专页？`,

            okText: 'Yes 是',
            okType: 'danger',
            cancelText: 'No 否',
            onOk() {
                UpdatePin({ variables: { id: id, pin: true } })
            },
            onCancel() {
            },
        });
    }

    function formatAddOns(addOns, fbPostObj ) {
        const { regional } = fbPostObj
        const formattedRegionalStr = regional.replace("_", " ").toLowerCase()
 
        return addOns.filter(addOn =>{
            return addOn.match(new RegExp(formattedRegionalStr, 'gi')) && addOn.match(new RegExp('facebook pin', 'gi'))
        }).join("\n")

        // return addOns.map(addon => addon.replace("Fb", "Facebook")).join(", ")
    }

    function onFinishFilter(values) {
        console.log('values', values)
        setTitle(values.title)
        setPostId(values.post_id)
        setPackageId(values.package_id)
        setAgent(values.agent)
        setAdvertiser(values.advertiser)

        setVariables({
            offset: 0, title: values.title, postId: values.post_id, packageId: values.package_id, agent: values.agent, advertiser: values.advertiser
        })

    }

    function onResetClicked() {
        form.resetFields();
        setTitle('')
        setPostId('')
        setPackageId('')
        setAgent('')
        setAdvertiser('')
        setVariables({ offSet: 0 })
    }


    return (
        <Layout style={{ margin: '16px 32px' }}>
            <ImageComponent.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis) }} >
                <ImageComponent
                    width={200}
                    style={{ display: 'none' }}
                    src={imageProof}
                    preview={{
                        visible,
                        src: imageProof,
                        onVisibleChange: value => {
                            setVisible(value);
                        },
                    }}
                />
            </ImageComponent.PreviewGroup>

            <Form className="filter-form" name="basic" onFinish={onFinishFilter}
                form={form}
                autoComplete="off">
                <Row align="top" justify='space-between'>
                    <Col span={8}>
                        <Row>
                            <Col span={6}>
                                <p>Ads Package ID <br />广告配套编号</p>
                            </Col>
                            <Col span={16}>
                                <Form.Item name="package_id">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8}>
                        <Row>
                            <Col span={8}>
                                <p>Post ID <br />贴文编号</p>
                            </Col>
                            <Col span={16}>
                                <Form.Item name="post_id">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8} style={{ textAlign: 'end' }}>
                        <Space direction="horizontal">
                            <Button onClick={() => downloadReportFunction()}>
                                Export 汇出
                            </Button>
                            <Button onClick={() => onResetClicked()}>
                                Reset 重设
                            </Button>
                            <Button className="search-button" htmlType="submit">
                                Search 搜寻
                            </Button>
                        </Space>
                    </Col>
                </Row>
                <Row align="top" >
                    <Col span={8}>
                        <Row>
                            <Col span={6}>
                                <p>Title <br />标题</p>
                            </Col>
                            <Col span={16}>
                                <Form.Item name="title">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8}>
                        <Row>
                            <Col span={8}>
                                <p>Advertiser Name <br />广告商姓名</p>
                            </Col>
                            <Col span={16}>
                                <Form.Item name="advertiser">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>

                </Row>
                <Row align="top" >
                    <Col span={8}>
                        <Row>
                            <Col span={6}>
                                <p>Agent Name <br/> 代理姓名</p>
                            </Col>
                            <Col span={16}>
                                <Form.Item name="agent">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
            <div style={{ background: 'white', padding: '28px 32px' }}>
                <Row justify="space-between" style={{ marginBottom: '36px' }}>
                    <h1>Facebook Pin Post 粉丝专页置顶贴文</h1>
                    <Space direction='horizontal'>
                        <Radio.Group value={facebookFilter} onChange={(e) => handleSizeChange(e)}>
                            <Radio.Button value="all">All 全部</Radio.Button>
                            <Radio.Button value='pending'>Pending 待定</Radio.Button>
                            <Radio.Button value='listing'>Pinned 已置顶</Radio.Button>
                            <Radio.Button value='expired'>Expired 逾期</Radio.Button>
                        </Radio.Group>
                        {/* <Search placeholder="Input search text 输入搜寻关键字" allowClear onSearch={onSearch} style={{ width: 280 }} /> */}
                    </Space>
                </Row>

                <Table dataSource={adsList} columns={roles.includes('project_lead') ? viewOnlyColumns : columns} scroll={{ x: 1800 }} size="small" pagination={{
                    current: currentPageNumber, total: totalCount, pageSize: 5, onChange: (page, pageSize) => {
                        var off = (page - 1) * 5
                        setOffSet(off);
                        setCurrentPageNumber(page);
                        setVariables({ offSet: off, title, postId, packageId, agent, advertiser })
                    }
                }} />

            </div>

        </Layout>
    )
}

export default FacebookPinPage;