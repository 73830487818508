import { Layout, Space, Breadcrumb, message, Image, Button, DatePicker, Pagination, Row, Col, Table, ConfigProvider } from "antd";
import { useQuery, useMutation } from 'react-apollo';
import { AllAdvertisers, DownloadAdvertiserReport, DownloadAdvertiserVerificationDocument, ViewVerificationDocuments } from "../../../server/query";
import React, { useState, useEffect } from 'react';
import client from '../../../server/apollo_config'
const AdvertiserDocument = () => {

    const [UserList, setUserList] = useState()
    const [offSet, setOffSet] = useState(0);
    const [totalCount, setTotalCount] = useState('0');
    const [selfie, setSelfie] = useState('')
    const [icFront, setIcFront] = useState('')
    const [icBack, setIcBack] = useState('')
    const [viewIC, setViewIC] = useState(false);
    const [viewSelfie, setViewSelfie] = useState(false);
    const [visible, setVisible] = useState(false);
    const { loading, error, data, refetch } = useQuery(
        AllAdvertisers, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "no-cache",
        variables: { offSet },
    })

    const column = [
        {
            title: 'ID \n编号',
            dataIndex: 'regular_id',
            key: 'id'
        },
        {
            title: 'Name \n姓名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'View \n检视',
            dataIndex: 'id',
            key: 'action',
            render: (text) => <Button type="text" className="table-action-button" onClick={() => { onClickView(text) }}>View 检视</Button>
        },
        {
            title: 'Download \n下载',
            dataIndex: 'id',
            key: 'action',
            render: (text) => <Button type="text" className="table-action-button" onClick={() => { onClickDownload(text) }}>Download 下载</Button>
        }
    ]

    useEffect(() => {
        if (loading === false && data !== undefined) {
            console.log('data', data.allAdvertisers.edges)

            rerenderListView(data.allAdvertisers.edges)
            setTotalCount(data.allAdvertisers.totalCount)
        }

    }, [loading, data])

    function rerenderListView(data) {
        var list = []
        for (var i = 0; i < data.length; i++) {
            list.push({
                'id': data[i].node.id,
                'regular_id': data[i].node.regularId,
                'name': data[i].node.legalName,
            })
        }
        setUserList(list);
    }

    function onClickSelfie(id) {
        client.query({
            query: ViewVerificationDocuments,
            variables: { id: id },
            fetchPolicy: "network-only"
        }).then((res) => {
            if (res.data.advertiser.selfie === null) {
                message.error(`No selfie has been uploaded. 自拍未上传`)
            } else {

            }
        })
    }

    function onClickView(id) {
        client.query({
            query: ViewVerificationDocuments,
            variables: { id: id },
            fetchPolicy: "network-only"
        }).then((res) => {

            if (res.data.advertiser.icBack != null || res.data.advertiser.icFront == !null || res.data.advertiser.selfie == !null) {
                setSelfie(res.data.advertiser.selfie);
                setIcBack(res.data.advertiser.icBack);
                setIcFront(res.data.advertiser.icFront);
                setVisible(true)
                if (res.data.advertiser.icBack === null) {
                    message.error(`Back image of ic is not uploaded.`)
                }

            } else {
                message.error(`No Document has been uploaded. 
                没有文件上传`)
            }
        })

    }

    function onClickDownload(advertiserId) {
        if (advertiserId == null) {
            message.error(`No document found for this advertiser. 未找到此广告客户的文件`)
        } else {
            client.query({
                query: DownloadAdvertiserVerificationDocument,
                fetchPolicy: 'network-only',
                variables: { advertiserId }

            }).then((res) => {
                let element = document.createElement('a');
                element.setAttribute('href', res.data.downloadAdvertiserVerificationDocuments.url);
                element.setAttribute('target', "_blank");
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            })
        }
    }





    return (

        <Layout>
            <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis) }} >
                <Image
                    width={200}
                    style={{ display: 'none' }}
                    src={selfie}
                    preview={{
                        visible,
                        src: selfie,
                        onVisibleChange: value => {
                            setVisible(value);
                        },
                    }}
                />
                <Image
                    width={200}
                    style={{ display: 'none' }}
                    src={icFront}
                    preview={{
                        viewSelfie,
                        src: icFront,
                        onVisibleChange: value => {
                            setVisible(value);
                        },
                    }}
                />
                <Image
                    width={200}
                    style={{ display: 'none' }}
                    src={icBack}
                    preview={{
                        viewSelfie,
                        src: icBack,
                        onVisibleChange: value => {
                            setVisible(value);
                        },
                    }}
                />



            </Image.PreviewGroup>
            <Image.PreviewGroup preview={{ viewSelfie, onVisibleChange: vis => setViewSelfie(vis) }} >
                <Image
                    width={200}
                    style={{ display: 'none' }}
                    src={selfie}
                    preview={{
                        viewSelfie,
                        src: selfie,
                        onVisibleChange: value => {
                            setViewSelfie(value);
                            setSelfie('')
                        },
                    }}
                />
            </Image.PreviewGroup>
            <Image.PreviewGroup preview={{ viewIC, onVisibleChange: vis => setViewIC(vis) }} >
                <Image
                    width={200}
                    style={{ display: 'none' }}
                    src={icFront}
                    preview={{
                        viewIC,
                        src: icFront,
                        onVisibleChange: value => {
                            setViewIC(value);
                        },
                    }}
                />
                <Image
                    width={200}
                    style={{ display: 'none' }}
                    src={icBack}
                    preview={{
                        viewIC,
                        src: icBack,
                        onVisibleChange: value => {
                            setViewIC(value);
                        },
                    }}
                />
            </Image.PreviewGroup>

            <Space direction='vertical' className="commission_header">
                <Breadcrumb style={{ marginBottom: '16px' }}>
                    <Breadcrumb.Item>Homepage 首页</Breadcrumb.Item>
                    <Breadcrumb.Item>Advertiser Verify Document 广告商认证文件</Breadcrumb.Item>
                </Breadcrumb>
                <h1>Advertiser Verify Document 广告商认证文件</h1>
            </Space>
            <Layout style={{ margin: '16px 32px' }}>
                <div style={{ background: 'white' }}>
                    <Table className="main-table" style={{ whiteSpace: "pre" }} columns={column} dataSource={UserList} pagination={false} />
                    <div style={{ textAlign: 'end', margin: '24px' }}>
                        <Pagination onChange={(page, pageSize) => {
                            setOffSet((page - 1) * 5);
                        }} total={totalCount} defaultPageSize={5} />
                    </div>
                </div>

            </Layout>

        </Layout>
    )
}

export default AdvertiserDocument;