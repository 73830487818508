import { Layout, Space, Breadcrumb, Input, Avatar, Row, Divider, message, Empty, Button, Select, Table, Modal } from "antd";
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { useQuery, useMutation } from 'react-apollo';
import { AgentLead, AllAgentLead } from '../../../../server/query'
import { RetiredAgentLead, UpdateAgentLead, AssignAgent } from '../../../../server/mutation'
import { ROLES } from '../../../../server/constant'
import {
    ArrowLeftOutlined,
} from '@ant-design/icons';
import '../agent-lead.css'
import Moment from 'moment';

const AgentLeadDetailView = (param) => {

    const { loading, error, data, refetch } = useQuery(AgentLead, { variables: { id: param.match.params.id } })
    const { loading: loadingAgentLead, data: dataAgentLead } = useQuery(AllAgentLead)
    const [RetiredAgent] = useMutation(RetiredAgentLead, {
        onCompleted(data) {
            if (data.retireAgentLead.ok == null) {
                message.error(data.retireAgentLead.error);
            } else {
                message.success('Lead agent removed.');
                goBack();
                setRemoveModalVisible(false)
            }
        }
    })
    const [updateAgentLead] = useMutation(UpdateAgentLead, {
        onCompleted(data) {
            if (data.updateAgentLead.ok == null) {
                message.error(data.updateAgentLead.error);
            } else {
                message.success('Lead Agent data updated.');
                refetch();
                setEditModalVisible(false)
            }
        }

    })
    const [UpdateAgent] = useMutation(AssignAgent, {
        onCompleted(data) {
            if (data.assignAgent.ok == null) {
                message.error(data.assignAgent.error);
            } else {
                message.success('Agent reassigned.');
                refetch();
                setRemoveModalVisible(false)
            }
        }

    })
    const [agentList, setAgentList] = useState([])
    const [superAdmin, setSuperAdmin] = useState(false)
    const [projectLead, setProjectLead] = useState(false)
    const [ReassignList, setReassignList] = useState([]);
    const [agentLeadList, setAgentLeadList] = useState([])
    const [name, setName] = useState('')
    const [statusModal, setStatusModal] = useState('delete')
    const [mobile, setMobile] = useState('')
    const [removeModalVisible, setRemoveModalVisible] = useState(false)
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [TransferId, setTransferId] = useState('')
    const [AgentId, setAgentId] = useState('')
    const history = useHistory()
    const { Option } = Select;


    useEffect(() => {
        var roles = localStorage.getItem(ROLES)
        if (roles.includes('super_admin') || roles.includes('project_lead') || roles.includes('operation') || roles.includes('finance')) {
            if (roles.includes('super_admin') || roles.includes('operation') || roles.includes('finance')) {
                setProjectLead(true)
                setSuperAdmin(true)
            }
            if (roles.includes('project_lead')) {
                setProjectLead(true)
            }
        } else {
            history.push('/inbox')
        }
        if (loading === false) {
            rerenderListView(data.agentLead.allChildren.edges)
            setName(data.agentLead.user.name)
            setMobile(data.agentLead.user.phoneNumber)
        }

        if (loadingAgentLead === false) {
            setAgentLeadList(dataAgentLead.allAgentLeads.edges)
        }





    }, [data, dataAgentLead, error, loading, loadingAgentLead]);

    function rerenderListView(data) {
        var list = []
        for (var i = 0; i < data.length; i++) {
            list.push({
                'key': data[i].node.id,
                'id': data[i].node.id,

                'regular_id': data[i].node.regularId,
                'name': data[i].node.user.name,
                'car_dealer': data[i].node.allCarDealers.totalCount,
                'sales': data[i].node.sales,
            })
        }
        setAgentList(list)
    }

    const columns = [
        {
            title: 'ID \n编号',
            dataIndex: 'regular_id',
            key: 'regular_id'

        },
        {
            title: 'Agent \n代理',
            dataIndex: ['name', 'id'],
            key: 'name',
            render: (text, row) => <Link type="text" to={`/agent-lead/agent-detail/${row['id']}`} style={{ color: '#1890FF' }}>{row['name']}</Link>,
        },
        {
            title: 'Advertiser \n广告商',
            dataIndex: 'car_dealer',
            key: 'car_dealer',
            sorter: (a, b) => a.car_dealer - b.car_dealer,
        },
        {
            title: 'Sales \n业绩',
            dataIndex: 'sales',
            key: 'sales',
            render: text => <p>RM{text}</p>,
            sorter: (a, b) => a.sales - b.sales,
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'action',
            render: (text, row) => (
                <Button type="text" style={{ color: '#C62828' }} onClick={() => { setStatusModal('edit'); setRemoveModalVisible(true); setAgentId(row['id']) }}>重新分配</Button>

            ),
        },
    ]

    const basicColumns = [
        {
            title: 'ID \n编号',
            dataIndex: 'regular_id',
            key: 'regular_id'

        },
        {
            title: 'Agent \n代理',
            dataIndex: 'name',
            key: 'name',
            render: (text, row) => <Link type="text" style={{ color: '#1890FF' }}>{row['name']}</Link>,
        },
        {
            title: 'Advertiser \n广告商',
            dataIndex: 'car_dealer',
            key: 'car_dealer',
            sorter: (a, b) => a.car_dealer - b.car_dealer,
        },
        {
            title: 'Sales \n业绩',
            dataIndex: 'sales',
            key: 'sales',
            render: text => <p>RM{text ?? 0}</p>,
            sorter: (a, b) => a.sales - b.sales,
        },
    ]

    const rowSelection = {
        onChange: (selectedRowKeys) => {
            setReassignList(selectedRowKeys)
        },
    };

    const handleRemoveOK = () => {

        console.log('ReassignList', ReassignList)
        if (statusModal === 'delete') {
            RetiredAgent({ variables: { id: param.match.params.id, transfer: TransferId } })
        } if (statusModal === 'edit') {

            if (ReassignList.length === 0 && AgentId !== "") {
                UpdateAgent({ variables: { id: AgentId, agentLead: TransferId } })

            } if (ReassignList.length > 0) {
                console.log('ReassignList', ReassignList)

                for (let i = 0; i < ReassignList.length; i++) {
                    setRemoveModalVisible(false)
                    UpdateAgent({ variables: { id: ReassignList[i], agentLead: TransferId } })

                }
            }
        }
    }

    const goBack = () => {
        history.goBack()
    }

    const handleRemoveCancel = () => {
        setRemoveModalVisible(false)
    }

    function onChangeSelection(value) {
        console.log(`selected ${value}`);
        setTransferId(value)
    }

    const onChangeModalInput = (e, type) => {
        if (type === 'name') { setName(e.target.value) }
        if (type === 'mobile') { setMobile(e.target.value) }
        // if (type === 'email') { setEmail(e.target.value) }
        // if (type === 'password') { setPassword(e.target.value) }
    }

    const handleCancel = () => {
        setEditModalVisible(false)
    }

    const handleOk = () => {
        updateAgentLead({ variables: { id: param.match.params.id, name: name, phoneNumber: mobile } })

    }

    return (
        <Layout>
            <Modal title="Edit Profile 编辑档案" visible={editModalVisible} onOk={handleOk} onCancel={handleCancel} className="create-modal" footer={[
                <Button key="back" onClick={handleCancel} style={{ borderRadius: '4px' }}>
                    Back 返回
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk} className="submit-button">
                    Edit 编辑
                </Button>,
            ]}>
                <Layout>
                    <Space direction='vertical' style={{ background: 'white' }} size="large">
                        <div>
                            <h1>Agent Lead Name 代理主管姓名</h1>
                            <Input placeholder="Agent Lead Name 代理主管姓名" onChange={(e) => onChangeModalInput(e, 'name')} value={name} />
                        </div>
                        <div>
                            <h1>Mobile Number 手机号码</h1>
                            <Input placeholder="Mobile Number 手机号码" type="number" onChange={(e) => onChangeModalInput(e, 'mobile')} value={mobile} />
                        </div>
                        {/* <div>
                            <h1>Email</h1>
                            <Input placeholder="Email" onChange={(e) => onChangeModalInput(e, 'email')} value={email} />
                        </div>
                  */}

                    </Space>
                </Layout>
            </Modal>
            <Modal title={statusModal === 'delete' ? "Remove Agent Lead 删除代理主管" : "Reassign Agent Lead 重新分配代理主管"} visible={removeModalVisible} onOk={handleRemoveOK} onCancel={handleRemoveCancel} className="create-modal" footer={[
                <Button key="back" onClick={handleRemoveCancel} style={{ borderRadius: '4px' }}>
                    Cancel 取消
                </Button>,
                <Button key="submit" type="primary" onClick={handleRemoveOK} className="submit-button">
                    Confirm 确认
                </Button>,
            ]}>
                <Layout>
                    <Space direction="vertical" style={{ background: 'white' }} >
                        <p>Please select an agent lead to transfer 请选择欲转换的代理主管</p>
                        <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Select an Agent Lead 选择代理主管"
                            optionFilterProp="children"
                            onChange={onChangeSelection}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {agentLeadList.map((agent) => (
                                <Option value={agent.node.id}>{agent.node.user.name}</Option>
                            ))}
                        </Select>
                    </Space>
                </Layout>
            </Modal>
            {data ? <Layout>
                <Space direction='vertical' style={{ padding: '16px 24px', background: 'white' }}>
                    <Breadcrumb>
                        <Breadcrumb.Item>Homepage 首页</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            Car Ads 广告
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="/agent-lead">Agent Lead 代理主管</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{data.agentLead.user.name}</Breadcrumb.Item>
                    </Breadcrumb>
                    <Row justify="space-between" style={{ paddingTop: '20px' }}>
                        <Space direction="horizontal" style={{ alignItems: 'flex-start' }} size={24}>
                            <div style={{ alignSelf: 'baseline' }}>
                                <ArrowLeftOutlined onClick={goBack} />
                            </div>
                            <Avatar size={80} src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                        </Space>
                        <Space size={16} direction="horizontal" split={<Divider type="vertical" style={{ borderColor: '#E9E9E9', height: '40px', margin: '0px 40px' }} />} style={{ paddingRight: '40px' }}>
                            <Space direction="vertical" align="end">
                                <p>Total Agent 总代理数</p>
                                <h2>{data.agentLead.allChildren.totalCount}</h2>
                            </Space>
                            <Space direction="vertical" align="end">
                                <p>Total Advertiser 总广告商数</p>
                                <h2>{data.agentLead.allCarDealers.totalCount}</h2>
                            </Space>
                            <Space direction="vertical" align="end">
                                <p>Total Sales 总业绩</p>
                                <h2>RM{data.agentLead?.sales ?? 0}</h2>
                            </Space>
                        </Space>
                    </Row>
                </Space>

                <Layout style={{ background: 'white', margin: '28px', padding: '24px' }}>

                    <Row justify="space-between" >
                        <h2>Basic Info 基本资料</h2>

                        {superAdmin ?
                            <Space direction="horizontal"><Button style={{ borderRadius: '4px' }} onClick={() => { setRemoveModalVisible(true); setStatusModal('delete') }}>Remove Account 删除账号</Button>
                                <Button className='add-new-button' onClick={() => { setEditModalVisible(true) }}>Edit Profile 编辑档案</Button>
                            </Space> : null}


                    </Row>
                    <Row justify="space-between" className="basic-info-row">
                        <Space direction='vertical' >
                            <p style={{ margin: "0px" }}>Account Creation Date 账号建立日期: <span>{Moment(data.agentLead.user.dateJoined).format('DD/MM/YYYY')}</span></p>
                            <p style={{ margin: "0px" }}>Agent Lead 代理主管姓名: <span>{data.agentLead.user.name}</span></p>
                            <p style={{ margin: "0px" }}>Mobile Number 手机号码: <span>{data.agentLead.user.phoneNumber}</span></p>
                            <p style={{ margin: "0px" }}>Address 地址: </p>
                        </Space>
                        <Space direction="vertical">
                            <p style={{ margin: "0px" }}>Agent Lead ID 代理主管编号: <span>{data.agentLead?.secondaryId}</span></p>
                            <p style={{ margin: "0px" }}>Email 电子邮件: <span>{data.agentLead.user.email}</span></p>
                        </Space>

                    </Row>
                    <Divider />
                    <Row justify="space-between">
                        <p>Agent 代理 <span>{data.agentLead.allChildren.totalCount} agent 代理</span></p>
                        {/* <Button disabled={ReassignList.length === 0 ? true : false} onClick={() => { setStatusModal('edit'); setRemoveModalVisible(true) }}>Reassign Agent</Button> */}
                        {projectLead ? <Button disabled={ReassignList.length === 0 ? true : false} onClick={() => { setStatusModal('edit'); setRemoveModalVisible(true) }}>Reassign 重新分配</Button> : null}

                    </Row>

                    <Table
                        style={{ whiteSpace: "pre"}}
                        rowSelection={projectLead ? rowSelection : null}
                        columns={projectLead ? columns : basicColumns}
                        dataSource={agentList}
                    />

                </Layout>
            </Layout> : <Empty />}
        </Layout>

    )
}

export default AgentLeadDetailView;