import { Layout, Space, Input, Button, Row, Col, Table, Modal, Select, message, ConfigProvider, Image, Divider } from "antd";
import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch, useLocation, useHistory } from "react-router-dom";
import { useQuery, useMutation } from 'react-apollo';
import './car-dealer.css'
import { UpdateIndividualAdvertiser, UpdateCarDealer, AssignAdvertiser } from '../../../server/mutation'
import { AdvertiserSearch, AllAgents, DownloadCarDealerReport, DownloadAdvertiserVerificationDocument, ViewVerificationDocuments, MyAgentProfile } from '../../../server/query'
import { ROLES } from '../../../server/constant'
import client from '../../../server/apollo_config'
import locale from 'antd/lib/locale/zh_CN';
import { DatePicker } from "antd";
import Moment from 'moment';
const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";

const CarDealerPage = () => {
    const { Option } = Select;
    const [offSet, setOffSet] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [totalAdvertisers, setTotalAdvertisers] = useState(0);
    const [visible, setVisible] = useState(false);
    const [advertiserList, setAdvertiserList] = useState([])
    const [agentsList, setAgentsList] = useState([])
    const [date, setDate] = useState([])
    const [isSearching, setIsSearching] = useState(false)
    const [createModalVisible, setCreateModalVisible] = useState(false)
    const [reassignModalVisible, setReassignModalVisible] = useState(false)
    const [address1, setAddress1] = useState('')
    const [address2, setAddress2] = useState('')
    const [postcode, setPostcode] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [country, setCountry] = useState('')
    const [isCarDealer, setIsCarDealer] = useState(false);
    const [companyName, setCompanyName] = useState('')
    const [companyBranch, setCompanyBranch] = useState('')
    const [registrationNumber, setRegistrationNumber] = useState('')
    const [selfie, setSelfie] = useState('')
    const [icFront, setIcFront] = useState('')
    const [icBack, setIcBack] = useState('')
    const [ID, setID] = useState('')
    const [TransferId, setTransferId] = useState('')
    const [superAdmin, setSuperAdmin] = useState(false)
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [variables, setVariables] = useState({ offSet, id, name })

    const { loading: agentLoading, data: agentData } = useQuery(MyAgentProfile)
    const agentId = agentData?.myAgentProfile?.regularId
    const roles = localStorage.getItem(ROLES)


    const handleAdvertiserSearchVariables = (variables, agentId) =>{
        if (roles.includes('super_admin') || roles.includes('operation') || roles.includes('finance')) {
            return { ...variables }
        }

        return { ...variables, agentId }
    }

    const { loading, data, refetch } = useQuery(AdvertiserSearch, {
        skip: agentLoading,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "no-cache",
        variables: handleAdvertiserSearchVariables(variables, agentId),
    })
    const [updateCarDealer] = useMutation(UpdateCarDealer, {
        onCompleted(data) {
            if (data.updateCarDealer.ok == null) {
                message.error(data.updateCarDealer.error);
            } else {
                message.success('Car dealer updated.')
                setCreateModalVisible(false)
                refetch()
            }
        }
    })
    const [updateIndividualAdvertiser] = useMutation(UpdateIndividualAdvertiser, {
        onCompleted(data) {
            if (data.updateIndividualAdvertiser.ok == null) {
                message.error(data.updateCarDealer.error);
            } else {
                message.success('Advertiser updated.')
                setCreateModalVisible(false)
                refetch()
            }
        }
    })
    const [ReassignCarDealer] = useMutation(AssignAdvertiser, {
        onCompleted(data) {
            if (data.assignAdvertiser.ok == null) {
                message.error(data.assignAdvertiser.error);
            } else {
                message.success('Car dealer reassigned.')
                setReassignModalVisible(false);
                setID('')
                setTransferId('')
                refetch()
            }
        }
    })

    const { loading: AgentListLoading, data: agentListData } = useQuery(AllAgents)

    let { url } = useRouteMatch();
    let location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (loading === false && data != null) {
            rerenderListView(data.advertiserSearch.edges)
            setTotalAdvertisers(data.advertiserSearch.totalCount)
        }

        if (AgentListLoading === false && agentListData != null) {
            rerenderAgentList(agentListData.allAgents.edges)
        }

        var roles = localStorage.getItem(ROLES)

        if (roles.includes('advertiser') || roles.includes('car_dealer')) {
            history.push('/inbox')
        }
        else {
            if (roles.includes('super_admin') || roles.includes('operation') || roles.includes('finance')) {
                setSuperAdmin(true);
            }
        }

    }, [AgentListLoading, agentListData, data, history, loading]);

    const onchangeDate = (date, dateString) => {

        var endDate = Moment.parseZone(dateString[1]).utc().format()
        var startDate = Moment.parseZone(dateString[0]).utc().format()

        if (date != null) {
            setDate(dateString)
            setVariables({
                offSet, id, name, startDate, endDate
            })
        } else if (date == null) {
            setDate('')
            setVariables({
                offSet, id, name,
            })
        }
    }


    const columns = [
        {
            title: 'ID \n编号',
            dataIndex: 'code',
            key: 'code',

        },
        {
            title: 'Name \n姓名',
            dataIndex: ['name', 'id'],
            key: 'name',
            render: (text, row) => <Link type="text" style={{ color: '#1890FF' }} to={`${url}/car-dealer-detail/${row['id']}`}>{row['name']}</Link>,
        },
        // {
        //     title: 'Agent status\n代理状态',
        //     dataIndex: 'referral_code',
        //     key: 'referral_code',
        //     render: text => <p>{text ? 'Agent' : '-'}</p>
        // },
        {
            title: 'Agent Name \n代理姓名',
            dataIndex: 'agent',
            key: 'agent',
            render: text => <p>{text ?? "-"}</p>,
        },
        {
            title: 'Ads \n广告',
            dataIndex: 'ads',
            key: 'ads',

            sorter: (a, b) => a.ads - b.ads,
        },
        {
            title: 'Package \n配套',
            dataIndex: 'package',
            key: 'package',
            render: text => <p>{text ?? "-"}</p>,
            sorter: (a, b) => a - b,
        },
        {
            title: 'Sales \n业绩',
            dataIndex: 'sales',
            key: 'sales',
            render: text => <p>RM{text ?? 0}</p>,
            sorter: (a, b) => a.sales - b.sales,
            filterDropdown: (
                <div className="custom-filter-dropdown">
                    <div>
                        <RangePicker
                            onChange={onchangeDate}
                            format={dateFormat}
                        />
                    </div>
                </div>
            ),
        },
        {
            title: 'Advertiser Type \n广告商类型',
            dataIndex: 'has_company',
            key: 'has_company',
            render: text => <p>{text ? 'Car Dealer \n汽车经销商' : 'Individual \n个人卖家'}</p>
        },

        {
            title: '',
            dataIndex: ['id', 'address1', 'address2', 'postcode', 'city', 'state', 'country', 'company_name', 'company_branch', 'company_registration_number'],
            key: 'action',
            render: (text, row) => {
                return(
                    <Space className="Table-action" size={2} split={<Divider type="vertical" style={{ borderColor: '#D8D8D8' }} />}>
                        <Button type="text" className="table-action-button" onClick={() => { viewDocuments(row['id'])}}>View 看法</Button>
                        <Button type="text" className="table-action-button" onClick={() => { onClickDownload(row['id']) }}>Download 下载</Button>
                        {roles.includes('super_admin') && (
                            <>
                                <Button type="text" className="table-action-button" onClick={() => { setCreateModalVisible(true); setID(row['id']); setAddress1(row['address1']); setAddress2(row['address2']); setPostcode(row['postcode']); setCity(row['city']); setState(row['state']); setCountry(row['country']); setCompanyName(row['company_name']); setCompanyBranch(row['company_branch']); setRegistrationNumber(row['registration_number']); setIsCarDealer(row['has_company']) }}>Edit 编辑</Button>
                                <Button type="text" className="table-action-button" onClick={() => { setReassignModalVisible(true); setID(row['id']); setTransferId(row.agentID) }}>Reassign 重新分配</Button>
                            </>
                        )}
                        {/* <Button type="text" style={{ color: '#C62828' }} onClick={() => { }} >Remove</Button> */}
                    </Space>
                )
            },
        },
    ];

    function rerenderListView(data) {
        var list = []
        for (var i = 0; i < data.length; i++) {
            list.push({
                "id": data[i].node.id,
                "code": data[i].node.regularId,
                "name": data[i].node.legalName,
                "agent": data[i].node.agent?.user?.name,
                "agentID": data[i].node?.agent?.id,
                "ads": data[i].node.allListings?.totalCount,
                "package": data[i].node?.adsPackage?.name,
                "date": data[i].node?.user?.dateJoined,
                "sales": data[i].node.sales,
                "email": data[i].node.user.email,
                "phoneNumber": data[i].node.user.phoneNumber,
                "address1": data[i].node.address1,
                'address2': data[i].node.address2,
                'postcode': data[i].node.postcode,
                'city': data[i].node.city,
                'state': data[i].node.state,
                'country': data[i].node.country,
                'has_company': data[i].node.hasCompany,
                'company_name': data[i].node.carDealer?.companyName,
                'company_branch': data[i].node.carDealer?.companyBranch,
                'registration_number': data[i].node.carDealer?.companyRegistrationNo,
                'referral_code': data[i].node.agent?.referralCode,
            });
        }
        // list.sort(function (a, b) {
        //     return new Date(b.date) - new Date(a.date);
        // });

        setAdvertiserList(list)
    }

    function rerenderAgentList(data) {
        var list = []
        for (var i = 0; i < data.length; i++) {
            list.push({
                'id': data[i].node.id,
                'name': data[i].node.user.name
            })
        }

        setAgentsList(list)

    }

    // function onSearchClicked() {
    //     client.query({
    //         query: AdvertiserSearch,
    //         fetchPolicy: "network-only",
    //         variables: { id: agentID, name: agentName }
    //     }).then((res) => {
    //         rerenderListView(res.data.advertiserSearch.edges)
    //         setTotalAdvertisers(res.data.advertiserSearch.totalCount)
    //     })
    // }

    function viewDocuments(id) {
        client.query({
            query: ViewVerificationDocuments,
            variables: { id: id },
            fetchPolicy: "network-only"
        }).then((res) => {
            setSelfie(res.data.advertiser.selfie);
            setIcBack(res.data.advertiser.icBack);
            setIcFront(res.data.advertiser.icFront);
            setVisible(true);

        })

    }

    function onClickDownload(advertiserId) {
        if (advertiserId == null) {
            message.error(`No document found for this advertiser. 未找到此广告客户的文件`)
        } else {
            client.query({
                query: DownloadAdvertiserVerificationDocument,
                fetchPolicy: 'network-only',
                variables: { advertiserId }

            }).then((res) => {
                let element = document.createElement('a');
                element.setAttribute('href', res.data.downloadAdvertiserVerificationDocuments.url);
                element.setAttribute('target', "_blank");
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            })
        }
    }

    function onResetClicked() {
        setCurrentPageNumber(1);
        setOffSet(0);
        setIsSearching(false)
        setId('');
        setName('');
        // rerenderListView(data.advertiserSearch.edges);
        setVariables({
            offSet: 0, id: "" , name: "",
        })
    }

    const onChangeInput = (e, type) => {
        // search input change
        if (type === 'name') { setName(e.target.value) }
        if (type === 'id') { setId(e.target.value) }
    }

    const handleCancel = () => {
        setCreateModalVisible(false)
    }

    const handleOk = () => {
        isCarDealer
            ? updateCarDealer({ variables: { id: ID, address1: address1, address2: address2, postcode: postcode, city: city, state: state, country: country, companyName: companyName, companyBranch: companyBranch, companyRegistrationNo: registrationNumber } })
            : updateIndividualAdvertiser({ variables: { id: ID, address1: address1, address2: address2, postcode: postcode, city: city, state: state, country: country } })
    }

    const onChangeModalInput = (e, type) => {
        switch (type) {
            case 'address_1':
                setAddress1(e.target.value)
                break;
            case 'address_2':
                setAddress2(e.target.value)
                break;
            case 'postcode':
                setPostcode(e.target.value);
                break;
            case 'city':
                setCity(e.target.value)
                break;
            case 'state':
                setState(e.target.value)
                break;
            case 'country':
                setCountry(e.target.value)
                break;
            case 'company_name':
                setCompanyName(e.target.value)
                break;
            case 'company_branch':
                setCompanyBranch(e.target.value)
                break;
            case 'registration_number':
                setRegistrationNumber(e.target.value)
                break;

            default: // do nothing;
                break;

        }

    }

    const handleReassignOK = () => {
        ReassignCarDealer({ variables: { id: ID, agent: TransferId } })
        setID("")
    }

    const handleRemoveCancel = () => {
        setReassignModalVisible(false)
        setID("")
    }

    function onChangeSelection(value) {
        setTransferId(value)
    }

    async function downloadReportFunction() {
        client.query({
            query: DownloadCarDealerReport,
            fetchPolicy: "network-only"
        }).then((res) => {
            let element = document.createElement('a');
            element.setAttribute('href', res.data.downloadCarDealerReport.url);
            element.setAttribute('target', "_blank");
            // element.setAttribute('download', 'output.xlsx');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        })
    }



    return (

        <Layout className="lead-agent-main">

            <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis) }} >
                <Image
                    width={200}
                    style={{ display: 'none' }}
                    src={selfie}
                    preview={{
                        visible,
                        src: selfie,
                        onVisibleChange: value => {
                            setVisible(value);
                        },
                    }}
                />
                <Image
                    width={200}
                    style={{ display: 'none' }}
                    src={icFront}
                    preview={{
                        visible,
                        src: icFront,
                        onVisibleChange: value => {
                            setVisible(value);
                        },
                    }}
                />
                <Image
                    width={200}
                    style={{ display: 'none' }}
                    src={icBack}
                    preview={{
                        visible,
                        src: icBack,
                        onVisibleChange: value => {
                            setVisible(value);
                        },
                    }}
                />



            </Image.PreviewGroup>

            <Modal title='Edit Car Dealer 编辑汽车经销商' visible={createModalVisible} onOk={handleOk} onCancel={handleCancel} width={isCarDealer ? "700px" : "400px"} className="create-modal" footer={[
                <Button key="back" onClick={handleCancel} style={{ borderRadius: '4px' }}>
                    Back 返回
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk} className="submit-button">
                    Edit 编辑
                </Button>,
            ]}>
                <Layout>
                    <Space direction="horizontal" style={{ background: 'white' }} size="large" align="start">
                        <Space direction='vertical' size="large" style={{ width: '300px' }}>
                            <div>
                                <h1>Address 1 地址 1</h1>
                                <Input placeholder="Address 1 地址 1" onChange={(e) => onChangeModalInput(e, 'address_1')} value={address1} />
                            </div>
                            <div>
                                <h1>Address 2 地址 2</h1>
                                <Input placeholder="Address 2 地址 2" onChange={(e) => onChangeModalInput(e, 'address_2')} value={address2} />
                            </div>
                            <div>
                                <h1>Postcode 邮政编号</h1>
                                <Input placeholder="Postcode 邮政编号" type="number" onChange={(e) => onChangeModalInput(e, 'postcode')} value={postcode} />
                            </div>
                            <div>
                                <h1>City 城市</h1>
                                <Input placeholder="City 城市" onChange={(e) => onChangeModalInput(e, 'city')} value={city} />
                            </div>
                            <div>
                                <h1>State 州属</h1>
                                <Input placeholder="State 州属" onChange={(e) => onChangeModalInput(e, 'state')} value={state} />
                            </div>
                            <div>
                                <h1>Country 国家</h1>
                                <Input placeholder="Country 国家" onChange={(e) => onChangeModalInput(e, 'country')} value={country} />
                            </div>
                        </Space>
                        {isCarDealer && <Space direction="vertical" size="large" style={{ width: '300px' }}>
                            <div>
                                <h1>Company Name 公司名称</h1>
                                <Input placeholder="Company Name 公司名称" onChange={(e) => onChangeModalInput(e, 'company_name')} value={companyName} />
                            </div>
                            <div>
                                <h1>Company Branch 公司分行</h1>
                                <Input placeholder="Company Branch 公司分行" onChange={(e) => onChangeModalInput(e, 'company_branch')} value={companyBranch} />
                            </div>
                            <div>
                                <h1>Company Registration Number 公司注册号码</h1>
                                <Input placeholder="Company Registration Number 公司注册号码" onChange={(e) => onChangeModalInput(e, 'registration_number')} value={registrationNumber} />
                            </div>
                        </Space>}
                    </Space>
                </Layout>
            </Modal>

            <Modal 
                title="Reassign Agent 重新分配代理" 
                visible={reassignModalVisible} 
                onOk={handleReassignOK} 
                onCancel={handleRemoveCancel} 
                className="create-modal" 
                footer={[
                    <Button key="back" onClick={handleRemoveCancel} style={{ borderRadius: '4px' }}>
                        Cancel 取消
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleReassignOK} className="submit-button">
                        Confirm 确认
                    </Button>,
                ]}
                destroyOnClose
            >
                <Layout>
                    <Space direction="vertical" style={{ background: 'white' }} >
                        <p>Please select an agent to transfer 请选择欲转换的代理</p>
                        <Select
                            value={TransferId}
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Select an Agent  选择代理"
                            optionFilterProp="children"
                            onChange={onChangeSelection}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {agentsList.map((agent) => {
                                return <Option value={agent.id} disabled={agent.id === TransferId}>{agent.name}</Option>
                            })}
                        </Select>
                    </Space>
                </Layout>
            </Modal>

            <Row className="agent-lead-header" gutter={[16]} >
                <Col span={8}>
                    <Row>
                        <Col span={7} style={{ alignSelf: 'self-end' }}>
                            <h1>Advertiser ID <br />广告商编号:</h1>
                        </Col>
                        <Col span={16}>
                            <Input placeholder="Please Enter 请输入" type="number" value={id} onChange={(e) => onChangeInput(e, 'id')} />
                        </Col>
                    </Row>
                </Col>
                <Col span={8}>
                    <Row>
                        <Col span={3} style={{ alignSelf: 'self-end' }}>
                            <h1>Name <br />姓名:</h1>
                        </Col>
                        <Col span={16}>
                            <Input placeholder="Please Enter 请输入" value={name} onChange={(e) => onChangeInput(e, 'name')} />
                        </Col>
                    </Row>
                </Col>
                <Col span={8} align="end">
                    <Space direction="horizontal">
                        <Button style={{ borderRadius: '4px' }} onClick={() =>onResetClicked()}>Reset 重设</Button>
                        <Button style={{ background: '#C62828', color: 'white', borderRadius: '4px', borderColor: '#C62828' }} 
                            onClick={() => {
                                setVariables({
                                    offSet: 0, id: id, name: name,
                                }); 
                                setIsSearching(true)
                                setOffSet(0); 
                                setCurrentPageNumber(1);
                            }}
                        >
                            Search 搜寻
                        </Button>
                    </Space>
                </Col>
            </Row>

            <Space direction="vertical" className="agent-lead-table">
                <Row justify="space-between" style={{ paddingBottom: '22px' }}>
                    <h2>Advertiser 广告商&emsp;<span>{totalAdvertisers} Advertiser 广告商</span></h2>
                    {superAdmin ? <Space direction="horizontal">
                        <Button style={{ borderRadius: '4px' }} onClick={() => downloadReportFunction()}>Download 下载</Button>
                    </Space> : null}
                </Row>

                <ConfigProvider locale={locale}>
                    <Table
                        size="small"
                        scroll={{
                            x: 720
                        }}
                        dataSource={advertiserList}
                        style={{ whiteSpace: "pre" }}
                        columns={ columns }
                        // key={advertiserList}
                        pagination={{
                            showSizeChanger: false,
                            current: currentPageNumber, total: totalAdvertisers, pageSize: 5, 
                            onChange: (page, pageSize) => {
                                const off = (page - 1) * 5

                                setVariables(
                                    isSearching ? 
                                        {
                                            offSet: off, 
                                            id: id, 
                                            name: name,
                                        } : 
                                        { offSet: off}
                                )

                                setOffSet(off);
                                setCurrentPageNumber(page);
                            }
                        }}
                    />
                </ConfigProvider>
            </Space>
        </Layout>
    )
}

export default CarDealerPage;