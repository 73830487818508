import { Layout, Space, Breadcrumb, Form, Input, Button, message, Row, Col, Table, Image, Select, Modal } from "antd";
import client from '../../../server/apollo_config'
import React, { useState, useEffect } from 'react';
import { ROLES } from '../../../server/constant'
import { useQuery, useMutation } from 'react-apollo';
import { AllEditors, AllWagtailUsers, DownloadAdvertiserVerificationDocument, ViewVerificationDocuments } from "../../../server/query";
import { useHistory, useLocation } from "react-router-dom";
import Moment from 'moment';
import { CreateEditor } from "../../../server/mutation";

const WagTailUser = () => {
    const [offSet, setOffSet] = useState(0);
    const [name, setName] = useState();
    const [form] = Form.useForm();
    const [selfie, setSelfie] = useState('')
    const [icFront, setIcFront] = useState('')
    const [icBack, setIcBack] = useState('')
    const [userList, setUserList] = useState()
    const [visible, setVisible] = useState(false);
    const [createModalVisible, setCreateModalVisible] = useState(false)
    const history = useHistory();
    const [totalCount, setTotalCount] = useState(0)
    const [currentPageNumber, setCurrentPageNumber] = useState(1);

    const [createEditorForm] = Form.useForm()

    const { loading, error, data } = useQuery(
        AllEditors, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        variables: { offSet, name }
    })

    const [AddEditor] = useMutation(CreateEditor, {
        onCompleted(data) {
            if (data.createEditor.ok == null) {
                message.error(data.createEditor.error);
            } else if (data.createEditor.ok === 'yes') {
                message.success("Editor Created");
            }
        }

    })

    useEffect(() => {
        const roles = localStorage.getItem(ROLES)
        if (roles.includes('super_admin')) {

        } else {
            history.push('/inbox')
        }

        if (loading === false && data !== undefined) {
            rerenderListView(data.allEditors.edges)
            setTotalCount(data.allEditors.totalCount)
        }
    }, [data, history, loading])

    const column = [
        {
            title: 'ID \n编号',
            dataIndex: 'id',
            key: 'id',

        },
        {
            title: 'Name \n姓名',
            dataIndex: 'name',
            key: 'name',
            render: (_, row) =>{
                const { name, firstName, lastName } = row
                if(name){
                    return name
                }

                if(firstName){
                    if(lastName){
                        return `${firstName} ${lastName}`
                    }

                    return firstName
                }
                
                return ""
            }
          
        },
        {
            title: 'Role \n角色',
            dataIndex: 'role',
            key: 'role',
            render: (arrayOfRoles) =>{
                return arrayOfRoles.map((role, index) =>{
                    if(index === arrayOfRoles.length -1){
                        return `${role.charAt(0).toUpperCase() + role.slice(1)}`
                    }
                    return `${role.charAt(0).toUpperCase() + role.slice(1)}, `
                })
            }
        },
        {
            title: 'Email \n电子邮件',
            dataIndex: 'email',
            key: 'email',
        },
        {

            title: 'Mobile Number \n电话号码',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Registration Date \n登记日期',
            dataIndex: 'date',
            key: 'date',
            render: (text, row) => row['date'] !== '' ? (Moment(row['date']).format('DD/MM/YYYY')) : '',
        },

    ]

    function rerenderListView(data) {
        var list = []
        for (var i = 0; i < data.length; i++) {
            list.push({
                'id': data[i].node.regularId,
                'name': data[i].node.name,
                'role': data[i].node.roles,
                'email': data[i].node.email,
                'firstName': data[i].node.firstName,
                'lastName': data[i].node.lastName,
                'phone': data[i].node.phoneNumber,
                'source': '',
                'link': '',
                'title': '',
                'date': data[i].node.dateJoined,
                'verify_at': '',
                'advertiser': data[i].node.advertiser,
                'advertiser_id': data[i].node.advertiser?.id,
            })
        }

        setUserList(list)
    }

    const onFinish = (values) => {
        setName(values.name)
        setOffSet(0);
        setCurrentPageNumber(1);
    }

    function onClickView(id) {
        console.log('id', id)
        client.query({
            query: ViewVerificationDocuments,
            variables: { id: id },
            fetchPolicy: "network-only"
        }).then((res) => {

            if (res.data.advertiser.icBack != null || res.data.advertiser.icFront == !null || res.data.advertiser.selfie == !null) {
                setSelfie(res.data.advertiser.selfie);
                setIcBack(res.data.advertiser.icBack);
                setIcFront(res.data.advertiser.icFront);
                setVisible(true)
                if (res.data.advertiser.icBack === null) {
                    message.error(`Back image of ic is not uploaded.`)
                }

            } else {
                message.error(`No Document has been uploaded. 
                没有文件上传`)
            }
        })

    }

    function onClickDownload(advertiserId) {
        if (advertiserId == null) {
            message.error(`No document found for this advertiser. 未找到此广告客户的文件`)
        } else {
            client.query({
                query: DownloadAdvertiserVerificationDocument,
                fetchPolicy: 'network-only',
                variables: { advertiserId }

            }).then((res) => {
                let element = document.createElement('a');
                element.setAttribute('href', res.data.downloadAdvertiserVerificationDocuments.url);
                element.setAttribute('target', "_blank");
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            })
        }
    }

    function onResetClicked() {
        form.resetFields();
        setName()
        setOffSet(0);
        setCurrentPageNumber(1);
    }

    const handleModalVisible = () =>{
        setCreateModalVisible(prevFlag => !prevFlag)
    }

    const handleEditorForm = () =>{
        AddEditor({ variables: { ...createEditorForm.getFieldsValue(true) }})
    }

    return (
        <Layout>
            <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis) }} >
                <Image
                    width={200}
                    style={{ display: 'none' }}
                    src={selfie}
                    preview={{
                        visible,
                        src: selfie,
                        onVisibleChange: value => {
                            setVisible(value);
                        },
                    }}
                />
                <Image
                    width={200}
                    style={{ display: 'none' }}
                    src={icFront}
                    preview={{
                        visible,
                        src: icFront,
                        onVisibleChange: value => {
                            setVisible(value);
                        },
                    }}
                />
                <Image
                    width={200}
                    style={{ display: 'none' }}
                    src={icBack}
                    preview={{
                        visible,
                        src: icBack,
                        onVisibleChange: value => {
                            setVisible(value);
                        },
                    }}
                />
            </Image.PreviewGroup>

            <Space direction='vertical' className="commission_header">
                <Breadcrumb style={{ marginBottom: '16px' }}>
                    <Breadcrumb.Item>Homepage 首页</Breadcrumb.Item>
                    <Breadcrumb.Item>Wagtail User List 代理列表</Breadcrumb.Item>
                </Breadcrumb>
                <div style={{display:"flex", flexDirection:"row" }}>
                    <Space size="large" style={{flex: 1}}>
                        <h1>Wagtail User List 代理列表</h1>
                        <span>{totalCount} users 用户</span>
                    </Space>
                    <Button type="primary" htmlType="submit" className="submit-button" onClick={handleModalVisible}>
                        Add New 新增
                    </Button>
                </div>
            </Space>

            <Layout style={{ margin: '16px 32px' }}>
                <Form className="filter-form" name='basic' onFinish={onFinish} form={form} autoComplete="false">
                    <Row align="top" justify="space-between">
                        <Col span={8}>
                            <Row>
                                <Col span={3}>
                                    <p>Name <br />姓名</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="name">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        {/* <Col span={8}>
                            <Row>
                                <Col span={6}>
                                    <p>Reg. Source</p>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name="reg-source">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col> */}
                        <Col span={8} style={{ textAlign: 'end' }}>
                            <Space direction="horizontal">
                                <Button onClick={() => onResetClicked()}>
                                    Reset 重设
                                </Button>
                                <Button className="search-button" htmlType="submit">
                                    Search 搜寻
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>

                <div style={{ background: 'white' }}>
                    <Table className="main-table" style={{ whiteSpace: "pre" }} size="small" columns={column} dataSource={userList} pagination={{
                        current: currentPageNumber,
                        total: totalCount,
                        showSizeChanger: false,
                        pageSize: 5,
                        onChange: (page, pageSize) => {
                            var off = (page - 1) * 5
                            setOffSet(off);
                            setCurrentPageNumber(page);
                        },
                    }} />
                </div>
            </Layout>

            <Modal 
                title={"新增总管理员"} 
                visible={createModalVisible} 
                onCancel={handleModalVisible}
                className="create-modal" 
                footer={[
                    <Button key="back" 
                        onClick={handleModalVisible} 
                        style={{ borderRadius: '4px' }}
                    >
                        Back 返回
                    </Button>,
                    <Button 
                        key="submit" 
                        type="primary" 
                        onClick={handleEditorForm} 
                        className="submit-button"
                    >
                        {"Add New 新增"}
                    </Button>,
                ]}
            >
                <Layout>
                    <Space direction='vertical' style={{ background: 'white' }} size="large">
                        <Form form={createEditorForm} layout="vertical">
                            <Form.Item name="name" label="Name 姓名">
                                <Input placeholder="Name 姓名"/>
                            </Form.Item>
                            <Form.Item name="phoneNumber" label="Mobile Number 手机号码">
                                <Input placeholder="Mobile Number 手机号码"/>
                            </Form.Item>
                            <Form.Item name="email" label="Email 电子邮件">
                                <Input placeholder="Email 电子邮件"/>
                            </Form.Item>
                            <Form.Item name="password" label="Password 密码">
                                <Input placeholder="Password 密码"/>
                            </Form.Item>
                        </Form>
                    </Space>
                </Layout>
            </Modal>
        </Layout>
    )





}

export default WagTailUser;